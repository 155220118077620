import React from 'react';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client/react';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import App from '../app';

import configureStore from '../../flows/store.config';
import { GRAPHQL_URI } from '../../graphql/config';
import { checkExpiredTime } from '../../utils/auth';

import '../../assets/styles/bootstrap.min.css';
import '../../assets/styles/fontawesome.min.css';
import '../../assets/styles/animate.min.css';
import '../../assets/styles/slick.css';
import '../../assets/styles/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-image-lightbox/style.css';
import '../../assets/styles/style.css';
import '../../assets/styles/responsive.css';

import './global-variable';

checkExpiredTime();

const history = createBrowserHistory();
const { store, persistor } = configureStore(history, {});

const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
});

/**
 * Note isPreventToken
 * Set in context for each API
 * Default allow token (not prevent)
 *
 * context: { isPreventToken: true },
 */
const authLink = setContext((_, { headers, isPreventToken = false }) => {
  const token = localStorage.getItem('token');
  const Store = localStorage.getItem('store') || 'default';
  const authorization = token && !isPreventToken ? `Bearer ${token}` : null;

  return {
    headers: Object.assign(
      {},
      {
        ...headers,
        Store,
      },
      authorization && { authorization }
    ),
  };
});

const client = new ApolloClient({
  uri: GRAPHQL_URI,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export { store };
export default () => (
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
