import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { getUrlParameter } from '../../../utils/uri';
import { toastAlert } from '../../../utils/toastify';
import { SOCIAL_SIGN_IN_GQL } from '../../../graphql/customer.gql';
import { mapStateToProps, mapDispatchToProps } from './state';

interface IProps {
  location: any;
  saveAuthInfoAction: Function;
  clearCartAction: Function;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: IProps) => {
  const { saveAuthInfoAction, clearCartAction } = props;

  const [socialSignIn]: any = useLazyQuery(SOCIAL_SIGN_IN_GQL, {
    onCompleted: ({ generateSocialCustomerToken }) => {
      clearCartAction();
      saveAuthInfoAction(generateSocialCustomerToken);
    },
    onError: ({ message }) => toastAlert({ type: 'error', message }),
  });

  useEffect(() => {
    const {
      location: { hash },
    } = props;
    const accessToken = getUrlParameter(hash.replace('#', '?'), 'access_token');
    socialSignIn({ variables: { identifier: accessToken, type: 'google' } });
  }, []);

  return (
    <div className='text-center py-5 my-5'>
      <span className='spinner-grow spinner-grow-lg my-5' />
      <h3 className='py-1'>Connect with Google</h3>
      <h4 className='py-1'>Redirecting, please wait a moment...</h4>
    </div>
  );
});
