import { initAppAction, saveStoreConfigAction } from '../../flows/app/action';
import { saveUerInfoAction } from '../../flows/user/action';
import { saveWishlistInfoAction } from '../../flows/wishlist/action';
import { saveCompareInfo, saveCompareList } from '../../flows/compare/action';
import {
  saveCartTokenAction,
  saveCartInfoAction,
} from '../../flows/checkout/action';

export const mapStateToProps = (state) => ({
  appState: state.app,
  authState: state.auth,
  checkoutState: state.checkout,
  compareState: state.compare,
});

export const mapDispatchToProps = (dispatch) => ({
  initAppAction: (data: any) => dispatch(initAppAction(data)),
  saveUerInfoAction: (data: any) => dispatch(saveUerInfoAction(data)),
  saveCartTokenAction: (data: any) => dispatch(saveCartTokenAction(data)),
  saveCartInfoAction: (data: any) => dispatch(saveCartInfoAction(data)),
  saveWishlistInfoAction: (data: any) => dispatch(saveWishlistInfoAction(data)),
  saveCompareInfo: (data: any) => dispatch(saveCompareInfo(data)),
  saveCompareList: (data: any) => dispatch(saveCompareList(data)),
  saveStoreConfigAction: (data: any) => dispatch(saveStoreConfigAction(data)),
});
