import { NavLink } from 'react-router-dom';
import React, { Component, useEffect, useState } from 'react';
import { GET_LIST_BRANDINGS_GQL } from '../../graphql/layout.gql';
import { useLazyQuery } from '@apollo/client';
import { toastAlert } from '../../utils/toastify';
import { renderHtmlContent } from '../../utils/html';

import OwlCarousel from 'react-owl-carousel3';

const options = {
  loop: true,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='fas fa-chevron-left'></i>",
    "<i class='fas fa-chevron-right'></i>",
  ],
  responsive: {
    0: {
      items: 3,
      margin: 30,
    },
    576: {
      items: 4,
    },
    768: {
      items: 5,
    },
    1200: {
      items: 6,
    },
  },
};

const Partner = () => {
  const [display, setDisplay] = useState(false);
  const [panel, setPanel] = useState(true);

  const [brands, setBrand] = useState(null);

  const init = () => {
    getBrand();
  };

  const [getBrand]: any = useLazyQuery(GET_LIST_BRANDINGS_GQL, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getListBrandings }) => {
      setBrand(getListBrandings.items);
      setDisplay(true);
    },
    onError: ({ message }) => toastAlert({ type: 'error', message }),
  });

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='partner-area'>
      <div className='container'>
        {display ? (
          <OwlCarousel
            className='partner-slides owl-carousel owl-theme'
            {...options}
          >
            {!!brands &&
              !!brands.length &&
              brands.map((data) => {
                return (
                  <div className='partner-item' key={data.id}>
                    <a
                      href='#'
                      onClick={(e) => e.preventDefault()}
                      style={{ pointerEvents: 'none' }}
                    >
                      <img src={data.image} alt='image' />
                    </a>
                  </div>
                );
              })}
          </OwlCarousel>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default Partner;
