import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { useLazyQuery, useMutation } from '@apollo/client';
import ProductImage from '../../../components/product-details/ProductImage';
import ProductContent from '../../../components/product-details/ProductContent';
import {
  transformProductDetail,
} from '../../../utils/product.transform';
import DetailsTab from '../../../components/product-details/DetailsTab';
import RelatedProducts from '../../../components/product-details/RelatedProducts';
import {
  ADD_PRODUCT_VIEW_COUNT_GQL,
  QUERY_GET_PRODUCT_DETAIL_BY_SLUG
} from '../../../graphql/catelog.gql';
import {
  ADD_SIMPLE_PRODUCT_TO_CART_GQL,
  ADD_CONFIGRURABLE_PRODUCT_TO_CART_GQL, GET_CART_INFO_GQL,
} from '../../../graphql/checkout.gql';
import { toastAlert } from '../../../utils/toastify';
import * as ROUTINGS from '../../../routings/path';
import { mapStateToProps, mapDispatchToProps } from './state';
import Facility from '../../../components/Common/Facility';
import NotFoundImage from '../../../images/404.png';
import callGetApi, {toAstError} from "../../../api";
import Meta from "../../../components/meta";

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: any) => {
  const [product, setProduct] = useState(null);
  const [productImage, setProductImage] = useState(null);
  const [addToCartType, setAddToCartType] = useState('');
  const [isSubmitLoading, setIsSubmitLoading] = useState(true);

  const {
    authState,
    match: {
      params: { productSlug },
    },
    checkoutState: { cartId },
    compareState: { compareListUUID, compareData },
    saveCartInfoAction,
    wishlistState,
    saveWishlistInfoAction,
    saveCompareList,
    history,
  } = props;

  const wishlistId = wishlistState.info && wishlistState.info.id;
  const wishlistItems = (wishlistState.info && wishlistState.info.items) || [];

  const init = () => {
    !!productSlug &&
    queryGetProductDetail(productSlug);
  };

  const queryGetProductDetail: any = (url_key) => {
    let final_query = QUERY_GET_PRODUCT_DETAIL_BY_SLUG.replaceAll("{{$param}}", "\"" + url_key + "\"");

    callGetApi(final_query, true).then((res: any) => {
      let products =
          !!res.data &&
          !!res.data.data &&
          !!res.data.data.products && res.data.data.products;

      if (
          !!products &&
          !!products.items &&
          !!products.items.length &&
          !!products.items[0]
      ) {
        const newProduct = transformProductDetail(products.items[0]);

        addProductViewCount({
          variables: {
            id: newProduct.id
          }
        });

        setProduct(newProduct);

          let newProductImage = [newProduct.image];

          if (!!newProduct.mediaGallery && !!newProduct.mediaGallery.length) {
              newProduct.mediaGallery.forEach((oneMedia) => {
                  if (newProduct.image.url !== oneMedia.url) {
                      newProductImage.push(oneMedia);
                  }
              });
          }

          setProductImage(newProductImage);
      }

      if (res.data.errors) {
        let errors = res.data.errors;
        toAstError(errors);
      }

      setIsSubmitLoading(false);
      return;
    });
  };

  const handleAddToCart = ({
    sku,
    quantity,
    isCongifurableProduct,
    parentSku,
    type,
  }) => {
    setAddToCartType(type);
    if (!isCongifurableProduct) {
      addSimpleProductToCart({
        variables: { sku, quantity, cartId },
      });
    } else {
      addConfigurableProductToCart({
        variables: { sku, quantity, cartId, parentSku },
      });
    }
  };

  const [addSimpleProductToCart, { loading: isAddSimpleProductLoading }]: any =
    useMutation(ADD_SIMPLE_PRODUCT_TO_CART_GQL, {
      onCompleted: ({ addSimpleProductsToCart }) => {
        toastAlert({ type: 'success', message: 'Added to cart successful' });
        const cartInfo =
          !!addSimpleProductsToCart && addSimpleProductsToCart.cart;
        !!cartInfo && saveCartInfoAction(cartInfo);
        if (addToCartType === 'buy-now') {
          history.push(ROUTINGS.ROUTING_CHECKOUT);
        } else {
          history.push(ROUTINGS.ROUTING_CART);
        }
      },
      onError: ({ message }) => {
        toastAlert({ type: 'error', message });
        getCartInfo({ variables: { cartId } });
      },
    });

  const [
    addConfigurableProductToCart,
    { loading: isAddConfigurationProductLoading },
  ]: any = useMutation(ADD_CONFIGRURABLE_PRODUCT_TO_CART_GQL, {
    onCompleted: ({ addConfigurableProductsToCart }) => {
      toastAlert({ type: 'success', message: 'Added to cart successful' });
      const cartInfo =
        !!addConfigurableProductsToCart && addConfigurableProductsToCart.cart;
      !!cartInfo && saveCartInfoAction(cartInfo);
      if (addToCartType === 'buy-now') {
        history.push(ROUTINGS.ROUTING_CHECKOUT);
      } else {
        history.push(ROUTINGS.ROUTING_CART);
      }
    },
    onError: ({ message }) => {
      toastAlert({ type: 'error', message });
      getCartInfo({ variables: { cartId } });
    },
  });

  const [detectFetchNewInfo, setDetectFetchNewInfo] = useState('');
  const handleFetchNewInfo = (sku) => {
    setDetectFetchNewInfo(sku);
  }

  const [addProductViewCount]: any = useLazyQuery(
    ADD_PRODUCT_VIEW_COUNT_GQL, {
      fetchPolicy: 'network-only',
    }
  );

  const [getCartInfo, { loading, data: cartDataInfo }]: any = useLazyQuery(GET_CART_INFO_GQL, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    onCompleted: ({ cart }) => {
      saveCartInfoAction(cart);
    },
    onError: ({ message }: any) => {
      if(!!cartDataInfo) {
        const { cart } = cartDataInfo;
        if(!!saveCartInfoAction(cart)) {
          saveCartInfoAction(cart);
        }
      }
    },
  });

  useEffect(() => {
    init();
  }, [props.match.params.productSlug]);

  if (!!isSubmitLoading)
    return (
      <div
        className='py-5 my-5'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span className='spinner-grow spinner-grow-lg my-5' />
      </div>
    );

  if (!product) {
    return (
      <section className='error-area ptb-60'>
        <div className='container'>
          <div className='error-content'>
            <img src={NotFoundImage} alt='error' />

            <h3>Product not Found</h3>
            <p>
              The page you are looking for might have been removed had its name
              changed or is temporarily unavailable.
            </p>

            <NavLink to='/'>
              <a className='btn btn-light'>Go to Home</a>
            </NavLink>
          </div>
        </div>
      </section>
    );
  }
  return (
    <>
      <Meta
          title={product.meta_title ? product.meta_title : product.name}
          description={product.meta_description}
          keywords={product.meta_keyword}
      />
      <Breadcrumb title={product.name} />

      <section className='products-details-area pt-60'>
        <div className='container'>
          <div className='row'>
            {!!productImage && !!productImage.length && (
              <ProductImage list={productImage} />
            )}

            <ProductContent
              product={product}
              wishlistId={wishlistId}
              authState={authState}
              wishlistItems={wishlistItems}
              onAddToCart={handleAddToCart}
              saveWishlistInfoAction={saveWishlistInfoAction}
              isAddToCartLoading={
                !!isAddSimpleProductLoading ||
                !!isAddConfigurationProductLoading
              }
              compareListUUID={compareListUUID}
              compareData={compareData}
              saveCompareList={saveCompareList}
              onFetchNewInfo={handleFetchNewInfo}
            />

            <DetailsTab product={product} detectFetchNewInfo={detectFetchNewInfo}/>
          </div>
        </div>

        {!!product &&
          !!product.relatedProducts &&
          !!product.relatedProducts.length && (
            <RelatedProducts products={product} />
          )}

        <Facility />
      </section>
    </>
  );
});
