import React, { Component, useEffect, useState } from "react";
import { GET_SIZE_GUIDE_AREA_GQL } from "../../graphql/layout.gql";
import { useLazyQuery } from "@apollo/client";
import { toastAlert } from "../../utils/toastify";
import { renderHtmlContent } from "../../utils/html";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import Facility from "../../components/Common/Facility";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Meta from "../../components/meta";

export default () => {
  const [sizeguide, setSizeGuide] = useState(null);

  const init = () => {
    getSizeGuide();
  };

  const [getSizeGuide]: any = useLazyQuery(GET_SIZE_GUIDE_AREA_GQL, {
    fetchPolicy: "network-only",
    onCompleted: ({ cmsBlocks }) => {
      setSizeGuide(cmsBlocks.items);
    },
    onError: ({ message }) => toastAlert({ type: "error", message }),
  });

  useEffect(() => {
    init();
  }, []);

  const [meta, setMeta] = useState({
    title: "Sizing Guide page",
    description: "",
    keyword: ""
  });

  return (
    <>
      <Meta
          title={meta.title}
          description={meta.description}
          keywords={meta.keyword}
      />
      <Breadcrumb title="Sizing Guide" />
      <section className="sizing-guide-area ptb-60">
        <div className="container">
          <div className="section-title">
            <h2>
              <span className="dot"></span> Size Guide
            </h2>
          </div>
          {!!sizeguide &&
            !!sizeguide.length &&
            sizeguide.map((item) => {
              return renderHtmlContent({ content: item.content });
            })}
        </div>
      </section>
      <Facility />
    </>
  );
};
