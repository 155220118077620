import * as ROUTINGS from '../../routings/path';
import { REDUCER_GROUP } from '../reducer.group';
import * as ACTION_TYPE from './type';

export const INITIAL_STATE = {
  info: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = INITIAL_STATE,
  action = {
    type: '',
    payload: { data: {} },
    meta: {},
    group: '',
  }
) => {
  if (action.group !== REDUCER_GROUP.AUTH) return state;

  switch (action.type) {
    case ACTION_TYPE.SAVE_AUTH_INFO:
      const { data }: any = action.payload;

      const { token, expiredTime } = saveDataInfoLocal(data);
      forceNavigateToHome();

      return Object.assign({}, state, {
        info: { token, expiredTime },
      });

    case ACTION_TYPE.SIGN_OUT:
      localStorage.clear();
      forceNavigateToHome();
      return INITIAL_STATE;

    default:
      return state;
  }
};

const saveDataInfoLocal = ({
  token,
  expired_after_hours: expiredAfterHours,
}: any) => {
  const now = new Date().getTime();
  const HOUR_TO_MILI_SECOND = 60 * 60 * 1000;
  const expiredTime =
    now +
    (expiredAfterHours <= 0 ? 8640 : expiredAfterHours) * HOUR_TO_MILI_SECOND;

  localStorage.setItem('token', token);
  localStorage.setItem('expired-time', expiredTime.toString());

  return { token, expiredTime };
};

const forceNavigateToHome = () =>
  setTimeout(() => (window.location.href = ROUTINGS.ROUTING_HOME), 500);
