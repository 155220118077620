import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';

import {
  ADD_PRODUCT_REVIEW,
  GET_REVIEW_META_DATA,
  GET_ADDITIONAL_INFO_GQL,
} from '../../graphql/catelog.gql';
import {
  QUERY_GET_SHIPPING_TAB_AREA_GQL,
  QUERY_GET_WHY_BUY_TAB_AREA_GQL,
} from '../../graphql/layout.gql';
import { toastAlert } from '../../utils/toastify';
import { renderHtmlContent } from '../../utils/html';
import { RatingValue } from '../../utils/rating';
import callGetApi, {toAstError} from "../../api";

const UseTagFunc = () => {
  let useTag = '<use xlink:href="#star" />';

  return <svg className='star' dangerouslySetInnerHTML={{ __html: useTag }} />;
};

const DetailsTab = (props) => {
  const [nickname, setNickname] = useState('');
  const [summary, setSummary] = useState('');
  const [text, setText] = useState('');
  const [ratingsId, setRatingId] = useState('');
  const [ratingsValueId, setRatingsValuesId] = useState('');

  const handleChangeNickName = (e) => setNickname(e.target.value);
  const handleChangeSummary = (e) => setSummary(e.target.value);
  const handleChangeText = (e) => setText(e.target.value);

  const openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName('tabs_item');
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove('fadeInUp');
      tabcontent[i].style.display = 'none';
    }

    tablinks = document.getElementsByTagName('li');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace('current', '');
    }

    document.getElementById(tabNmae).style.display = 'block';
    document.getElementById(tabNmae).className += ' fadeInUp animated';
    evt.currentTarget.className += 'current';
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();

    if (!!isSignUpLoading) return;

    if (
      !ratingsValueId ||
      !ratingsValueId.length ||
      !ratingsId ||
      !ratingsId.length
    ) {
      toastAlert({
        type: 'error',
        message: 'Please select rating value before',
      });
      return;
    }

    submitForm({
      variables: {
        sku: product.sku,
        nickname,
        summary,
        text,
        ratingsId,
        ratingsValueId,
      },
    });
    setNickname('');
    setSummary('');
    setText('');
    setRatingsValuesId('');
  };
  const [submitForm, { loading: isSignUpLoading }]: any = useMutation(
    ADD_PRODUCT_REVIEW,
    {
      onCompleted: () => {
        toastAlert({
          type: 'success',
          message: 'Submit Form successful',
        });
      },
      onError: ({ message }) => {
        toastAlert({ type: 'error', message });
      },
    }
  );

  //Get Tab
  const [shipping, setShipping] = useState(null);
  const [whybuyTab, setWhyBuyTab] = useState(null);
  const [info, setInfo] = useState(null);

  const [getInfo]: any = useLazyQuery(GET_ADDITIONAL_INFO_GQL, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getAdditionalInfo }) => {
      setInfo(getAdditionalInfo.items);
    },
    onError: ({ message }) => toastAlert({ type: 'error', message }),
  });

  const queryGetShippingTab = () => {
    callGetApi(QUERY_GET_SHIPPING_TAB_AREA_GQL).then((res: any) => {
      let cmsBlocks =
          !!res.data &&
          !!res.data.data &&
          !!res.data.data.cmsBlocks && res.data.data.cmsBlocks;

      if (cmsBlocks) {
        setShipping(cmsBlocks.items);
      }

      if (res.data.errors) {
        let errors = res.data.errors;
        toAstError(errors);
      }
    });
  }

  const queryGetWhyBuyTab = () => {
    callGetApi(QUERY_GET_WHY_BUY_TAB_AREA_GQL).then((res: any) => {
      let cmsBlocks =
          !!res.data &&
          !!res.data.data &&
          !!res.data.data.cmsBlocks && res.data.data.cmsBlocks;

      if (cmsBlocks) {
        setWhyBuyTab(cmsBlocks.items);
      }

      if (res.data.errors) {
        let errors = res.data.errors;
        toAstError(errors);
      }
    });
  }

  const [reviewMetaData, setReviewMetaData] = useState([]);
  const [getReviewMetaData]: any = useLazyQuery(GET_REVIEW_META_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: ({ productReviewRatingsMetadata }) => {
      if (!productReviewRatingsMetadata) return;
      const ratingsIdRes =
        productReviewRatingsMetadata?.items &&
        productReviewRatingsMetadata?.items[0].id;
      setRatingId(ratingsIdRes);

      const reviewMetaDataValue =
        !!productReviewRatingsMetadata.items &&
        productReviewRatingsMetadata.items[0] &&
        productReviewRatingsMetadata.items[0].values;
      setReviewMetaData(reviewMetaDataValue);
    },
  });

  useEffect(() => {
    getReviewMetaData();
    queryGetShippingTab();
    queryGetWhyBuyTab();
    getInfo({
      variables: {
        sku: product.sku,
      },
    });
  }, []);

  const { product, detectFetchNewInfo } = props;
  useEffect(() => {
    '' !== detectFetchNewInfo &&
      getInfo({
        variables: {
          sku: detectFetchNewInfo,
        },
      });
  }, [detectFetchNewInfo]);

  return (
    <div className='col-lg-12 col-md-12'>
      <div id='product-space-tab'></div>
      <div className='tab products-details-tab' style={{ paddingBottom: 50 }}>
        <div className='row'>
          <div className='col-lg-12 col-md-12'>
            <ul className='tabs'>
              <li
                onClick={(e) => {
                  e.preventDefault();
                  openTabSection(e, 'tab1');
                }}
                className='current'
              >
                <span className='tabs-nav-text'>
                  <div className='dot'></div> Description
                </span>
              </li>

              <li
                onClick={(e) => {
                  e.preventDefault();
                  openTabSection(e, 'tab2');
                }}
              >
                <span className='tabs-nav-text'>
                  <div className='dot'></div> Additional information
                </span>
              </li>

              <li
                onClick={(e) => {
                  e.preventDefault();
                  openTabSection(e, 'tab3');
                }}
              >
                <span className='tabs-nav-text'>
                  <div className='dot'></div> Shipping
                </span>
              </li>

              <li
                onClick={(e) => {
                  e.preventDefault();
                  openTabSection(e, 'tab4');
                }}
              >
                <span className='tabs-nav-text'>
                  <div className='dot'></div> Why Buy From Us
                </span>
              </li>

              <li
                onClick={(e) => {
                  e.preventDefault();
                  openTabSection(e, 'tab5');
                }}
              >
                <span className='tabs-nav-text'>
                  <div className='dot'></div> Reviews
                </span>
              </li>
            </ul>
          </div>

          <div className='col-lg-12 col-md-12'>
            <div className='tab_content'>
              <div id='tab1' className='tabs_item'>
                <div className='products-details-tab-content'>
                  {!!product &&
                    renderHtmlContent({ content: product.description })}
                </div>
              </div>

              <div id='tab2' className='tabs_item'>
                <div className='products-details-tab-content'>
                  <div className='table-responsive'>
                    <table className='table table-striped'>
                      <tbody>
                        {!!info &&
                          info.length &&
                          info.map((data, idx) => {
                            return (
                              <tr key={idx}>
                                <td>{data.label}</td>
                                <td>{data.value}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div id='tab3' className='tabs_item'>
                <div className='products-details-tab-content'>
                  <div className='table-responsive'>
                    {!!shipping &&
                      !!shipping.length &&
                      shipping.map((item) => {
                        return renderHtmlContent({ content: item.content });
                      })}
                  </div>
                </div>
              </div>

              <div id='tab4' className='tabs_item'>
                <div className='products-details-tab-content'>
                  {!!whybuyTab &&
                    !!whybuyTab.length &&
                    whybuyTab.map((item) => {
                      return renderHtmlContent({ content: item.content });
                    })}
                </div>
              </div>

              <div id='tab5' className='tabs_item'>
                <div className='products-details-tab-content'>
                  <div className='product-review-form'>
                    <h3>Customer Reviews</h3>

                    <div className='review-title'>
                      <div className='rating'>
                        <RatingValue value={product.ratingSummary / 20} />
                      </div>
                      <p>Based on {product.reviewCount} reviews</p>

                      <NavLink
                        to='#'
                        className='btn btn-light'
                        onClick={(e) => {
                          e.preventDefault();
                          const scrollToTop =
                            document.getElementById('review-top');
                          !!scrollToTop && scrollToTop.scrollIntoView();
                        }}
                      >
                        Write a Review
                      </NavLink>
                    </div>

                    <div className='review-comments'>
                      {!!product &&
                        product.reviews.map((data, idx) => {
                          return (
                            <div className='review-item' key={idx}>
                              <div className='rating'>
                                <RatingValue value={data.average_rating / 20} />
                              </div>
                              <h3>{data.summary}</h3>
                              <span>
                                <strong>{data.nickname}</strong> on{' '}
                                <strong>{data.created_at}</strong>
                              </span>
                              <p>{data.text}</p>

                              {/* <NavLink to='#'>
                                <a className='review-report-link'>
                                  Report as Inappropriate
                                </a>
                              </NavLink> */}
                            </div>
                          );
                        })}
                    </div>

                    <div className='review-form' id='review-top'>
                      <h3>Write a Review</h3>

                      <form onSubmit={handleSubmitForm}>
                        <div className='form-group'>
                          <label>
                            Name <span className='required'>*</span>
                          </label>
                          <input
                            type='text'
                            value={nickname}
                            required
                            onChange={handleChangeNickName}
                            id='name'
                            name='name'
                            placeholder='Enter your name'
                            className='form-control'
                          />
                        </div>

                        <div className='review-rating'>
                          <p>Rate this item</p>

                          <div className='star-source'>
                            <svg>
                              <linearGradient
                                x1='50%'
                                y1='5.41294643%'
                                x2='87.5527344%'
                                y2='65.4921875%'
                                id='grad'
                              >
                                <stop stopColor='#f2b01e' offset='0%'></stop>
                                <stop stopColor='#f2b01e' offset='60%'></stop>
                                <stop stopColor='#f2b01e' offset='100%'></stop>
                              </linearGradient>
                              <symbol id='star' viewBox='153 89 106 108'>
                                <polygon
                                  id='star-shape'
                                  stroke='url(#grad)'
                                  strokeWidth='5'
                                  fill='currentColor'
                                  points='206 162.5 176.610737 185.45085 189.356511 150.407797 158.447174 129.54915 195.713758 130.842203 206 95 216.286242 130.842203 253.552826 129.54915 222.643489 150.407797 235.389263 185.45085'
                                ></polygon>
                              </symbol>
                            </svg>
                          </div>

                          <div className='star-rating'>
                            {reviewMetaData &&
                              reviewMetaData.length &&
                              reviewMetaData
                                .slice(0)
                                .reverse()
                                .map((item, index) => (
                                  <>
                                    <input
                                      type='radio'
                                      name='star'
                                      id={`rating-item-${index}`}
                                    />
                                    <label
                                      htmlFor={`rating-item-${index}`}
                                      onClick={() => {
                                        setRatingsValuesId(item.value_id);
                                      }}
                                    >
                                      <UseTagFunc />
                                    </label>
                                  </>
                                ))}
                          </div>
                        </div>

                        <div className='form-group'>
                          <label>
                            Review Title <span className='required'>*</span>
                          </label>
                          <input
                            value={summary}
                            onChange={handleChangeSummary}
                            type='text'
                            required
                            id='review-title'
                            name='review-title'
                            placeholder='Enter your review a title'
                            className='form-control'
                          />
                        </div>

                        <div className='form-group'>
                          <label>
                            Body of Review (1500){' '}
                            <span className='required'>*</span>
                          </label>
                          <textarea
                            value={text}
                            required
                            onChange={handleChangeText}
                            name='review-body'
                            id='review-body'
                            cols={30}
                            rows={10}
                            placeholder='Write your comments here'
                            className='form-control'
                          />
                        </div>
                        <button
                          style={{ width: 170 }}
                          type='submit'
                          className='btn btn-light'
                        >
                          {!isSignUpLoading ? (
                            'Submit Review'
                          ) : (
                            <span className='spinner-grow spinner-grow-sm' />
                          )}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsTab;
