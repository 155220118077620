import React, { useEffect, useState } from "react";
import { GET_SHIPPING_AREA_GQL } from "../../graphql/layout.gql";
import { useLazyQuery } from "@apollo/client";
import { toastAlert } from "../../utils/toastify";
import { renderHtmlContent } from "../../utils/html";

const Shipping = (props) => {
  const [shipping, setShipping] = useState(null);

  const init = () => {
    getShipping();
  };

  const [getShipping]: any = useLazyQuery(GET_SHIPPING_AREA_GQL, {
    fetchPolicy: "network-only",
    onCompleted: ({ cmsBlocks }) => {
      setShipping(cmsBlocks.items);
    },
    onError: ({ message }) => toastAlert({ type: "error", message }),
  });

  useEffect(() => {
    init();
  }, []);

  const { closeShipModal } = props;
  return (
    <div
      className="modal fade productShippingModal show"
      style={{ paddingRight: "16px", display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <button
            type="button"
            onClick={closeShipModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i className="fas fa-times"></i>
            </span>
          </button>

          <div className="shipping-content">
            {!!shipping &&
              !!shipping.length &&
              shipping.map((item) => {
                return renderHtmlContent({ content: item.content });
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shipping;
