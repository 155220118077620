import { store } from '../containers/app-entry-point';

export const auth = {
  clearSession: () => localStorage.clear(), // TODO: REMOVE?

  loggedIn: () => {
    // const state = store.getState();
    // const authState = state && (state as any).auth;

    // if (!authState || !authState.info || !authState.info) return false;

    // return true;

    const token = localStorage.getItem('token');
    return !!token;
  },
};

export const checkExpiredTime = () => {
  const expiredTime = localStorage.getItem('expired-time');
  if (!expiredTime) return;

  const now = new Date().getTime();
  const offset = parseFloat(expiredTime) * 1 - now;

  if (offset <= 0) localStorage.clear();
};
