import React, { Component, useEffect, useState } from "react";
import { GET_SIZE_GUIDE_AREA_GQL } from "../../graphql/layout.gql";
import { useLazyQuery } from "@apollo/client";
import { scrollElement } from "../../utils/scroll";
import { toastAlert } from "../../utils/toastify";
import { renderHtmlContent } from "../../utils/html";

const SizeGuide = (props) => {
  const { closeSizeGuide } = props;
  const [sizeguide, setSizeGuide] = useState(null);

  const init = () => {
    getSizeGuide();
  };

  const [getSizeGuide]: any = useLazyQuery(GET_SIZE_GUIDE_AREA_GQL, {
    fetchPolicy: "network-only",
    onCompleted: ({ cmsBlocks }) => {
      setSizeGuide(cmsBlocks.items);
    },
    onError: ({ message }) => toastAlert({ type: "error", message }),
  });

  useEffect(() => {
    init();
  }, []);

  return (
    <div
      className="modal fade sizeGuideModal show"
      style={{ paddingRight: "16px", display: "block", overflow: "scroll" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <button
            type="button"
            onClick={closeSizeGuide}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i className="fas fa-times"></i>
            </span>
          </button>

          <div className="modal-sizeguide">
            <h3>Size Guide</h3>
            <p>
              This is an approximate conversion table to help you find your
              size.
            </p>
            {!!sizeguide &&
              !!sizeguide.length &&
              sizeguide.map((item) => {
                return renderHtmlContent({ content: item.content });
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SizeGuide;
