import axios from 'axios';
import {toastAlert} from "../utils/toastify";

export default function callGetApi(endpoint, isNeedAuth = false, method = 'GET') {
    const store = localStorage.getItem('store') || 'default';

    let authorization = null;
    if(isNeedAuth === true) {
        const token = localStorage.getItem('token');
        authorization = token ? `Bearer ${token}` : null;
    }

    // @ts-ignore
    return axios({
        method,
        headers: {
            Store: store,
            Authorization: authorization
        },
        url: `${process.env.REACT_APP_GRAPHQL_URI}?query=${endpoint}`,
    }).catch((err) => {
        console.log(err);
    });
}

export function toAstError(errors) {
    Object.keys(errors).map(function (key) {
        toastAlert({type: 'error', message: errors[key].message})
    });
}
