import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import ReactPaginate from 'react-paginate';
import { getUrlParameter } from '../../../utils/uri';
import { NavLink } from 'react-router-dom';
import * as ROUTINGS from '../../../routings/path';

import {
  GET_CUSTOMER_ORDER_LIST_GQL,
  GET_CUSTOMER_ORDER_DETAIL_GQL,
} from '../../../graphql/order.gql';
import { mapStateToProps, mapDispatchToProps } from './state';
import styles from './style.module.scss';

interface IProps {
  userState: any;
  location: any;
  history: any;
  saveUerInfoAction: any;
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: IProps) => {
  const {
    location: { search },
    history,
  } = props;
  const [productList, setProductList] = useState(null);

  const [currentPage, setCurrentPage] = useState('1');
  const [totalPage, setTotalPage] = useState(0);

  const init = () => {
    const currentPageParams = getUrlParameter(search, 'page') || '1';
    setCurrentPage(currentPageParams);

    getCustomerOrder({
      variables: { currentPage: currentPageParams },
    });
  };

  const [getCustomerOrder, { loading: isLoading }]: any = useLazyQuery(
    GET_CUSTOMER_ORDER_LIST_GQL,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ customer }) => {
        setProductList(customer.orders.items);

        const totalPage = Math.floor(
          customer.orders.total_count / customer.orders.page_info.page_size
        );
        setTotalPage(
          totalPage * customer.orders.page_info.page_size ===
            customer.orders.total_count
            ? totalPage
            : totalPage + 1
        );
      },
    }
  );

  const updateUrl = ({ value }) => {
    const generateNewUrl = `${window.location.pathname}?page=${value}`;

    history.push(generateNewUrl);
  };

  const hanleOnPageChange = ({ selected }) => {
    if (selected + 1 === parseInt(currentPage)) return;

    updateUrl({ value: selected * 1 + 1 });
    setCurrentPage(selected);
  };

  useEffect(() => {
    init();
  }, [search]);

  return (
    <>
      <table className='table'>
        <thead>
          <tr>
            <th scope='col'>Order #</th>
            <th scope='col'>Date</th>
            <th scope='col'>Status</th>
            <th scope='col'>Order Total</th>
            <th scope='col' style={{ width: '10%' }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {!!productList && productList.length ? (
            productList.map((data, idx) => {
              return (
                <tr key={idx} style={{ lineHeight: '50px' }}>
                  <td className='product-name'>{data.number}</td>
                  <td className='product-name'>{data.order_date}</td>
                  <td className='product-name'>{data.status}</td>

                  <td className='product-price'>
                    <span className='unit-amount'>
                      ${data.total.grand_total.value}
                    </span>
                  </td>
                  <td>
                    <NavLink
                      style={{ whiteSpace: 'nowrap' }}
                      to={`${ROUTINGS.ROUTING_ORDER_LIST}/${data.number}`}
                    >
                      View Order
                    </NavLink>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td className='product-thumbnail' colSpan={5}>
                <p>Empty.</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {totalPage > 1 && (
        <div
          className='col-lg-12 col-md-12'
          style={{ opacity: isLoading ? 0 : 1 }}
        >
          <div className={styles.pagination}>
            <ReactPaginate
              initialPage={parseInt(currentPage) - 1}
              activeClassName={styles.activeLink}
              activeLinkClassName={styles.activeLink}
              breakClassName={''}
              breakLabel='...'
              breakLinkClassName={''}
              containerClassName={''}
              disabledClassName={''}
              marginPagesDisplayed={1}
              nextClassName={''}
              nextLabel='Next'
              nextLinkClassName={styles.nextLink}
              onPageChange={hanleOnPageChange}
              pageClassName={styles.page}
              pageCount={totalPage}
              pageLinkClassName={styles.pageLink}
              pageRangeDisplayed={5}
              previousClassName={styles.previous}
              previousLabel='Prev'
              previousLinkClassName={styles.previousLink}
              subContainerClassName='pages pagination'
            />
          </div>
        </div>
      )}
    </>
  );
});
