import { connect } from 'react-redux';
import classnames from 'classnames';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';
import QuickView from '../Modal/QuickView';
import AddToCart from '../Shared/AddToCart';
import { toastAlert } from '../../utils/toastify';
import { checkWishlist } from '../../utils/wishlist';
import * as ROUTINGS from '../../routings/path';
import { saveWishlistInfoAction } from '../../flows/wishlist/action';
import { saveCompareList as saveCompareListAction } from '../../flows/compare/action';
import { RatingValue } from '../../utils/rating';
import styles from './ProductsCard.module.scss';
import './ProductsCard.scss';
import {
  GET_WISH_LIST,
  ADD_PRODUCT_TO_WISH_LIST,
  ADD_CONFIGURABLE_PRODUCT_TO_WISH_LIST,
  REMOVE_PRODUCT_FROM_WISH_LIST,
  ADD_PRODUCT_TO_COMPARE_LIST,
  REMOVE_PRODUCT_FROM_COMPARE_LIST,
  GET_COMPARE_LIST,
} from '../../graphql/customer.gql';

const ProductsCard = (props: any) => {
  let {
    products,
    wishlistState,
    authState,
    saveWishlistInfoAction,
    compareState: { compareData, compareListUUID },
    saveCompareList,
    isCarousel,
  } = props;
  const wishlistId = wishlistState.info && wishlistState.info.id;

  const wishlistItems = (wishlistState.info && wishlistState.info.items) || [];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleModalData = (data) => setModalData(data);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleWishlist = (e, data, wishlistItemId, rootProduct) => {
    e.preventDefault();

    if (!authState.info) {
      toastAlert({
        type: 'error',
        message: 'Please login to wishlist',
      });
      return;
    }

    const { sku } = data;
    if (!wishlistId || !sku) return;
    if (
      !!isAddWishlistLoading ||
      !!isRemoveWishlistLoading ||
      !!isAddConfigurableWishlistLoading
    )
      return;

    if (!!wishlistItemId) {
      removeProductFromWishlist({
        variables: {
          wishlistId,
          id: wishlistItemId,
        },
      });
    } else {
      if (!!rootProduct.configurableOptions) {
        addConfigurableProductToWishlist({
          variables: {
            parentSku: rootProduct.sku,
            wishlistId,
            sku,
          },
        });
      } else {
        addProductToWishlist({
          variables: {
            wishlistId,
            sku,
          },
        });
      }
    }
  };

  const [addProductToWishlist, { loading: isAddWishlistLoading }]: any =
    useMutation(ADD_PRODUCT_TO_WISH_LIST, {
      onCompleted: ({}) => {
        toastAlert({
          type: 'success',
          message: 'Adding product to with list success',
        });
        getWishList();
      },
      onError: ({ message }) => toastAlert({ type: 'error', message }),
    });

  const [
    addConfigurableProductToWishlist,
    { loading: isAddConfigurableWishlistLoading },
  ]: any = useMutation(ADD_CONFIGURABLE_PRODUCT_TO_WISH_LIST, {
    onCompleted: ({}) => {
      toastAlert({
        type: 'success',
        message: 'Adding product to with list success',
      });
      getWishList();
    },
    onError: ({ message }) => toastAlert({ type: 'error', message }),
  });

  const [removeProductFromWishlist, { loading: isRemoveWishlistLoading }]: any =
    useMutation(REMOVE_PRODUCT_FROM_WISH_LIST, {
      onCompleted: ({}) => {
        toastAlert({
          type: 'info',
          message: 'Removing product from with list success',
        });
        getWishList();
      },
      onError: ({ message }) => toastAlert({ type: 'error', message }),
    });

  const [getWishList]: any = useLazyQuery(GET_WISH_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: ({ customer }) =>
      customer && saveWishlistInfoAction(customer.wishlist),
  });

  const checkIsInCompareList = (product) => {
    if (!compareData || !compareData.items) return false;

    const checkIsInCompareList =
      !!compareData.items &&
      !!compareData.items.length &&
      compareData.items.find((item) => {
        if (!item || !item.product) return false;
        return item.product.url_key === product.slug;
      });

    return !!checkIsInCompareList;
  };

  const handleCompare = (isInCompare, id) => {
    if (!compareData) return;

    if (!!isInCompare) {
      removeProductFromComparelist({
        variables: {
          compareListUUID,
          productId: id,
        },
      });
    } else {
      addProductToComparelist({
        variables: {
          compareListUUID,
          productId: id,
          trigger_report_event: true,
        },
      });
    }
  };

  const [addProductToComparelist, { loading: isAddComparelistLoading }]: any =
    useMutation(ADD_PRODUCT_TO_COMPARE_LIST, {
      onCompleted: ({}) => {
        toastAlert({
          type: 'success',
          message: 'Adding product to compare list success',
        });
        !!compareListUUID &&
          !!compareListUUID.length &&
          getCompareList({ variables: { compareListUUID } });
      },
      onError: ({ message }) => toastAlert({ type: 'error', message }),
    });

  const [
    removeProductFromComparelist,
    { loading: isRemoveComparelistLoading },
  ]: any = useMutation(REMOVE_PRODUCT_FROM_COMPARE_LIST, {
    onCompleted: ({}) => {
      toastAlert({
        type: 'success',
        message: 'Removing product from compare list success',
      });
      !!compareListUUID &&
        !!compareListUUID.length &&
        getCompareList({ variables: { compareListUUID } });
    },
    onError: ({ message }) => toastAlert({ type: 'error', message }),
  });

  const [getCompareList]: any = useLazyQuery(GET_COMPARE_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: ({ compareList }) => {
      saveCompareList({ compareData: compareList });
    },
  });

  return (
    <>
      {products.map((data, idx) => {
        const selectedProduct = !!data.configurableOptions
          ? data.variants && data.variants[0] && data.variants[0].product
          : data;

        const wishlistItemId = checkWishlist({ wishlistItems, data: data });
        const isInCompare = checkIsInCompareList(data);

        return (
          <div
            style={isCarousel && { paddingLeft: 10, paddingRight: 10 }}
            className={
              !isCarousel
                ? 'col-lg-3 col-sm-6 col-md-4 col-6 products-col-item'
                : ''
            }
            key={idx}
          >
            <div
              className={classnames('single-product-box', styles.productItem)}
            >
              <div className='product-image'>
                <NavLink
                  style={{}}
                  className={'product-image-link'}
                  to={`${ROUTINGS.ROUTING_PRODUCT_DETAIL_PATH}/${data.slug}`}
                >
                  <img src={data.small_image} alt={data.image_label} />
                  <img src={data.imageHover} alt={data.image_label} />
                </NavLink>

                <ul className={styles.actionList}>
                  <li>
                    <NavLink
                      to='#'
                      data-tip='Quick View'
                      data-place='left'
                      onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openModal();
                        handleModalData(data.slug);
                      }}
                    >
                      <i className='far fa-eye'></i>
                    </NavLink>
                  </li>
                  <li className={!!wishlistItemId && styles.visible}>
                    <NavLink
                      to='#'
                      onClick={(e) =>
                        handleWishlist(e, selectedProduct, wishlistItemId, data)
                      }
                      data-tip='Add to Wishlist'
                      data-place='left'
                    >
                      <i
                        className={`${
                          !!wishlistItemId ? 'fas' : 'far'
                        } fa-heart`}
                      ></i>
                    </NavLink>
                  </li>
                  <li className={!!isInCompare && styles.visible}>
                    <NavLink
                      to='#'
                      data-tip='Add to Compare'
                      data-place='left'
                      onClick={(e) => {
                        e.preventDefault();
                        handleCompare(isInCompare, data.id);
                      }}
                    >
                      <i className='fas fa-sync'></i>
                    </NavLink>
                  </li>
                </ul>
              </div>

              <div className='product-content'>
                <h3>
                  <NavLink
                    to={`${ROUTINGS.ROUTING_PRODUCT_DETAIL_PATH}/${data.slug}`}
                  >
                    {data.title}
                  </NavLink>
                </h3>

                <div className='product-price'>
                  {!!data.oldPrice && (
                    <span
                      style={{
                        textDecoration: 'line-through',
                        opacity: 0.4,
                        marginRight: 10,
                      }}
                      className='new-price'
                    >
                      ${data.oldPrice}
                    </span>
                  )}
                  <span className='new-price'>${data.price}</span>
                </div>

                <div className='rating' style={{ height: 21 }}>
                  {data.reviewCount ? (
                    <>
                      <RatingValue value={data.ratingSummary / 20} />
                      <span>({data.reviewCount})</span>
                    </>
                  ) : null}
                </div>

                <AddToCart product={data} />
              </div>
            </div>
          </div>
        );
      })}
      {modalOpen ? (
        <QuickView closeModal={closeModal} modalData={modalData} />
      ) : (
        ''
      )}
    </>
  );
};

export const mapStateToProps = (state) => ({
  wishlistState: state.wishlist,
  authState: state.auth,
  compareState: state.compare,
});

export const mapDispatchToProps = (dispatch) => ({
  saveWishlistInfoAction: (data) => dispatch(saveWishlistInfoAction(data)),
  saveCompareList: (data) => dispatch(saveCompareListAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsCard);
