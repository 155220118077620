import React, { Component, useEffect, useState } from "react";
import { GET_CUSTOMER_SERVICE_CONTENT_AREA_GQL } from "../../graphql/layout.gql";
import { useLazyQuery } from "@apollo/client";
import { toastAlert } from "../../utils/toastify";
import { renderHtmlContent } from "../../utils/html";

import Facility from "../../components/Common/Facility";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Meta from "../../components/meta";

export default () => {
  const [servicecontent, setServiceContent] = useState(null);

  const init = () => {
    getServiceContent();
  };

  const [getServiceContent]: any = useLazyQuery(
    GET_CUSTOMER_SERVICE_CONTENT_AREA_GQL,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ cmsBlocks }) => {
        setServiceContent(cmsBlocks.items);
      },
      onError: ({ message }) => toastAlert({ type: "error", message }),
    }
  );

  useEffect(() => {
    init();
  }, []);

    const [meta, setMeta] = useState({
        title: "Terms Of Service - iGBMerch Official Website",
        description: "OVERVIEW This website is operated by IGB Merch. Throughout the site, the terms “we”, “us” and “our” refer to IGB Merch. IGB Merch offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices",
        keyword: ""
    });

  return (
    <>
        <Meta
            title={meta.title}
            description={meta.description}
            keywords={meta.keyword}
        />
      <Breadcrumb title="Customer Service" />

      <section className="customer-service-area ptb-60">
        <div className="container">
          <div className="section-title">
            <h2>
              <span className="dot"></span> Customer Service
            </h2>
          </div>

          <div className="customer-service-content">
            {!!servicecontent &&
              !!servicecontent.length &&
              servicecontent.map((item) => {
                return renderHtmlContent({ content: item.content });
              })}
          </div>
        </div>
      </section>
      <Facility />
    </>
  );
};
