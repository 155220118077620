import React, { Component, useEffect, useState } from "react";
import {
  QUERY_GET_COPY_RIGHT_AREA_GQL,
  QUERY_GET_FOOTER_AREA_GQL,
} from "../../graphql/layout.gql";
import { renderHtmlContent } from "../../utils/html";
import callGetApi, {toAstError} from "../../api";

const Footer = () => {
  const [foorter, setFoorter] = useState(null);
  const [copyRight, setCopyRight] = useState(null);

  const init = () => {
    queryGetFooter();
    queryGetCopyRight();
  };

  const queryGetFooter = () => {
    callGetApi(QUERY_GET_FOOTER_AREA_GQL).then((res: any) => {
      let cmsBlocks =
          !!res.data &&
          !!res.data.data &&
          !!res.data.data.cmsBlocks && res.data.data.cmsBlocks;

      if (cmsBlocks) {
        setFoorter(cmsBlocks.items);
      }

      if (res.data.errors) {
        let errors = res.data.errors;
        toAstError(errors);
      }
    });
  };

  const queryGetCopyRight = () => {
    callGetApi(QUERY_GET_COPY_RIGHT_AREA_GQL).then((res: any) => {
      let cmsBlocks =
          !!res.data &&
          !!res.data.data &&
          !!res.data.data.cmsBlocks && res.data.data.cmsBlocks;

      if (cmsBlocks) {
        setCopyRight(cmsBlocks.items);
      }

      if (res.data.errors) {
        let errors = res.data.errors;
        toAstError(errors);
      }
    });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <footer className="footer-area">
      <div className="container">
        {!!foorter &&
          !!foorter.length &&
          foorter.map((item) => {
            return renderHtmlContent({ content: item.content });
          })}
      </div>

      <div className="copyright-area">
        {!!copyRight &&
          !!copyRight.length &&
          copyRight.map((item) => {
            return renderHtmlContent({ content: item.content });
          })}
      </div>
    </footer>
  );
};

export default Footer;
