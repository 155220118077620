import React, { Component, useEffect, useState } from "react";
import { GET_INSTAGRAM_SQL } from "../../graphql/layout.gql";
import { useLazyQuery } from "@apollo/client";
import { toastAlert } from "../../utils/toastify";
import * as ROUTINGS from "../../routings/path";
import { NavLink } from "react-router-dom";
import { renderHtmlContent } from "../../utils/html";

import OwlCarousel from "react-owl-carousel3";

const options = {
  loop: true,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='fas fa-chevron-left'></i>",
    "<i class='fas fa-chevron-right'></i>",
  ],
  responsive: {
    0: {
      items: 3,
    },
    576: {
      items: 4,
    },
    768: {
      items: 6,
    },
    1200: {
      items: 9,
    },
  },
};

const InstagramPhoto = () => {
  const [display, setDisplay] = useState(false);
  const [panel, setPanel] = useState(true);
  const [instagram, setInstagram] = useState(null);

  const init = () => {
    getInstagram();
  };

  const [getInstagram]: any = useLazyQuery(GET_INSTAGRAM_SQL, {
    fetchPolicy: "network-only",
    onCompleted: ({ getGalleriesByCategoryId }) => {
      setInstagram(getGalleriesByCategoryId.items);
      setDisplay(true);
    },
    onError: ({ message }) => toastAlert({ type: "error", message }),
  });

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="instagram-area">
      {display ? (
        <OwlCarousel
          className="instagram-slides owl-carousel owl-theme"
          as
          any
          {...options}
        >
          {!!instagram &&
            !!instagram.length &&
            instagram.map((data) => {
              return (
                <div className="instagram-box" key={data.id}>
                  <img src={data.image_url} alt={data.alt} />
                  <a href={`${ROUTINGS.ROUTING_GALLERY}`}></a>
                </div>
              );
            })}
        </OwlCarousel>
      ) : (
        ""
      )}
    </div>
  );
};

export default InstagramPhoto;
