import { withRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import OrderSummary from './OrderSummary';
import { toastAlert } from '../../utils/toastify';
import { auth } from '../../utils/auth';
import { checkEmptryString } from '../../utils/validation';
import ConnectStripe from '../../containers/shop/connect-stripe';
import { useMutation, useLazyQuery } from '@apollo/client';

import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import * as ROUTINGS from '../../routings/path';
import {
  SET_SHIPPING_ADDRESS_GQL,
  SET_SHIPPING_ADDRESS_BY_ID_GQL,
  SET_BILLING_ADDRESS_GQL,
  SET_BILLING_ADDRESS_BY_ID_GQL,
  SET_EMAIL_ON_CART_GQL,
  SET_SHIPPING_METHOD_GQL,
  GET_CART_INFO_GQL,
  SET_PAYMENT_METHOD_GQL,
  PLACE_ORDER_GQL,
  GET_CUSTOMER_ADDRESS_GQL,
  CREATE_EMPTY_CART_GQL,
  CREATE_PAYPAL_TOKEN_GQL,
  APPLY_COUPON_CODE,
  REMOVE_COUPON_CODE,
} from '../../graphql/checkout.gql';

import NotFoundImage from '../../images/404.png';

import { mapStateToProps, mapDispatchToProps } from './state';
import {GET_AVAILABLE_COUNTRIES, QUERY_GET_AVAILABLE_COUNTRIES} from '../../graphql/customer.gql';
import callGetApi, {toAstError} from "../../api";
import {QUERY_GET_CUSTOM_PAYMENT_AREA_GQL} from "../../graphql/layout.gql";

let timerUpdateShippingAddress: any = null;

const InputGroup = ({
  inputType = 'text',
  label,
  requiredLabel = true,
  initValue,
  msg,
  validation,
  onUpdate,
  col = 6,
  onBlur,
}) => {
  const [value, setValue] = useState(initValue || '');
  const [isDirty, setDirty] = useState(false);
  const [isValid, setValid] = useState(true);

  const onChange = (e) => {
    !isDirty && setDirty(true);
    setValue(e.target.value);
  };

  const onKeyUp = () => {
    validate();
  };

  const hadnleOnBlur = () => {
    validate();
    onBlur();
  };

  const validate = () => {
    let valid = true;
    validation.map((item) => {
      switch (item) {
        case 'required':
          if (checkEmptryString(value)) valid = false;
          break;

        case 'zipcode':
          valid = true;
          break;

        case 'phone':
          if (!value.match(/^[+]?\d{9,12}$/g)) valid = false;
          break;

        case 'email':
          const re =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
          if (!value.match(re)) valid = false;

          break;
      }
    });

    setValid(valid);
    onUpdate({ value, valid });
  };

  return (
    <div className={`col-lg-${col} col-md-${col}`}>
      <div className='form-group'>
        <label>
          {label} {!!requiredLabel && <span className='required'>*</span>}
        </label>
        <input
          autoComplete='nope'
          type={inputType || 'text'}
          name='firstName'
          required
          className='form-control'
          onKeyDown={(e) => {
            if (validation.indexOf('zipcode') >= 0) {
              if (
                !(
                  (e.keyCode >= 48 && e.keyCode <= 57) ||
                  [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) >= 0
                )
              )
                e.preventDefault();
            }
          }}
          onKeyUp={onKeyUp}
          onChange={onChange}
          onBlur={hadnleOnBlur}
          value={value}
        />
        {!!isDirty && !isValid && (
          <p style={{ color: 'red', fontSize: '13px' }}>{msg}</p>
        )}
      </div>
    </div>
  );
};

const CheckoutForm2 = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
  };

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <CardElement
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      />
      <button type='submit' disabled={!stripe}>
        Pay
      </button>
    </form>
  );
};

const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');

const App = () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm2 />
  </Elements>
);

const CartEmpty = () => {
  return (
    <section className='error-area ptb-60'>
      <div className='container'>
        <div className='error-content'>
          <img src={NotFoundImage} alt='error' />

          <h3>Cart Empty</h3>
          <p>There are no item in cart. Please back to shopping page.</p>

          <NavLink to='/'>
            <a className='btn btn-light'>Go to Home</a>
          </NavLink>
        </div>
      </div>
    </section>
  );
};

const FormError = ({ formError, position, msg }) => {
  if (!formError || formError !== position) return null;

  return <p style={{ color: 'red', fontSize: '13px' }}>{msg}</p>;
};

const AddressForm = ({
  authState,
  checkoutState,
  onGetNewCart,
  onSelectShippingAddress,
  onSelectBillingAddress,
  onSelectEmail,
  onValidateForm,
}: any) => {
  const { cartId, cartInfo } = checkoutState;
  const token = authState.info && authState.info.token;
  const shippingAddresses =
    !!checkoutState &&
    !!checkoutState.cartInfo &&
    !!checkoutState.cartInfo.shipping_addresses &&
    !!checkoutState.cartInfo.shipping_addresses.length &&
    checkoutState.cartInfo.shipping_addresses[0];

  const billingAddresses =
    !!checkoutState &&
    !!checkoutState.cartInfo &&
    checkoutState.cartInfo.billing_address;

  const [countryCode, setCountryCode] = useState('');
  const handleOnChangeCountryCode = (e) => {
    setCountryCode(e.target.value);
  };

  const [AnotherCountryCode, setAnotherCountryCode] = useState('');
  const handleOnChangeAnotherCountryCode = (e) => {
    setAnotherCountryCode(e.target.value);
  };

  const [firstname, setFirstname] = useState(shippingAddresses.firstname || '');
  const handleOnChangeFirstname = ({ value }) => {
    setFirstname(value);
  };

  const [anotherFirstname, setAnotherFirstname] = useState(
    (billingAddresses && billingAddresses.firstname) || ''
  );
  const handleOnChangeAnotherFirstname = ({ value }) => {
    setAnotherFirstname(value);
  };

  const [lastname, setLastname] = useState(shippingAddresses.lastname || '');
  const handleOnChangeLastname = ({ value }) => {
    setLastname(value);
  };

  const [anotherLastname, setAnotherLastname] = useState(
    (billingAddresses && billingAddresses.lastname) || ''
  );
  const handleOnChangeAnotherLastname = ({ value }) => {
    setAnotherLastname(value);
  };

  const [company, setCompany] = useState(shippingAddresses.company || '');
  const handleOnChangeCompany = ({ value }) => {
    setCompany(value);
  };

  const [street, setStreet] = useState(
    (shippingAddresses.street && shippingAddresses.street[0]) || ''
  );
  const handleOnChangeStreet = ({ value }) => {
    setStreet(value);
  };

  const [anotherStreet, setAnotherStreet] = useState(
    (billingAddresses &&
      billingAddresses.street &&
      billingAddresses.street[0]) ||
      ''
  );
  const handleOnChangeAnotherStreet = ({ value }) => {
    setAnotherStreet(value);
  };

  const [city, setCity] = useState(shippingAddresses.city || '');
  const handleOnChangeCity = ({ value }) => {
    setCity(value);
  };

  const [anotherCity, setAnotherCity] = useState(
    (billingAddresses && billingAddresses.city) || ''
  );
  const handleOnChangeAnotherCity = ({ value }) => {
    setAnotherCity(value);
  };

  const [postcode, setPostcode] = useState(shippingAddresses.postcode || '');
  const handleOnChangePostcode = ({ value }) => {
    setPostcode(value);
  };

  const [anotherPostcode, setAnotherPostcode] = useState(
    (billingAddresses && billingAddresses.postcode) || ''
  );
  const handleOnChangeAnotherPostcode = ({ value }) => {
    setAnotherPostcode(value);
  };

  const [phonenumber, setPhonenumber] = useState(
    shippingAddresses.telephone || ''
  );
  const handleOnChangePhonenumber = ({ value }) => {
    setPhonenumber(value);
  };

  const [anotherPhonenumber, setAnotherPhonenumber] = useState(
    (billingAddresses && billingAddresses.telephone) || ''
  );
  const handleOnChangeAnotherPhonenumber = ({ value }) => {
    setAnotherPhonenumber(value);
  };

  const [email, setEmail] = useState(cartInfo.email || '');
  const handleOnChangeEmail = ({ value }) => {
    setEmail(value);

    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    onValidateForm(email.match(re) && checkValidShippingAddress());
  };

  const [customerNotes, setNotes] = useState(
    shippingAddresses.customer_notes || ''
  );
  const handleOnChangeNotes = (e) => {
    setNotes(e.target.value);
  };

  const [isSaveAddress, setSaveNewAddress] = useState(false);
  const handleToggleSaveAddress = (e) => {
    setSaveNewAddress(e.target.checked);
  };

  const [isAnotherAddress, setAnotherAddress] = useState(false);
  const handleToggleAnotherAddress = (e) => {
    setAnotherAddress(e.target.checked);

    updateShippingAddress();
    e.target.checked && updateBillingAddress();
  };

  const checkValidShippingAddress = () => {
    if (!countryCode || !countryCode.length) return false;

    if (!firstname || !firstname.length) return false;
    if (!lastname || !lastname.length) return false;
    if (!street || !street.length) return false;
    if (!city || !city.length) return false;
    if (!phonenumber || !phonenumber.length) return false;
    if (!phonenumber.match(/^[+]?\d{9,12}$/g)) return false;

    if (checkEmptryString(firstname)) return false;
    if (checkEmptryString(lastname)) return false;
    if (checkEmptryString(street)) return false;
    if (checkEmptryString(city)) return false;
    if (checkEmptryString(postcode)) return false;

    return true;
  };

  const handleValidateAllForm = () => {
    const shipping = checkValidShippingAddress();

    const billing = checkValidBillingAddress();

    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const checkEmail = email.match(re);

    if (isAnotherAddress) {
      onValidateForm(shipping && billing && (auth.loggedIn() || checkEmail));
    } else {
      onValidateForm(shipping && (auth.loggedIn() || checkEmail));
    }
  };

  useEffect(() => {
    handleValidateAllForm();
  }, [isAnotherAddress]);

  useEffect(() => {
    updateShippingAddress();
  }, [isSaveAddress]);

  const updateShippingAddress = () => {
    const check = checkValidShippingAddress();
    handleValidateAllForm();
    if (!check) return;

    setShippingAddress({
      variables: {
        countryCode,
        firstname,
        lastname,
        company,
        street,
        city,
        postcode,
        phonenumber,
        region: city,
        cartId,
        isSaveAddress,
        customerNotes,
      },
    });
    !!onSelectShippingAddress && onSelectShippingAddress(true);

    if (!isAnotherAddress) {
      setBillingAddress({
        variables: {
          countryCode,
          firstname,
          lastname,
          company,
          street,
          city,
          postcode,
          phonenumber,
          region: city,
          cartId,
        },
      });
      !!onSelectBillingAddress && onSelectBillingAddress(true);
    }
  };

  const checkValidBillingAddress = () => {
    if (!AnotherCountryCode || !AnotherCountryCode.length) return false;
    if (!anotherFirstname || !anotherFirstname.length) return false;
    if (!anotherLastname || !anotherLastname.length) return false;
    if (!anotherStreet || !anotherStreet.length) return false;
    if (!anotherCity || !anotherCity.length) return false;
    if (!anotherPhonenumber || !anotherPhonenumber.length) return false;
    if (!anotherPhonenumber.match(/^[+]?\d{9,12}$/g)) return false;

    if (checkEmptryString(anotherFirstname)) return false;
    if (checkEmptryString(anotherLastname)) return false;
    if (checkEmptryString(anotherStreet)) return false;
    if (checkEmptryString(anotherCity)) return false;

    if (checkEmptryString(anotherPhonenumber)) return false;

    return true;
  };

  const updateBillingAddress = () => {
    if (!isAnotherAddress) return;

    const check = checkValidBillingAddress();
    handleValidateAllForm();
    if (!check) return;

    setBillingAddress({
      variables: {
        countryCode: AnotherCountryCode,
        firstname: anotherFirstname,
        lastname: anotherLastname,
        company,
        street: anotherStreet,
        city: anotherCity,
        postcode: anotherPostcode,
        phonenumber: anotherPhonenumber,
        region: anotherCity,
        cartId,
      },
    });
    !!onSelectBillingAddress && onSelectBillingAddress(true);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    setShippingAddress({
      variables: {
        countryCode,
        firstname,
        lastname,
        company,
        street,
        city,
        postcode,
        phonenumber,
        region: city,
        cartId,
        isSaveAddress,
        customerNotes,
      },
    });
    !!onSelectShippingAddress && onSelectShippingAddress(true);

    setBillingAddress({
      variables: {
        countryCode,
        firstname,
        lastname,
        company,
        street,
        city,
        postcode,
        phonenumber,
        region: city,
        cartId,
      },
    });
    !!onSelectBillingAddress && onSelectBillingAddress(true);

    handleSetEmail();
  };

  const handleSetEmail = () => {
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    onValidateForm(email.match(re) && checkValidShippingAddress());

    if (!token && !!email && email.match(re)) {
      setEmailOnCart({
        variables: {
          cartId,
          email,
        },
      });
      !!onSelectEmail && onSelectEmail(true);
    }
  };

  const [setShippingAddress]: any = useMutation(SET_SHIPPING_ADDRESS_GQL, {
    onCompleted: ({}) => {
      !!onSelectShippingAddress && onSelectShippingAddress(false);
      onGetNewCart();
    },
    onError: ({ message }) => {
      !!onSelectShippingAddress && onSelectShippingAddress(false);
      toastAlert({ type: 'error', message });
    },
  });

  const [setBillingAddress]: any = useMutation(SET_BILLING_ADDRESS_GQL, {
    onCompleted: () => {
      !!onSelectBillingAddress && onSelectBillingAddress(false);
    },
    onError: ({ message }) => {
      !!onSelectBillingAddress && onSelectBillingAddress(false);
      toastAlert({ type: 'error', message });
    },
  });

  const [setEmailOnCart]: any = useMutation(SET_EMAIL_ON_CART_GQL, {
    onCompleted: (a) => {
      !!onSelectEmail && onSelectEmail(false);
    },
    onError: ({ message }) => {
      !!onSelectEmail && onSelectEmail(false);
      toastAlert({ type: 'error', message });
    },
  });

  useEffect(() => {
    updateShippingAddress();
  }, [countryCode]);

  useEffect(() => {
    updateBillingAddress();
  }, [AnotherCountryCode]);

  let initAvailableCountries = [{ code: '', country: '' }];
  const [availableCountries, setAvailableCountries] = useState(
    initAvailableCountries
  );

  const init = () => {
    let isMounted = true; // note mutable flag
    queryGetAvailableCountries(isMounted);
    return () => {
      isMounted = false;
    };
  };

  useEffect(() => {
    init();
  }, []);

  const [initLoading, setInitLoading] = useState(false);

  const queryGetAvailableCountries = (isMounted) => {
    setInitLoading(true);
    if(isMounted) {
      callGetApi(QUERY_GET_AVAILABLE_COUNTRIES).then((res: any) => {
        let countries =
            !!res.data &&
            !!res.data.data &&
            !!res.data.data.countries && res.data.data.countries;

        if (countries) {
          let availableData = [];
          countries.map((country) => {
            if (country.full_name_english) {
              availableData.push({
                code: country.id,
                country: country.full_name_english
              });
            }
          });
          setAvailableCountries(availableData);
        }

        if (res.data.errors) {
          let errors = res.data.errors;
          toAstError(errors);
        }
      });
    }
    setInitLoading(false);
  };

  return (
    <form autoComplete='off' onSubmit={handleOnSubmit}>
      <div className='billing-details'>
        <div className='row'>
          <div className='col-lg-12 col-md-12'>
            <div className='form-group'>
              <label>
                Country <span className='required'>*</span>
              </label>
              {!initLoading ? (
                <div className='select-box'>
                  <select
                    className='form-control'
                    name='country'
                    required
                    value={countryCode}
                    onChange={handleOnChangeCountryCode}
                    onBlur={updateShippingAddress}
                  >
                    <option value=''>Select Country</option>
                    {availableCountries.map(({ code, country }) => (
                      <option value={code}>{country}</option>
                    ))}
                  </select>
                </div>
              ) : (
                <span className='spinner-grow spinner-grow-sm' />
              )}
            </div>
          </div>
          <InputGroup
            label={'First Name'}
            initValue={firstname}
            msg={'Invalid first name format'}
            validation={['required']}
            onUpdate={handleOnChangeFirstname}
            onBlur={updateShippingAddress}
          />

          <InputGroup
            label={'Last Name'}
            initValue={lastname}
            msg={'Invalid last name format'}
            validation={['required']}
            onUpdate={handleOnChangeLastname}
            onBlur={updateShippingAddress}
          />

          <InputGroup
            label={'Company Name'}
            requiredLabel={false}
            initValue={company}
            msg={''}
            col={12}
            validation={[]}
            onUpdate={handleOnChangeCompany}
            onBlur={updateShippingAddress}
          />

          <InputGroup
            label={'Address'}
            initValue={street}
            col={12}
            msg={'This is required field'}
            validation={['required']}
            onUpdate={handleOnChangeStreet}
            onBlur={updateShippingAddress}
          />

          <InputGroup
            label={'Town / City'}
            initValue={city}
            msg={'This is required field'}
            validation={['required']}
            onUpdate={handleOnChangeCity}
            onBlur={updateShippingAddress}
          />

          <InputGroup
            label={'Postcode / Zip'}
            initValue={postcode}
            requiredLabel={false}
            msg={'Invalid zip format, use like 123456'}
            validation={['required']}
            onUpdate={handleOnChangePostcode}
            onBlur={updateShippingAddress}
          />

          {!token && (
            <InputGroup
              label={'Email'}
              initValue={email}
              msg={'Invalid email format'}
              validation={['required', 'email']}
              onUpdate={handleOnChangeEmail}
              onBlur={handleSetEmail}
            />
          )}

          <InputGroup
            label={'Phone'}
            inputType={'tel'}
            initValue={phonenumber}
            msg={'Invalid phone number format, use like +2923432432432'}
            validation={['required', 'phone']}
            onUpdate={handleOnChangePhonenumber}
            onBlur={updateShippingAddress}
          />

          <div className='col-lg-12 col-md-12'>
            <div className='form-group'>
              {auth.loggedIn() && (
                <label
                  htmlFor={'inputToggleCreateAddress'}
                  style={{ cursor: 'pointer' }}
                >
                  <input
                    id={'inputToggleCreateAddress'}
                    type={'checkbox'}
                    checked={isSaveAddress}
                    onChange={handleToggleSaveAddress}
                    style={{ margin: '5px 12px 5px 0' }}
                  />
                  Save as new address
                </label>
              )}
              <label
                htmlFor={'inputToggleAnotherAddress'}
                style={{ cursor: 'pointer' }}
              >
                <input
                  id={'inputToggleAnotherAddress'}
                  type={'checkbox'}
                  checked={isAnotherAddress}
                  onChange={handleToggleAnotherAddress}
                  style={{ margin: '5px 12px 5px 0' }}
                />
                Ship to a different address?
              </label>
            </div>
          </div>

          {isAnotherAddress && (
            <>
              <div className='col-lg-12 col-md-12'>
                <div className='form-group'>
                  <label>
                    Country <span className='required'>*</span>
                  </label>
                  {!initLoading ? (
                    <div className='select-box'>
                      <select
                        className='form-control'
                        name='country'
                        required
                        value={AnotherCountryCode}
                        onChange={handleOnChangeAnotherCountryCode}
                        onBlur={updateBillingAddress}
                      >
                        <option value=''>Select Country</option>
                        {availableCountries.map(({ code, country }) => (
                          <option value={code}>{country}</option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <span className='spinner-grow spinner-grow-sm' />
                  )}
                </div>
              </div>
              <InputGroup
                label={'First Name'}
                initValue={anotherFirstname}
                msg={'Invalid first name format'}
                validation={['required']}
                onUpdate={handleOnChangeAnotherFirstname}
                onBlur={updateBillingAddress}
              />

              <InputGroup
                label={'Last Name'}
                initValue={anotherLastname}
                msg={'Invalid last name format'}
                validation={['required']}
                onUpdate={handleOnChangeAnotherLastname}
                onBlur={updateBillingAddress}
              />

              {/* <InputGroup
                label={'Company Name'}
                requiredLabel={false}
                initValue={anotherCompany}
                msg={''}
                col={12}
                validation={[]}
                onUpdate={handleOnChangeAnotherCompany}/>           */}

              <InputGroup
                label={'Address'}
                initValue={anotherStreet}
                col={12}
                msg={'This is required field'}
                validation={['required']}
                onUpdate={handleOnChangeAnotherStreet}
                onBlur={updateBillingAddress}
              />

              <InputGroup
                label={'Town / City'}
                initValue={anotherCity}
                msg={'This is required field'}
                validation={['required']}
                onUpdate={handleOnChangeAnotherCity}
                onBlur={updateBillingAddress}
              />

              <InputGroup
                label={'Postcode / Zip'}
                initValue={anotherPostcode}
                requiredLabel={false}
                msg={''}
                validation={['required']}
                onUpdate={handleOnChangeAnotherPostcode}
                onBlur={updateBillingAddress}
              />

              <InputGroup
                label={'Phone'}
                inputType={'tel'}
                initValue={anotherPhonenumber}
                msg={'Invalid phone number format, use like +2923432432432'}
                validation={['required', 'phone']}
                onUpdate={handleOnChangeAnotherPhonenumber}
                onBlur={updateBillingAddress}
              />
            </>
          )}

          {/*  */}
          <div className='col-lg-12 col-md-12'>
            <div className='form-group'>
              <textarea
                name='notes'
                id='notes'
                cols={30}
                rows={6}
                value={customerNotes}
                onChange={handleOnChangeNotes}
                placeholder='Order Notes'
                className='form-control'
              />
            </div>
          </div>
        </div>
      </div>

      <button type='submit' style={{ display: 'none' }} />
    </form>
  );
};

const ShippingMethod = ({
  selectedShippingMethod,
  availableShippingMethods,
  isDisablePlaceOrder,
  onSelect,
}) => {
  return (
    <form style={{ pointerEvents: isDisablePlaceOrder ? 'none' : 'all' }}>
      <div className='payment-method'>
        <h3 className={'title'}>Shipping Method</h3>
        <p>
          {!availableShippingMethods ||
            (!availableShippingMethods.length &&
              'There are no avalable shipping method')}
        </p>
        {availableShippingMethods &&
          availableShippingMethods.map((item, idx) => {
            if(item) {
              const isSelected =
                  !!selectedShippingMethod &&
                  item.carrier_code === selectedShippingMethod.carrier_code &&
                  item.method_code === selectedShippingMethod.method_code;

              let carrier_title = (item?.carrier_title && item.carrier_title) || ((item?.carrier_code && item.carrier_code) || "");
              let method_title = (item?.method_title && item.method_title) || ((item?.method_code && item.method_code) || "");

              return (
                  <p>
                    <input
                        type='radio'
                        id={`shipping-method-${idx}`}
                        checked={isSelected}
                        name='radio-group'
                        onChange={() => onSelect(item)}
                    />
                    <label htmlFor={`shipping-method-${idx}`}>
                      {`Carrier: ${carrier_title} - Method: ${
                          method_title
                      } - Shipping Fee: ${(item.amount && item.amount.value) || 0}`}
                    </label>
                  </p>
              );
            }
          })}
      </div>
    </form>
  );
};

const PaymentMethod = ({
  availablePaymentMethods,
  selectedPaymentMethod,
  isDisablePlaceOrder,
  onSelect,
}) => {
  return (
    <form style={{ pointerEvents: isDisablePlaceOrder ? 'none' : 'all' }}>
      <div className='payment-method'>
        <h3 className={'title'}>Payment Method</h3>
        <p>
          {!availablePaymentMethods ||
            (!availablePaymentMethods.length &&
              'There are no avalable payment method')}
        </p>
        {availablePaymentMethods &&
          availablePaymentMethods.map((item, idx) => {
            const isSelected =
              !!selectedPaymentMethod &&
              (selectedPaymentMethod.code === item.code ||
                (selectedPaymentMethod.code === 'stripe_pending' &&
                  item.code === 'stripe_payments'));
            return (
              <p>
                <input
                  id={`payment-method-${idx}`}
                  checked={isSelected}
                  type='radio'
                  name='radio-group'
                  onChange={() => onSelect(item)}
                />
                <label htmlFor={`payment-method-${idx}`}>{item.title}</label>
              </p>
            );
          })}
      </div>
    </form>
  );
};

const CouponCode = ({
  onAddCouponCode,
  onRemoveCouponCode,
  isLoading = false,
  type = 'APPLY',
  couponCode = '',
}) => {
  const [inputCode, setInputCode] = useState('');
  const handleOnChange = (e) => {
    e.preventDefault();
    setInputCode(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddCouponCode(inputCode);
  };

  return (
    <div>
      <div className='payment-method'>
        <h3 className={'title'}>Coupon Code</h3>
        {type === 'APPLY' ? (
          <form
            className='form-group'
            style={{ display: 'flex', maxWidth: 400 }}
            onSubmit={handleSubmit}
          >
            <input
              type='text'
              placeholder={'Input coupon code...'}
              className='form-control'
              required
              onChange={handleOnChange}
            />
            <button
              style={{ width: 170 }}
              disabled={!!isLoading}
              className={`btn btn-primary`}
            >
              {!isLoading ? (
                'Add'
              ) : (
                <span className='spinner-grow spinner-grow-sm' />
              )}
            </button>
          </form>
        ) : (
          <div style={{ display: 'flex' }}>
            <div
              style={{
                height: 40,
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                lineHeight: '40px',
                padding: '0 15px',
                border: '1px solid #aaa',
              }}
            >
              {couponCode}
            </div>
            <button
              style={{ width: 115 }}
              disabled={!!isLoading}
              onClick={onRemoveCouponCode}
              className={`btn btn-primary`}
            >
              {!isLoading ? (
                'Remove'
              ) : (
                <span className='spinner-grow spinner-grow-sm' />
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const AddressList = ({ customerAddress, onSelectAddress, onAddingNew }) => {
  const [selected, setSelected] = useState(0);

  if (!customerAddress || !customerAddress.length) return null;

  const itemStyle = {
    border: '1px solid #eaedff',
    padding: 'calc(var(--bs-gutter-x)/ 2)',
    marginBottom: 'calc(var(--bs-gutter-x))',
    cursor: 'pointer',
  };

  const addingItem = {
    height: 'calc(100% - var(--bs-gutter-x))',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <form className='row'>
      {customerAddress.map((item) => (
        <label className='col-lg-6 col-md-12'>
          <div style={itemStyle}>
            <div>
              <input
                name={'address-item'}
                id={'address-list'}
                type={'radio'}
                checked={selected === item.id}
                onChange={() => {
                  onSelectAddress(item);
                  setSelected(item.id);
                }}
                style={{ margin: '5px 12px 5px 0' }}
              />
              <b>Name:</b> {`${item.lastname}, ${item.firstname}`}
            </div>
            <div>
              <b>Phone number:</b> {item.telephone}
            </div>
            <div>
              <b>Address:</b>{' '}
              {`${item.street && item.street[0]}, ${item.city}, ${
                item.country_code
              }`}
            </div>
          </div>
        </label>
      ))}
      <label
        className='col-lg-6 col-md-12'
        onClick={() => {
          onAddingNew();
          setSelected(0);
        }}
      >
        <div style={{ ...itemStyle, ...addingItem }}>
          <span style={{ marginRight: 5 }} className={'fa fa-plus'}></span>
          Create New Address
        </div>
      </label>
    </form>
  );
};

function CheckoutForm({
  history,
  authState,
  checkoutState,
  clearCartAction,
  saveCartInfoAction,
  saveCartTokenAction,
  saveCustomerAddressAction,
  updateSelectedPaymentMethodAction,
}: any) {
  const { cartId, customerAddress } = checkoutState;
  const token = authState.info && authState.info.token;
  const cartInfo = checkoutState && checkoutState.cartInfo;

  const appliedCoupons = cartInfo.applied_coupons || [];
  const couponCode = (!!appliedCoupons.length && appliedCoupons[0].code) || '';

  const availableShippingMethods =
    !!cartInfo &&
    !!cartInfo.shipping_addresses &&
    !!cartInfo.shipping_addresses.length &&
    cartInfo.shipping_addresses[0].available_shipping_methods;

  const selectedShippingMethod =
    (!!cartInfo &&
      !!cartInfo.shipping_addresses &&
      !!cartInfo.shipping_addresses[0] &&
      cartInfo.shipping_addresses[0].selected_shipping_method) ||
    null;

  const availablePaymentMethods =
    !!checkoutState &&
    !!checkoutState.cartInfo &&
    checkoutState.cartInfo.available_payment_methods;

  const selectedPaymentMethod =
    !!checkoutState &&
    !!checkoutState.cartInfo &&
    checkoutState.cartInfo.selected_payment_method;

  useEffect(() => {
    !!token && getCustomerAddress();
    handleGetCart();
  }, []);

  const [getCustomerAddress]: any = useLazyQuery(GET_CUSTOMER_ADDRESS_GQL, {
    fetchPolicy: 'network-only',
    onCompleted: ({ customer }) =>
      !!customer &&
      !!customer.addresses &&
      saveCustomerAddressAction(customer.addresses),
    onError: ({ message }) => toastAlert({ type: 'error', message }),
  });

  const handleSelectShippingMethod = (shippingMethod) => {
    setShippingMethod({
      variables: {
        cartId,
        carrierCode: shippingMethod.carrier_code,
        methodCode: shippingMethod.method_code,
      },
    });
    setSelectShippingMethodLoading(true);
  };

  const [setShippingMethod]: any = useMutation(SET_SHIPPING_METHOD_GQL, {
    onCompleted: ({}) => {
      setSelectShippingMethodLoading(false);
      handleGetCart();
    },
    onError: ({ message }) => {
      setSelectShippingMethodLoading(false);
      toastAlert({ type: 'error', message });
    },
  });

  const handleGetCart = () => {
    getCartInfo({ variables: { cartId } });
  };

  const [getCartInfo, { loading, data: cartData }]: any = useLazyQuery(
    GET_CART_INFO_GQL,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      onCompleted: ({ cart }) => {
        saveCartInfoAction(cart);
        checkOutOfStock(cart);
      },
      onError: ({ message }: any) => {
        if (!!cartData) {
          const { cart } = cartData;
          if (!!cart) {
            saveCartInfoAction(cart);
            checkOutOfStock(cart);
          }
        }
        toastAlert({ type: 'error', message });

        setTimeout(() => {
          history.push(ROUTINGS.ROUTING_CART);
        }, 1000);
      },
    }
  );

  const checkOutOfStock = (cart) => {
    if (!cart || !cart.items) return;
    let check = false;
    cart.items.map((item) => {
      if (item?.product?.stock_status === 'OUT_OF_STOCK') {
        check = true;
      }
    });

    if (check) {
      history.push(ROUTINGS.ROUTING_CART);
    }
  };

  const handleSelectPaymentMethod = (paymentMethod) => {
    if (paymentMethod.code === 'stripe_payments') {
      updateSelectedPaymentMethodAction({
        code: 'stripe_pending',
        title: 'pending',
      });
      return;
    }
    setPaymentMethod({
      variables: {
        cartId,
        paymentMethodCode: paymentMethod.code,
      },
    });
    setSelectPaymentMethodLoading(true);
  };

  const [setPaymentMethod]: any = useMutation(SET_PAYMENT_METHOD_GQL, {
    onCompleted: ({}) => {
      setSelectPaymentMethodLoading(false);
      handleGetCart();
    },
    onError: ({ message }) => {
      setSelectPaymentMethodLoading(false);
      toastAlert({ type: 'error', message });
    },
  });

  const validateWithoutPayment = () => {
    if (!!isDisplayAddressForm) {
      if (!isValidAddressForm) {
        toastAlert({
          type: 'error',
          message: 'Error: Please fill address form',
        });
        return false;
      }
    } else {
      if (!customerAddressId) {
        toastAlert({
          type: 'error',
          message: 'Error: Please select address',
        });
        return false;
      }
    }

    if (!cartInfo.items || !cartInfo.items.length) {
      toastAlert({ type: 'error', message: 'Error: No items in cart' });
      return false;
    }

    if (!cartInfo.shipping_addresses || !cartInfo.shipping_addresses.length) {
      toastAlert({ type: 'error', message: 'Error: No shipping address yet' });
      return false;
    }

    if (!cartInfo.billing_address) {
      toastAlert({ type: 'error', message: 'Error: No billing address yet' });
      return false;
    }

    if (
      !cartInfo.shipping_addresses[0] ||
      !cartInfo.shipping_addresses[0].selected_shipping_method
    ) {
      toastAlert({
        type: 'error',
        message: 'Error: No shipping method selected yet',
      });
      return false;
    }

    return true;
  };

  const validateBeforePlaceOrder = () => {
    if (!validateWithoutPayment()) return false;

    if (
      !cartInfo.selected_payment_method ||
      !cartInfo.selected_payment_method.code ||
      !cartInfo.selected_payment_method.code.length
    ) {
      toastAlert({
        type: 'error',
        message: 'Error: No payment method selected yet',
      });
      return false;
    }

    return true;
  };

  const handlePlaceOrder = () => {
    if (!cartInfo) return;
    if (!validateBeforePlaceOrder()) return;

    setIsDisablePlaceOrder(true);
    const {
      selected_payment_method: { code: paymentMethodCode },
    } = cartInfo;

    if ('paypal_express' === paymentMethodCode) {
      return createPaypalToken({
        variables: {
          cartId,
          returnUrl: process.env.REACT_APP_PAYPAL_RETURN_URL,
          cancelUrl: process.env.REACT_APP_PAYPAL_CANCEL_URL,
        },
      });
    }

    placeOrder({ variables: { cartId } });
  };

  const [placeOrder, { loading: isPlaceOrderLoading }]: any = useMutation(
    PLACE_ORDER_GQL,
    {
      onCompleted: ({ placeOrder }) => {
        toastAlert({
          type: 'success',
          message: 'Congratulation, your order is completed',
        });
        clearCartAction();
        createEmptyCart();
        history.push(
          `${ROUTINGS.ROUTING_ORDER_SUCCESS}?id=${
            placeOrder && placeOrder.order && placeOrder.order.order_number
          }`
        );
      },
      onError: ({ message }) => toastAlert({ type: 'error', message }),
    }
  );

  const [createEmptyCart]: any = useMutation(CREATE_EMPTY_CART_GQL, {
    onCompleted: ({ createEmptyCart }) => saveCartTokenAction(createEmptyCart),
  });

  const isDisplayAddressList = customerAddress.length > 0;

  const [customerAddressId, setCustomerAddressId] = useState(0);
  const handleSelctAddressItem = (data) => {
    setDisplayAddressForm(false);

    setCustomerAddressId(parseInt(data.id));
    setShippingAddressById({
      variables: {
        cartId,
        customerNotes,
        customerAddressId: parseInt(data.id),
      },
    });

    setSelectShippingAddressLoading(true);
    setBillingAddressById({
      variables: {
        cartId,
        customerAddressId: parseInt(data.id),
      },
    });

    setSelectBillingAddressLoading(true);
  };

  const [setShippingAddressById]: any = useMutation(
    SET_SHIPPING_ADDRESS_BY_ID_GQL,
    {
      onCompleted: ({}) => {
        setSelectShippingAddressLoading(false);
        handleGetCart();
      },
      onError: ({ message }) => {
        setSelectShippingAddressLoading(false);
        toastAlert({ type: 'error', message });
      },
    }
  );

  const [setBillingAddressById]: any = useMutation(
    SET_BILLING_ADDRESS_BY_ID_GQL,
    {
      onCompleted: ({}) => {
        setSelectBillingAddressLoading(false);
      },
      onError: ({ message }) => {
        setSelectBillingAddressLoading(true);
        toastAlert({ type: 'error', message });
      },
    }
  );

  const [isDisplayAddressForm, setDisplayAddressForm] = useState(
    !isDisplayAddressList
  );

  const [createPaypalToken]: any = useMutation(CREATE_PAYPAL_TOKEN_GQL, {
    onCompleted: ({ createPaypalExpressToken }) => {
      if (!createPaypalExpressToken)
        return toastAlert({
          type: 'error',
          message: 'Something went wrong, please try again',
        });

      const {
        paypal_urls: { start: redirectUrl },
      } = createPaypalExpressToken;
      window.location.href = redirectUrl;
    },
    onError: ({ message }) => toastAlert({ type: 'error', message }),
  });

  const [isSelectShippingAddressLoading, setSelectShippingAddressLoading] =
    useState(false);
  const [isSelectBillingAddressLoading, setSelectBillingAddressLoading] =
    useState(false);
  const [isSelectEmailLoading, setSelectEmailLoading] = useState(false);
  const [isSelectShippingMethodLoading, setSelectShippingMethodLoading] =
    useState(false);
  const [isSelectPaymentMethodLoading, setSelectPaymentMethodLoading] =
    useState(false);

  const initIsDisablePlaceOrder =
    !!isPlaceOrderLoading ||
    !!isSelectShippingAddressLoading ||
    !!isSelectBillingAddressLoading ||
    !!isSelectEmailLoading ||
    !!isSelectShippingMethodLoading ||
    !!isSelectPaymentMethodLoading;

  const [isDisablePlaceOrder, setIsDisablePlaceOrder] = useState(initIsDisablePlaceOrder);

  const [isValidAddressForm, setValidAddressForm] = useState(false);
  const handleValidateForm = (state) => setValidAddressForm(state);

  const handleAddCouponCode = (couponCode) => {
    applyCouponCode({
      variables: {
        cartId,
        couponCode,
      },
    });
  };

  const handleRemoveCouponCode = () => {
    removeCouponCode({
      variables: {
        cartId,
      },
    });
  };

  const [applyCouponCode, { loading: isLoadingApplyCouponCode }]: any =
    useMutation(APPLY_COUPON_CODE, {
      onCompleted: (data) => {
        handleGetCart();
        toastAlert({ type: 'success', message: 'Adding coupon code success' });
      },
      onError: ({ message }) => {
        toastAlert({ type: 'error', message });
      },
    });

  const [removeCouponCode, { loading: isLoadingRemoveCouponCode }]: any =
    useMutation(REMOVE_COUPON_CODE, {
      onCompleted: (data) => {
        toastAlert({ type: 'success', message: 'Remove coupon code success' });
        handleGetCart();
      },
      onError: ({ message }) => {
        toastAlert({ type: 'error', message });
      },
    });

  const shippingAddresses =
    !!checkoutState &&
    !!checkoutState.cartInfo &&
    !!checkoutState.cartInfo.shipping_addresses &&
    !!checkoutState.cartInfo.shipping_addresses.length &&
    checkoutState.cartInfo.shipping_addresses[0];

  const [customerNotes, setNotes] = useState(
    shippingAddresses.customer_notes || ''
  );

  const handleOnChangeNotes = (e) => {
    setNotes(e.target.value);
  };

  if (!cartInfo || !cartInfo.items || !cartInfo.items.length) {
    return <CartEmpty />;
  }

  const isTripeSelected =
    selectedPaymentMethod &&
    (selectedPaymentMethod.code === 'stripe_pending' ||
      selectedPaymentMethod.code === 'stripe_payments');

  return (
    <section className='checkout-area ptb-60'>
      <div className='container'>
        {!token && (
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <div className='user-actions'>
                <i className='fas fa-sign-in-alt'></i>
                <span>
                  Returning customer?{' '}
                  <NavLink to={ROUTINGS.ROUTING_AUTH_SIGN_IN}>
                    Click here to login
                  </NavLink>
                </span>
              </div>
            </div>
          </div>
        )}

        <div>
          <div className='row'>
            <div className='col-lg-6 col-md-12'>
              <div className='billing-details'>
                <h3 className='title'>Billing Details</h3>
                {!!isDisplayAddressList && (
                  <>
                    <AddressList
                      {...{ customerAddress }}
                      onSelectAddress={handleSelctAddressItem}
                      onAddingNew={() => setDisplayAddressForm(true)}
                    />
                    {!isDisplayAddressForm && (
                      <div className='col-lg-12 col-md-12'>
                        <div className='form-group'>
                          <textarea
                            name='notes'
                            id='notes'
                            cols={30}
                            rows={6}
                            value={customerNotes}
                            onChange={handleOnChangeNotes}
                            placeholder='Order Notes'
                            className='form-control'
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
                {!!isDisplayAddressForm && (
                  <AddressForm
                    onValidateForm={handleValidateForm}
                    authState={authState}
                    checkoutState={checkoutState}
                    onSelectShippingAddress={setSelectShippingAddressLoading}
                    onSelectBillingAddress={setSelectBillingAddressLoading}
                    onSelectEmail={setSelectEmailLoading}
                    onGetNewCart={handleGetCart}
                  />
                )}
              </div>
            </div>

            <div className='col-lg-6 col-md-12'>
              <div className='order-details'>
                <h3 className='title'>Your Order</h3>

                {!!cartInfo && (
                  <OrderSummary
                    selectedShippingMethod={selectedShippingMethod}
                    cartInfo={cartInfo}
                  />
                )}
                <CouponCode
                  couponCode={couponCode}
                  onAddCouponCode={handleAddCouponCode}
                  onRemoveCouponCode={handleRemoveCouponCode}
                  isLoading={
                    isLoadingApplyCouponCode || isLoadingRemoveCouponCode
                  }
                  type={!!cartInfo.applied_coupons ? 'REMOVE' : 'APPLY'}
                />
                {!!availableShippingMethods &&
                  !!availableShippingMethods.length && (
                    <ShippingMethod
                      isDisablePlaceOrder={isDisablePlaceOrder}
                      availableShippingMethods={availableShippingMethods}
                      selectedShippingMethod={selectedShippingMethod}
                      onSelect={handleSelectShippingMethod}
                    />
                  )}

                {!!availablePaymentMethods &&
                  !!availablePaymentMethods.length && (
                    <PaymentMethod
                      isDisablePlaceOrder={isDisablePlaceOrder}
                      selectedPaymentMethod={selectedPaymentMethod}
                      availablePaymentMethods={availablePaymentMethods}
                      onSelect={handleSelectPaymentMethod}
                    />
                  )}

                {isTripeSelected && (
                  <div>
                    <ConnectStripe
                      disabled={
                        isDisablePlaceOrder ||
                        (!isValidAddressForm && !!isDisplayAddressForm)
                      }
                      onSuccess={() => {
                        handlePlaceOrder();
                      }}
                      onError={() => {}}
                    />
                  </div>
                )}

                {!isTripeSelected && (
                  <div
                    style={{
                      marginTop: 40,
                      borderTop: '1px solid #eaedff',
                      paddingTop: 30,
                    }}
                  >
                    <button
                      style={{
                        width: 170,
                        opacity:
                          isDisablePlaceOrder ||
                          (!isValidAddressForm && !!isDisplayAddressForm)
                            ? 0.5
                            : 1,
                      }}
                      disabled={
                        isDisablePlaceOrder ||
                        (!isValidAddressForm && !!isDisplayAddressForm)
                      }
                      onClick={handlePlaceOrder}
                      className={`btn btn-primary`}
                    >
                      {!isDisablePlaceOrder ? (
                        'Place Order'
                      ) : (
                        <span className='spinner-grow spinner-grow-sm' />
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CheckoutForm)
);
