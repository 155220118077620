import React, { Component } from "react";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useMutation, useLazyQuery } from "@apollo/client";
import { checkEmptryString } from "../../utils/validation";
import { toastAlert } from "../../utils/toastify";

import * as ROUTINGS from "../../routings/path";
import {
  SUBSCRIBE_EMAIL_GQL,
  QUERY_GET_NEWSLETTER_CONTENT_AREA_GQL
} from "../../graphql/layout.gql";
import { renderHtmlContent } from "../../utils/html";
import callGetApi, {toAstError} from "../../api";

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [letterContent, setLetterContent] = useState(null);

  const handleChangeEmail = (e) => setEmail(e.target.value);

  const [submitEmail, { loading: isLoading }]: any = useMutation(
    SUBSCRIBE_EMAIL_GQL,
    {
      onCompleted: () => {
        toastAlert({
          type: "success",
          message: "Subscribe Email successful",
        });
      },
      onError: ({ message }) => toastAlert({ type: "error", message }),
    }
  );

  const queryGetLetterContent = () => {
    callGetApi(QUERY_GET_NEWSLETTER_CONTENT_AREA_GQL).then((res: any) => {
      let cmsBlocks =
          !!res.data &&
          !!res.data.data &&
          !!res.data.data.cmsBlocks && res.data.data.cmsBlocks;

      if (cmsBlocks) {
        setLetterContent(cmsBlocks.items);
      }

      if (res.data.errors) {
        let errors = res.data.errors;
        toAstError(errors);
      }
    });
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (!!isLoading) return;

    submitEmail({
      variables: {
        email,
      },
    });
    setEmail("");
  };
  useEffect(() => {
    queryGetLetterContent();
  }, []);

  return (
    <section className="subscribe-area ptb-60">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            {!!letterContent &&
              letterContent.map((item) => {
                return renderHtmlContent({ content: item.content });
              })}
          </div>

          <div className="col-lg-6">
            <form
              className="newsletter-form"
              data-toggle="validator"
              onSubmit={handleSubmitForm}
            >
              <input
                type="email"
                value={email}
                onChange={handleChangeEmail}
                className="form-control"
                placeholder="Enter your email address"
                name="EMAIL"
                required={true}
                autoComplete="off"
              />
              <button type="submit" style={{ width: 120 }}>
                {!isLoading ? (
                  "Subscribe"
                ) : (
                  <span className="spinner-grow spinner-grow-sm" />
                )}
              </button>
              <div id="validator-newsletter" className="form-result"></div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
