import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { toastAlert } from '../../utils/toastify';
import { GET_COLLECTION_LISTS_GQL } from '../../graphql/layout.gql';

import * as ROUTINGS from '../../routings/path';
import { transformCollection } from '../../utils/collection.transform';

const ProductsCollections = () => {
  const [collectionList, setCollectionList] = useState(null);
  const init = () => {
    getCollectionList();
  };

  const [getCollectionList]: any = useLazyQuery(GET_COLLECTION_LISTS_GQL, {
    fetchPolicy: 'network-only',
    onCompleted: ({ categories }) => {
      setCollectionList(transformCollection(categories.items[0].children));
    },
    onError: ({ message }) => toastAlert({ type: 'error', message }),
  });

  useEffect(() => {
    init();
  }, []);

  const imageSrc = "/category_placeholder.png";

  return (
    <section className='products-collections-area ptb-60'>
      <div className='container'>
        <div className='section-title'>
          <h2>
            <span className='dot'></span> Collections
          </h2>
        </div>

        <div className='row'>
          {!!collectionList &&
            collectionList.map((data, idx) => {
              return (
                <div className='col-lg-4 col-md-6'>
                  <div className='collections-box'>
                    <img src={!!data.image && data.image || imageSrc} alt='image' />

                    <div className='category'>
                      <h4>{data.name}</h4>
                      <span>{data.product_count}</span>
                    </div>

                    <NavLink
                      to={`${ROUTINGS.ROUTING_PRODUCT_CATEGORY_PATH}/${data.url_key}`}
                    >
                      <a></a>
                    </NavLink>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default ProductsCollections;
