import { useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Wishlist from "../Modal/Wishlist";
import { toastAlert } from "../../utils/toastify";
import * as ROUTINGS from "../../routings/path";

const TopHeader = ({ wishlistState }: any) => {
  const items = (wishlistState.info && wishlistState.info.items) || [];
  const [display, setDisplay] = useState(false);

  const handleWishlist = () => {
    if (!items || !items.length) {
      return toastAlert({
        type: "error",
        message: "There are no item in wishlist.",
      });
    }
    setDisplay(!display);
  };

  return (
    <>
      <div className="top-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-6">
              <ul className="top-header-nav">
                <li>
                  <NavLink to="/about">
                    <a>About</a>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/">
                    <a>Our Stores</a>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/faq">
                    <a>FAQ's</a>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact-us">
                    <a>Contact</a>
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="col-lg-5 col-md-6">
              <ul className="top-header-right-nav">
                <li>
                  <NavLink to="#">
                    <a
                      data-toggle="modal"
                      data-target="#shoppingWishlistModal"
                      onClick={(e) => {
                        e.preventDefault();
                        handleWishlist();
                      }}
                    >
                      Wishlist <i className="far fa-heart"></i>
                    </a>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`${ROUTINGS.ROUTING_COMPARE}`}>
                    <a>
                      Compare <i className="fas fa-balance-scale"></i>
                    </a>
                  </NavLink>
                </li>
                <li>
                  <div className="languages-list">
                    <select>
                      <option value="1">Eng</option>
                      <option value="2">Ger</option>
                      <option value="3">Span</option>
                    </select>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {display ? <Wishlist items={items} onClick={handleWishlist} /> : ""}
    </>
  );
};

export const mapStateToProps = (state) => ({
  wishlistState: state.wishlist,
});

export const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);
