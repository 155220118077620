import React, { Component, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { toastAlert } from '../../utils/toastify';
import { renderHtmlContent } from '../../utils/html';

import { GET_TESTIMONIALS_SLIDER_GQL } from '../../graphql/layout.gql';

import OwlCarousel from 'react-owl-carousel3';

const options = {
  loop: true,
  nav: false,
  dots: true,
  autoplayHoverPause: true,
  items: 1,
  autoplay: true,
  navText: [
    "<i class='fas fa-chevron-left'></i>",
    "<i class='fas fa-chevron-right'></i>",
  ],
};

const TestimonialsTwo = () => {
  const [panel, setPanel] = useState(true);
  const [display, setDisplay] = useState(false);
  const [slider, setSlider] = useState(null);

  const init = () => {
    getSlider();
  };

  const [getSlider]: any = useLazyQuery(GET_TESTIMONIALS_SLIDER_GQL, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getSlider }) => {
      setSlider(getSlider.items);
      setDisplay(true);
    },
    onError: ({ message }) => toastAlert({ type: 'error', message }),
  });

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='testimonials-area ptb-60 bg2'>
      <div className='container'>
        {display ? (
          <OwlCarousel
            className='testimonials-slides owl-carousel owl-theme'
            {...options}
          >
            {!!slider &&
              !!slider.length &&
              slider.map((item) => renderHtmlContent({ content: item.html }))}
            {/* <div className="single-testimonials">
                <div className="client-image">
                  <img
                    src={
                      "https://novine-react.envytheme.com/_next/static/images/news-img2-c1c57d2f2f581d2d30da87251bf0e0c6.jpg"
                    }
                    alt="image"
                  />
                </div>

                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                  amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Quis ipsum
                  suspendisse ultrices gravida. Risus commodo viverra maecenas
                  accumsan lacus vel facilisis.
                </p>

                <div className="client-info">
                  <h4>Jason Statham</h4>
                  <span>Founder at Brand</span>
                </div>
              </div> */}

            {/* <div class='single-testimonials'>
                <div class='client-image'>
                  <img
                    src={
                      'https://novine-react.envytheme.com/_next/static/images/news-img2-c1c57d2f2f581d2d30da87251bf0e0c6.jpg'
                    }
                    alt='image'
                  />
                </div>

                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                  amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Quis ipsum
                  suspendisse ultrices gravida. Risus commodo viverra maecenas
                  accumsan lacus vel facilisis.
                </p>

                <div class='client-info'>
                  <h4>Jason Jisan</h4>
                  <span>Founder at Brand</span>
                </div>
              </div>

              <div className='single-testimonials'>
                <div className='client-image'>
                  <img
                    src={
                      'https://novine-react.envytheme.com/_next/static/images/news-img2-c1c57d2f2f581d2d30da87251bf0e0c6.jpg'
                    }
                    alt='image'
                  />
                </div>

                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                  amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Quis ipsum
                  suspendisse ultrices gravida. Risus commodo viverra maecenas
                  accumsan lacus vel facilisis.
                </p>

                <div className='client-info'>
                  <h4>Jason Shabbir</h4>
                  <span>Founder at Brand</span>
                </div>
              </div>*/}
          </OwlCarousel>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default TestimonialsTwo;
