import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import CartProduct from './CartProduct';
import { ToastContainer, toast, Slide } from 'react-toastify';
import * as ROUTINGS from '../../routings/path';
import { useMutation, useLazyQuery } from '@apollo/client';
import { toastAlert } from '../../utils/toastify';
import {
  saveCartTokenAction,
  saveCartInfoAction,
} from '../../flows/checkout/action';
import {
  UPDATE_CART_PRODUCT_GQL,
  GET_CART_INFO_GQL,
  REMOVE_FROM_CART,
} from '../../graphql/checkout.gql';

const mapStateToProps = (state) => ({
  checkoutState: state.checkout,
});

const mapDispatchToProps = (dispatch) => ({
  saveCartInfoAction: (data: any) => dispatch(saveCartInfoAction(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: any) => {
  const {
    checkoutState: { cartId, cartInfo },
    saveCartInfoAction,
  } = props;
  const subTotal =
    (cartInfo &&
      cartInfo.prices &&
      cartInfo.prices.subtotal_including_tax &&
      cartInfo.prices.subtotal_including_tax.value) ||
    0;

  const total =
    (cartInfo &&
      cartInfo.prices &&
      cartInfo.prices.grand_total &&
      cartInfo.prices.grand_total.value) ||
    0;

  const selectedShippingMethod =
    (!!cartInfo &&
      !!cartInfo.shipping_addresses &&
      !!cartInfo.shipping_addresses[0] &&
      cartInfo.shipping_addresses[0].selected_shipping_method) ||
    null;

  const shippingPrice =
    (selectedShippingMethod &&
      selectedShippingMethod.amount &&
      selectedShippingMethod.amount.value) ||
    0;

  const discountPrice = Math.abs(
    parseInt(
      (cartInfo &&
        cartInfo.prices &&
        cartInfo.prices.discount &&
        cartInfo.prices.discount.amount &&
        cartInfo.prices.discount.amount.value) ||
        0
    )
  );
  const discountPriceDisplay = !!discountPrice ? `-$${discountPrice}` : '$0';

  const handleUpdateCartItem = ({ cartItemUid, quantity }) => {
    updateCartProduct({
      variables: {
        cartId: cartId,
        cartItemId: cartItemUid,
        quantity: quantity,
      },
    });
  };

  const [updateCartProduct, { loading: isUpdateCartLoading }]: any =
    useMutation(UPDATE_CART_PRODUCT_GQL, {
      onCompleted: ({ updateCartItems }) => {
        !!updateCartItems &&
          !!updateCartItems.cart &&
          saveCartInfoAction(updateCartItems.cart);
      },
      onError: ({ message }) => {
          toastAlert({ type: 'error', message });
          getCartInfo({ variables: { cartId } });
      },
    });

  const handleDeleteCartItem = ({ cartItemUid }) => {
    deleteCartProduct({
      variables: {
        cartId: cartId,
        cartItemId: cartItemUid,
      },
    });
  };

  const [deleteCartProduct, { loading: isDeleteCartLoading, data: cartData }]: any =
    useMutation(REMOVE_FROM_CART, {
      onCompleted: ({ removeItemFromCart }) => {
        !!removeItemFromCart &&
          !!removeItemFromCart.cart &&
          saveCartInfoAction(removeItemFromCart.cart);
        getCartInfo({ variables: { cartId } });
      },
      onError: ({ message }) => {
        getCartInfo({ variables: { cartId } });
        toastAlert({ type: 'error', message })
      },
    });

    const [getCartInfo, { loading, data: cartDataInfo }]: any = useLazyQuery(GET_CART_INFO_GQL, {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      onCompleted: ({ cart }) => {
        saveCartInfoAction(cart);
      },
      onError: ({ message }: any) => {
        if(!!cartDataInfo) {
          const { cart } = cartDataInfo;
          if(!!saveCartInfoAction(cart)) {
            saveCartInfoAction(cart);
          }
        }
      },
    });

    useEffect(() => {
      getCartInfo({ variables: { cartId } });
    }, []);

  const isEmpty = (!!cartInfo && !cartInfo.items) || !cartInfo.items.length;

  return (
    <section className='cart-area ptb-60'>
      <ToastContainer transition={Slide} />

      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12'>
            <form>
              <div className='cart-table table-responsive'>
                <CartProduct
                  isUpdateCartLoading={isUpdateCartLoading}
                  isDeleteCartLoading={isDeleteCartLoading}
                  cartInfo={cartInfo}
                  onUpdate={handleUpdateCartItem}
                  onDelete={handleDeleteCartItem}
                />
              </div>

              <div className='cart-buttons'>
                <div className='row align-items-center'>
                  <div className='col-lg-7 col-md-7'>
                    <div className='continue-shopping-box'>
                      <NavLink to={ROUTINGS.ROUTING_HOME}>
                        <a className='btn btn-light'>Continue Shopping</a>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              {!isEmpty && (
                <div className='cart-totals'>
                  <h3>Cart Totals</h3>

                  <ul>
                    <li>
                      Subtotal Including Tax <span>${subTotal}</span>
                    </li>
                    <li>
                      Shipping <span>${shippingPrice}</span>
                    </li>
                    {!!discountPrice && (
                      <li>
                        Discount <span>{discountPriceDisplay}</span>
                      </li>
                    )}

                    <li>
                      Total{' '}
                      <span>
                        <b>${total}</b>
                      </span>
                    </li>
                  </ul>

                  <NavLink to={ROUTINGS.ROUTING_CHECKOUT}>
                    <a className='btn btn-light'>Proceed to Checkout</a>
                  </NavLink>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
});
