import { gql, useMutation } from '@apollo/client';

export const SIGN_IN_GQL = gql`
  mutation GenerateCustomerToken($email: String!, $password: String!) {
    generateCustomerToken(email: $email, password: $password) {
      token
      expired_after_hours
    }
  }
`;

export const SIGN_UP_GQL = gql`
  mutation CreateCustomer($input: CustomerInput!) {
    createCustomer(input: $input) {
      customer {
        firstname
        lastname
        email
        phone
        date_of_birth
        is_subscribed
      }
    }
  }
`;

export const GET_CUSTOMER_DATA_GQL = gql`
  query {
    customer {
      firstname
      lastname
      suffix
      email
      phone
      id
      is_subscribed
      date_of_birth
    }
  }
`;

export const SOCIAL_SIGN_IN_GQL = gql`
  query GenerateSocialCustomerToken($identifier: String!, $type: String!) {
    generateSocialCustomerToken(identifier: $identifier, type: $type) {
      token
      expired_after_hours
    }
  }
`;

export const FORGOT_PASSWORD_GQL = gql`
  mutation RequestPasswordResetEmail($email: String!) {
    requestPasswordResetEmail(email: $email)
  }
`;

export const RESET_FORGOT_PASSWORD_GQL = gql`
  mutation ResetPassword(
    $email: String!
    $resetPasswordToken: String!
    $newPassword: String!
  ) {
    resetPassword(
      email: $email
      resetPasswordToken: $resetPasswordToken
      newPassword: $newPassword
    )
  }
`;

export const UPDATE_CUSTOMER_INFO_GQL = gql`
  mutation UpdateCustomerV2(
    $firstname: String
    $lastname: String
    $date_of_birth: String
    $is_subscribed: Boolean
  ) {
    updateCustomerV2(
      input: {
        firstname: $firstname
        lastname: $lastname
        date_of_birth: $date_of_birth
        is_subscribed: $is_subscribed
      }
    ) {
      customer {
        firstname
        lastname
        date_of_birth
        is_subscribed
      }
    }
  }
`;

export const CHANGE_PASSWORD_GQL = gql`
  mutation ChangeCustomerPassword(
    $currentPassword: String!
    $newPassword: String!
  ) {
    changeCustomerPassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      email
    }
  }
`;

export const GET_WISH_LIST = gql`
  query getWishList {
    customer {
      wishlist {
        id
        items_count
        sharing_code
        updated_at
        items_v2(currentPage: 1, pageSize: 200) {
          page_info {
            page_size
            current_page
          }
          items {
            id
            added_at
            product {
              url_key
              sku
              name
              __typename
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
              thumbnail {
                url
                label
              }
              ... on ConfigurableProduct {
                variants {
                  product {
                    name
                    sku
                    price_range {
                      minimum_price {
                        regular_price {
                          value
                          currency
                        }
                        final_price {
                          value
                          currency
                        }
                      }
                    }
                    thumbnail {
                      url
                      label
                    }
                  }
                  attributes {
                    uid
                    label
                    code
                    value_index
                  }
                }
              }
            }
            ... on ConfigurableWishlistItem {
              child_sku
            }
          }
        }
      }
    }
  }
`;

export const ADD_PRODUCT_TO_WISH_LIST = gql`
  mutation addProductsToWishlist($wishlistId: ID!, $sku: String!) {
    addProductsToWishlist(
      wishlistId: $wishlistId
      wishlistItems: [{ sku: $sku, quantity: 1 }]
    ) {
      wishlist {
        id
        items_count
        items_v2(currentPage: 1, pageSize: 8) {
          items {
            id
            quantity
            product {
              uid
              name
              sku
              price_range {
                minimum_price {
                  regular_price {
                    currency
                    value
                  }
                  final_price {
                    value
                    currency
                  }
                }
                maximum_price {
                  regular_price {
                    currency
                    value
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
        }
      }
      user_errors {
        code
        message
      }
    }
  }
`;

export const ADD_CONFIGURABLE_PRODUCT_TO_WISH_LIST = gql`
  mutation addProductsToWishlist(
    $parentSku: String
    $wishlistId: ID!
    $sku: String!
  ) {
    addProductsToWishlist(
      wishlistId: $wishlistId
      wishlistItems: [{ parent_sku: $parentSku, sku: $sku, quantity: 1 }]
    ) {
      wishlist {
        id
        items_count
        items_v2(currentPage: 1, pageSize: 8) {
          items {
            id
            quantity
            product {
              uid
              name
              sku
              price_range {
                minimum_price {
                  regular_price {
                    currency
                    value
                  }
                  final_price {
                    value
                    currency
                  }
                }
                maximum_price {
                  regular_price {
                    currency
                    value
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
        }
      }
      user_errors {
        code
        message
      }
    }
  }
`;

export const REMOVE_PRODUCT_FROM_WISH_LIST = gql`
  mutation removeProductsFromWishlist($wishlistId: ID!, $id: ID!) {
    removeProductsFromWishlist(
      wishlistId: $wishlistId
      wishlistItemsIds: [$id]
    ) {
      wishlist {
        id
        items_count
        items_v2(currentPage: 1, pageSize: 200) {
          page_info {
            page_size
            current_page
          }
          items {
            id
            added_at
            product {
              url_key
              sku
              name
              __typename
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
              thumbnail {
                url
                label
              }
              ... on ConfigurableProduct {
                variants {
                  product {
                    name
                    sku
                    price_range {
                      minimum_price {
                        regular_price {
                          value
                          currency
                        }
                        final_price {
                          value
                          currency
                        }
                      }
                    }
                    thumbnail {
                      url
                      label
                    }
                  }
                  attributes {
                    uid
                    label
                    code
                    value_index
                  }
                }
              }
            }
            ... on ConfigurableWishlistItem {
              child_sku
            }
          }
        }
      }
      user_errors {
        code
        message
      }
    }
  }
`;

export const GET_COMPARE_LIST = gql`
  query GetCompareList($compareListUUID: ID!) {
    compareList(uid: $compareListUUID) {
      uid
      item_count
      items {
        product {
          id
          name
          url_key
          url_path
          sku
          review_count
          rating_summary
          small_image {
            url
          }
          price_range {
            minimum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
            }
          }
        }
        attributes {
          code
          value
        }
      }
      attributes {
        code
        label
      }
    }
  }
`;

export const ADD_PRODUCT_TO_COMPARE_LIST = gql`
  mutation AddProductsToCompareList($compareListUUID: ID!, $productId: ID!) {
    addProductsToCompareList(
      input: {
        uid: $compareListUUID
        products: [$productId]
        trigger_report_event: true
      }
    ) {
      item_count
      items {
        product {
          url_key
          url_path
        }
        attributes {
          code
          value
        }
      }
      attributes {
        code
        label
      }
    }
  }
`;

export const REMOVE_PRODUCT_FROM_COMPARE_LIST = gql`
  mutation RemoveProductsFromCompareList(
    $compareListUUID: ID!
    $productId: ID!
  ) {
    removeProductsFromCompareList(
      input: { uid: $compareListUUID, products: [$productId] }
    ) {
      item_count
      items {
        product {
          url_key
        }
      }
    }
  }
`;

export const CREATE_COMPARE_LIST = gql`
  mutation {
    createCompareList(input: { products: [] }) {
      uid
    }
  }
`;

export const ASSIGN_COMPARE_LIST_TO_CUSTOMER = gql`
  mutation AssignCompareListToCustomer($compareListUUID: ID!) {
    assignCompareListToCustomer(uid: $compareListUUID) {
      result
      compare_list {
        uid
      }
    }
  }
`;

export const GET_LIST_ADDRESS_GQL = gql`
  query getListAddress {
    customer {
      addresses {
        id
        default_billing
        default_shipping
        firstname
        lastname
        street
        company
        city
        region {
          region_code
          region
        }
        postcode
        country_code
        telephone
      }
    }
  }
`;

export const CREATE_CUSTOMER_ADDRESS_SQL = gql`
  mutation createCustomerAddress(
    $countryCode: CountryCodeEnum
    $firstname: String!
    $lastname: String!
    $company: String!
    $street: [String]!
    $city: String!
    $region: String!
    $postcode: String!
    $phonenumber: String!
  ) {
    createCustomerAddress(
      input: {
        region: { region: $region }
        country_code: $countryCode
        street: $street
        telephone: $phonenumber
        postcode: $postcode
        city: $city
        company: $company
        firstname: $firstname
        lastname: $lastname
        default_shipping: false
        default_billing: false
      }
    ) {
      id
      firstname
      lastname
      region {
        region
        region_code
      }
      country_code
      street
      telephone
      postcode
      city
      company
      default_shipping
      default_billing
    }
  }
`;

export const UPDATE_CUSTOMER_ADDRESS_SQL = gql`
  mutation updateCustomerAddress(
    $id: Int!
    $countryCode: CountryCodeEnum
    $firstname: String!
    $lastname: String!
    $company: String!
    $street: [String]!
    $city: String!
    $region: String!
    $postcode: String!
    $phonenumber: String!
  ) {
    updateCustomerAddress(
      id: $id
      input: {
        region: { 
          region: $region
        }
        country_code: $countryCode
        street: $street
        telephone: $phonenumber
        postcode: $postcode
        city: $city
        company: $company
        firstname: $firstname
        lastname: $lastname
        default_shipping: true
        default_billing: true
      }
    ) {
      id
      region {
        region
        region_code
      }
      country_code
      street
      telephone
      postcode
      city
      company
      default_shipping
      default_billing
    }
  }
`;

export const DELETE_CUSTOMER_ADDRESS_SQL = gql`
  mutation deleteCustomerAddress($id: Int!) {
    deleteCustomerAddress(id: $id)
  }
`;

export const GET_AVAILABLE_COUNTRIES = gql`
  query countries{
    countries {
      id
      full_name_english
      full_name_locale
      available_regions {
        id
        name
      }
    }
  }
`;

export const QUERY_GET_AVAILABLE_COUNTRIES = `
  query countries{
    countries {
      id
      full_name_english
      full_name_locale
      available_regions {
        id
        name
      }
    }
  }
`;
