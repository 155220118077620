import { NavLink } from 'react-router-dom';
import { toastAlert } from '../../utils/toastify';
import { useLazyQuery, useMutation } from '@apollo/client';

import {
  GET_COMPARE_LIST,
  REMOVE_PRODUCT_FROM_COMPARE_LIST,
} from '../../graphql/customer.gql';

const RemoveCompare = (props) => {
  const { id, compareListUUID, saveCompareList } = props;

  const handleRemove = () => {
    removeProductFromComparelist({
      variables: {
        compareListUUID,
        productId: id,
      },
    });
  };

  const [
    removeProductFromComparelist,
    { loading: isRemoveComparelistLoading },
  ]: any = useMutation(REMOVE_PRODUCT_FROM_COMPARE_LIST, {
    onCompleted: ({}) => {
      toastAlert({
        type: 'success',
        message: 'Removing product from compare list success',
      });
      !!compareListUUID &&
        !!compareListUUID.length &&
        getCompareList({ variables: { compareListUUID } });
    },
    onError: ({ message }) => toastAlert({ type: 'error', message }),
  });

  const [getCompareList]: any = useLazyQuery(GET_COMPARE_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: ({ compareList }) => {
      saveCompareList({ compareData: compareList });
    },
  });

  return (
    <div className='remove-btn'>
      <NavLink
        to='#'
        className='remove'
        onClick={(e) => {
          e.preventDefault();
          handleRemove();
        }}
      >
        {!!isRemoveComparelistLoading ? (
          <div
            style={{ background: '#aaa', width: 20, height: 20 }}
            className='spinner-grow spinner-grow-sm'
          />
        ) : (
          <i className='far fa-trash-alt'></i>
        )}
      </NavLink>
    </div>
  );
};

export default RemoveCompare;
