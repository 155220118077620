import { connect } from "react-redux";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { checkEmptryString } from "../../../utils/validation";
import { toastAlert } from "../../../utils/toastify";
import * as ROUTINGS from "../../../routings/path";
import { SIGN_IN_GQL } from "../../../graphql/customer.gql";
import {
  FB_SIGN_IN_URL,
  GOOGLE_SIGN_IN_URI,
} from "../../../constants/social-sign-in";

import { mapStateToProps, mapDispatchToProps } from "./state";
import Facility from "../../../components/Common/Facility";
import Meta from "../../../components/meta";

const Heading = () => (
  <div className="section-title">
    <h2>
      <span className="dot"></span> Login
    </h2>
  </div>
);

const MainForm = ({ onSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [submitSignIn, { loading: isSubmitLoading }]: any = useMutation(
    SIGN_IN_GQL,
    {
      onCompleted: ({ generateCustomerToken }) =>
        onSuccess(generateCustomerToken),
      onError: ({ message }) => toastAlert({ type: "error", message }),
    }
  );

  const handleChangeEmail = (e) => setEmail(e.target.value);
  const handleChangePassword = (e) => setPassword(e.target.value);

  const validateForm = () => {
    if (checkEmptryString(password)) {
      toastAlert({ type: "error", message: "Error: Password is invalid." });
      return false;
    }

    return true;
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (!validateForm()) return false;

    submitSignIn({ variables: { email, password } });
  };

  return (
    <form className="login-form" onSubmit={handleSubmitForm}>
      <div className="form-group">
        <label>
          Email <span className="required">*</span>
        </label>
        <input
          value={email}
          onChange={handleChangeEmail}
          type="email"
          required
          className="form-control"
          placeholder="Enter your email"
          id="email"
          name="email"
        />
      </div>

      <div className="form-group">
        <label>
          Password <span className="required">*</span>
        </label>
        <input
          value={password}
          required
          onChange={handleChangePassword}
          type="password"
          className="form-control"
          placeholder="Enter your password"
          id="password"
          name="password"
        />
      </div>

      <button
        type="submit"
        disabled={isSubmitLoading}
        className="btn btn-primary"
      >
        {!isSubmitLoading ? (
          "Login"
        ) : (
          <span className="spinner-grow spinner-grow-sm" />
        )}
      </button>

      <NavLink
        to={ROUTINGS.ROUTING_AUTH_FORGOT_PASSWORD}
        className="forgot-password"
      >
        Lost your password?
      </NavLink>
    </form>
  );
};

const SocialSignIn = ({
  sociallogin_facebook_is_enabled,
  sociallogin_facebook_app_id,
  sociallogin_google_is_enabled
}) => {
  return (
    <div>
      <div className="login-form pt-5 pb-5">
        {
          !!sociallogin_facebook_is_enabled &&
        <a href={FB_SIGN_IN_URL(sociallogin_facebook_app_id)} className="btn btn-light mb-2">
          <i className="fab fa-facebook mx-3"></i>
          Connect via Facebook
        </a>
        }
        {
          sociallogin_google_is_enabled &&
          <a href={GOOGLE_SIGN_IN_URI} className="btn btn-light">
            <i className="fab fa-google mx-3"></i>
            Connect via Gmail
          </a>
        }
      </div>
    </div>
  );
};

const ExtraContent = () => (
  <div className="col-lg-6 col-md-12">
    <div className="new-customer-content">
      <div className="section-title">
        <h2>
          <span className="dot"></span> New Customer
        </h2>
      </div>

      <span>Create a Account</span>
      <p>
        Sign up for a free account at our store. Registration is quick and easy.
        It allows you to be able to order from our shop. To start shopping click
        register.
      </p>
      <NavLink to={ROUTINGS.ROUTING_AUTH_SIGN_UP} className="btn btn-light">
        Create A Account
      </NavLink>
    </div>
  </div>
);

interface IProps {
  authState: any;
  appState: any;
  saveAuthInfoAction: Function;
  clearCartAction: Function;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: IProps) => {
  const { authState, saveAuthInfoAction, clearCartAction, appState } = props;
  const {
    sociallogin_facebook_is_enabled,
    sociallogin_facebook_app_id,
    sociallogin_google_is_enabled
  } = appState;

    const [meta, setMeta] = useState({
        title: "Login page",
        description: "",
        keyword: ""
    });

  return (
    <>
    <Meta
        title={meta.title}
        description={meta.description}
        keywords={meta.keyword}
    />
      <section className="login-area ptb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="login-content">
                <Heading />
                <MainForm
                  onSuccess={(data) => {
                    clearCartAction();
                    saveAuthInfoAction(data);
                  }}
                />
                <SocialSignIn {...{
                  sociallogin_facebook_is_enabled,
                  sociallogin_facebook_app_id,
                  sociallogin_google_is_enabled
                  }} />
              </div>
            </div>

            <ExtraContent />
          </div>
        </div>
      </section>
      <Facility />
    </>
  );
});
