import {
  saveCartTokenAction,
  saveCartInfoAction,
  clearCartAction,
  saveCustomerAddressAction,
  updateSelectedPaymentMethodAction,
} from '../../flows/checkout/action';

export const mapStateToProps = (state) => ({
  checkoutState: state.checkout,
  authState: state.auth,
});

export const mapDispatchToProps = (dispatch) => ({
  clearCartAction: () => dispatch(clearCartAction()),
  saveCartInfoAction: (data: any) => dispatch(saveCartInfoAction(data)),
  saveCartTokenAction: (data: any) => dispatch(saveCartTokenAction(data)),
  updateSelectedPaymentMethodAction: (data: any) => dispatch(updateSelectedPaymentMethodAction(data)),
  saveCustomerAddressAction: (data: any) => dispatch(saveCustomerAddressAction(data)),
});
