import { withRouter } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { getUrlParameter } from '../../../utils/uri';
import * as ROUTINGS from '../../../routings/path';
import { toastAlert } from '../../../utils/toastify';
import { RESET_FORGOT_PASSWORD_GQL } from '../../../graphql/customer.gql';
import Meta from "../../../components/meta";

interface IProps {
  location: any;
  history: any;
}

export default withRouter((props: IProps) => {
  const { history } = props;
  const [resetPasswordToken, setResetPasswordToken] = useState('');

  const [email, setEmail] = useState('');
  const handleChangeEmail = (e) => setEmail(e.target.value);

  const [password, setPassword] = useState('');
  const handleChangePassword = (e) => setPassword(e.target.value);

  const [rePassword, setRePassword] = useState('');
  const handleChangeRePassword = (e) => setRePassword(e.target.value);

  useEffect(() => {
    const { location } = props;
    const accessToken = getUrlParameter(location.search, 'token') || '';
    setResetPasswordToken(accessToken);
  }, []);

  const [submitForgotPassword, { loading: isSubmitLoading }]: any = useMutation(
    RESET_FORGOT_PASSWORD_GQL,
    {
      onCompleted: () => {
        toastAlert({
          type: 'success',
          message: 'Change password success, please login again',
        });
        history.push(ROUTINGS.ROUTING_AUTH_SIGN_IN);
      },
      onError: ({ message }) => toastAlert({ type: 'error', message }),
    }
  );

  const handleSubmitForm = (e) => {
    e.preventDefault();

    if (password !== rePassword) {
      toastAlert({
        type: 'error',
        message: 'Password and re-pass are different. Please input again',
      });
      return;
    }

    submitForgotPassword({
      variables: {
        email,
        resetPasswordToken,
        newPassword: password,
      },
    });
  };

  const [meta, setMeta] = useState({
    title: "Reset forgot password",
    description: "",
    keyword: ""
  });

  return (
    <>
      <Meta
          title={meta.title}
          description={meta.description}
          keywords={meta.keyword}
      />
      <section className='signup-area ptb-60'>
        <div className='container'>
          <div className='signup-content'>
            <div className='section-title'>
              <h2>
                <span className='dot'></span> Create new password
              </h2>
            </div>
            <form className='signup-form' onSubmit={handleSubmitForm}>
              <div className='form-group'>
                <label>Email *</label>
                <input
                  value={email}
                  onChange={handleChangeEmail}
                  type='email'
                  className='form-control'
                  placeholder='Please input your email'
                  id='name'
                  name='name'
                />
              </div>
              <div className='form-group'>
                <label>Password *</label>
                <input
                  value={password}
                  onChange={handleChangePassword}
                  type='password'
                  required
                  className='form-control'
                  placeholder='Please input your password'
                  id='name'
                  name='name'
                />
              </div>
              <div className='form-group'>
                <label>Re-enter password *</label>
                <input
                  value={rePassword}
                  onChange={handleChangeRePassword}
                  type='password'
                  required
                  className='form-control'
                  placeholder='Please re input your pasword'
                  id='name'
                  name='name'
                />
              </div>
              <button
                type='submit'
                disabled={isSubmitLoading}
                className='btn btn-primary'
              >
                {!isSubmitLoading ? (
                  'Submit'
                ) : (
                  <span className='spinner-grow spinner-grow-sm' />
                )}
              </button>
              <NavLink to={ROUTINGS.ROUTING_AUTH_SIGN_IN}>
                <a className='return-store'>or Back to Sign in</a>
              </NavLink>
            </form>
          </div>
        </div>
      </section>
    </>
  );
});
