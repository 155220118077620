import { gql, useMutation } from '@apollo/client';

export const GET_STORE_GQL = `
  query availableStores {
    availableStores {
      store_code
      store_name
    }
  }
`;

export const GET_STORE_CONFIG_GQL = gql`
  query storeConfig {
    storeConfig {
      sociallogin_facebook_is_enabled
      sociallogin_facebook_app_id
      sociallogin_google_is_enabled
    }
  }
`;

export const QUERY_GET_STORE_CONFIG_GQL = `
  query storeConfig {
    storeConfig {
      sociallogin_facebook_is_enabled
      sociallogin_facebook_app_id
      sociallogin_google_is_enabled
    }
  }
`;
