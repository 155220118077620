import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const generateSelectedFilter = ({ filter, combineFilter }) => {
  return combineFilter.map((combine) => {
    let label = '';
    let keyLabel = '';

    filter.map((item) => {
      if (item.attribute_code === combine.key) {
        item.options &&
          item.options.map((option) => {
            if (option.value === combine.value) {
              label = option.label;
              keyLabel = capitalizeFirstLetter(item.label);
            }
          });
      }
    });

    return {
      ...combine,
      label,
      keyLabel,
    };
  });
};

const checkOpen = ({ id, toggle }) => toggle.indexOf(id) >= 0;

const TogglePanel = ({ title, children, toggle, id, handleToggle }) => {
  const isOpen = checkOpen({ toggle, id });

  return (
    <div
      className={`collapse-widget filter-list-widget ${isOpen ? '' : 'open'}`}
    >
      <h3
        className={`collapse-widget-title ${isOpen ? '' : 'active'}`}
        onClick={(e) => {
          e.preventDefault();
          handleToggle(id, !isOpen);
        }}
      >
        {title}
        <i className='fas fa-angle-up'></i>
      </h3>

      {!!isOpen && children}
    </div>
  );
};

const LeftFilter = ({
  filter,
  onClick,
  onSelectFilter,
  combineFilter,
  onRemoveFilter,
}) => {
  const [toggle, setToggle] = useState([-1]);

  const closeLeftFilter = () => {
    onClick(false);
  };

  const handleToggle = (id, status) => {
    const newToggle = toggle.filter((item) => item !== id);
    setToggle([...newToggle, !!status && id]);
  };

  const selectedFilter = generateSelectedFilter({ filter, combineFilter });

  return (
    <div
      className='modal left fade productsFilterModal show'
      style={{
        display: 'block',
        paddingRight: '16px',
      }}
    >
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={closeLeftFilter}
          >
            <span aria-hidden='true'>
              <i className='fas fa-times'></i> Close
            </span>
          </button>

          <div className='modal-body'>
            <div className='woocommerce-sidebar-area'>
              {!!selectedFilter && !!selectedFilter.length && (
                <TogglePanel
                  handleToggle={handleToggle}
                  title={'Current Selection'}
                  toggle={toggle}
                  id={-1}
                >
                  <div className={`selected-filters-wrap-list`}>
                    <ul>
                      {selectedFilter.map((item) => {
                        if (
                          !item.keyLabel ||
                          !item.label ||
                          !item.keyLabel.length ||
                          !item.label.length
                        )
                          return null;
                        return (
                          <li>
                            <NavLink
                              onClick={(e) => {
                                e.preventDefault();
                                onRemoveFilter([item]);
                              }}
                              to='#'
                            >{`${item.keyLabel} : ${item.label}`}</NavLink>
                          </li>
                        );
                      })}
                    </ul>

                    <div className='delete-selected-filters'>
                      <NavLink
                        to='#'
                        onClick={(e) => {
                          e.preventDefault();

                          onRemoveFilter(selectedFilter);
                        }}
                      >
                        <a>
                          <i className='far fa-trash-alt'></i>{' '}
                          <span>Clear All</span>
                        </a>
                      </NavLink>
                    </div>
                  </div>
                </TogglePanel>
              )}
              {filter.map((item, index) => {
                switch (item.attribute_code) {
                  case 'price':
                    return (
                      <TogglePanel
                        handleToggle={handleToggle}
                        toggle={toggle}
                        title={'price'}
                        id={index}
                      >
                        <ul className={`price-list-row `}>
                          {item.options &&
                            item.options.map((option) => {
                              const value = option.value.split('_');
                              if (!value) return null;

                              return (
                                <li>
                                  <NavLink
                                    to='#'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      onSelectFilter(
                                        item.attribute_code,
                                        option.value
                                      );
                                    }}
                                  >
                                    <a>{`$${value[0]} - $${value[1]}`}</a>
                                  </NavLink>
                                </li>
                              );
                            })}
                        </ul>
                      </TogglePanel>
                    );

                  case 'color':
                    return (
                      <TogglePanel
                        handleToggle={handleToggle}
                        toggle={toggle}
                        title={'Color'}
                        id={index}
                      >
                        <ul className={`color-list-row`}>
                          {item.options &&
                            item.options.map((option) => (
                              <li>
                                <NavLink
                                  to='#'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onSelectFilter(
                                      item.attribute_code,
                                      option.value
                                    );
                                  }}
                                  className={`color-${
                                    option.label && option.label.toLowerCase()
                                  }`}
                                ></NavLink>
                              </li>
                            ))}
                        </ul>
                      </TogglePanel>
                    );

                  default:
                    return (
                      <TogglePanel
                        handleToggle={handleToggle}
                        toggle={toggle}
                        title={item.label}
                        id={index}
                      >
                        <ul className={`collections-list-row `}>
                          {item.options &&
                            item.options.map((option) => (
                              <li>
                                <NavLink
                                  to='#'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onSelectFilter(
                                      item.attribute_code,
                                      option.value
                                    );
                                  }}
                                >
                                  <a>{option.label}</a>
                                </NavLink>
                              </li>
                            ))}
                        </ul>
                      </TogglePanel>
                    );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftFilter;
