import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel3';

const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    animateOut: 'slideOutDown',
    animateIn: 'flipInX',
    items: 1,
    navText: [
        "<i class='fas fa-chevron-left'></i>",
        "<i class='fas fa-chevron-right'></i>"
    ]
}

const TopPanel = () => {
    const [display, setDisplay] = useState(false);
    const [panel, setPanel] = useState(true);

    useEffect(() => {
        setDisplay(true);

    }, []);
    
    return (
        <div className={`top-panel ${panel ? '' : 'hide'}`}>
            <div className="container">
                <div className="panel-content">
                    {
                        !!display
                            ? (
                                <OwlCarousel
                                    className="top-panel-slides owl-carousel owl-theme"
                                    {...options}
                                >
                                    <div className="single-item-box">
                                        <p><strong>Enjoy an extra 20% off</strong> select sales styles <NavLink to="#"><a>Read More</a></NavLink></p>
                                    </div>

                                    <div className="single-item-box">
                                        <p><strong>Enjoy an extra 20% off</strong> select sales styles <NavLink to="#"><a>Read More</a></NavLink></p>
                                    </div>

                                    <div className="single-item-box">
                                        <p><strong>Enjoy an extra 20% off</strong> select sales styles <NavLink to="#"><a>Read More</a></NavLink></p>
                                    </div>
                                </OwlCarousel>)
                            : ''}

                    <i
                        onClick={() => setPanel(false)}
                        className="fas fa-times panel-close-btn"
                    ></i>
                </div>
            </div>
        </div>
    );
}

export default TopPanel;
