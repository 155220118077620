import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import * as ROUTINGS from '../../../routings/path';
import { toastAlert } from '../../../utils/toastify';
import { FORGOT_PASSWORD_GQL } from '../../../graphql/customer.gql';
import Meta from "../../../components/meta";

export default withRouter(({history}) => {
  const [email, setEmail] = useState('');
  const handleChangeEmail = (e) => setEmail(e.target.value);

  const [submitForgotPassword, { loading: isSubmitLoading }]: any = useMutation(
    FORGOT_PASSWORD_GQL,
    {
      onCompleted: () =>{
        toastAlert({
          type: 'success',
          message: 'Request success, please check your email',
        });
        history.push(ROUTINGS.ROUTING_AUTH_SIGN_IN)
      },
      onError: ({ message }) => toastAlert({ type: 'error', message }),
    }
  );

  const handleSubmitForm = (e) => {
    submitForgotPassword({ variables: { email } });
    e.preventDefault();
  };

  const [meta, setMeta] = useState({
    title: "Forgot password",
    description: "",
    keyword: ""
  });

  return (
    <>
      <Meta
          title={meta.title}
          description={meta.description}
          keywords={meta.keyword}
      />
      <section className='signup-area ptb-60'>
        <div className='container'>
          <div className='signup-content'>
            <div className='section-title'>
              <h2>
                <span className='dot'></span> Forgot password
              </h2>
            </div>
            <form className='signup-form' onSubmit={handleSubmitForm}>
              <div className='form-group'>
                <label>Email</label>
                <input
                  value={email}
                  onChange={handleChangeEmail}
                  type='email'
                  required
                  className='form-control'
                  placeholder='Please input your email'
                  id='name'
                  name='name'
                />
              </div>
              <button
                type='submit'
                disabled={isSubmitLoading}
                className='btn btn-primary'
              >
                {!isSubmitLoading ? (
                  'Submit'
                ) : (
                  <span className='spinner-grow spinner-grow-sm' />
                )}
              </button>
              <NavLink to={ROUTINGS.ROUTING_AUTH_SIGN_IN}>
                <a className='return-store'>or Back to Sign in</a>
              </NavLink>
            </form>
          </div>
        </div>
      </section>
    </>
  );
});
