import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  transformProductDetail,
} from '../../utils/product.transform';
import { RatingValue } from '../../utils/rating';
import * as ROUTINGS from '../../routings/path';
import {
  ADD_PRODUCT_VIEW_COUNT_GQL,
  QUERY_GET_PRODUCT_DETAIL_BY_SLUG
} from '../../graphql/catelog.gql';
import {
  ADD_SIMPLE_PRODUCT_TO_CART_GQL,
  ADD_CONFIGRURABLE_PRODUCT_TO_CART_GQL,
  GET_CART_INFO_GQL,
} from '../../graphql/checkout.gql';
import { mapStateToProps, mapDispatchToProps } from './QuickView.state';
import { toastAlert } from '../../utils/toastify';
import callGetApi, {toAstError} from "../../api";
import { useHistory } from "react-router-dom";

const QuickView = (props) => {
  const {
    closeModal,
    modalData,
    checkoutState: { cartId },
    saveCartInfoAction,
  } = props;

  const [qty, setQty]: any = useState(1);
  const DecreaseItem = () => qty > 1 && setQty(qty * 1 - 1);
  const IncrementItem = () => setQty(qty * 1 + 1);

  const [product, setProduct] = useState(null);
  const [productImage, setProductImage] = useState(null);
  const [price, setPrice] = useState((product && product.price) || 0);
  const [oldPrice, setOldPrice] = useState((product && product.oldPrice) || 0);
  const [isSubmitLoading, setIsSubmitLoading] = useState(true);

  const initSelectedVariants =
      product?.configurableOptions &&
      product?.configurableOptions.map((item) => {
        return {
          key: item.attribute_code,
          uid: item.uid,
          value: null,
          is_selected: false
        };
      });
  const [selectedVariants, setSelectedVariants] =
      useState(initSelectedVariants);

  const initAvailableVariantProducts =
      product?.variants &&
      product?.variants.map((item) => {
        let array = [];
        item?.attributes.map((attribute) => {
          array.push(attribute.uid);
        });
        return array;
      });

  const [availableVariantProducts, setAvailableVariantProducts] =
      useState(null);

  const upAvailableProducts = () => {
    if(!availableVariantProducts) return false;

    selectedVariants.map((item) => {
      if (item?.value) {
        initAvailableVariantProducts.map((initAvailableVariantProduct, idx) => {
          if (!initAvailableVariantProduct.includes(item.value.uid)) {
            delete initAvailableVariantProducts[idx];
          }
        });
      }
    });

    let newAvailableVariantProducts = initAvailableVariantProducts.filter(function (el) {
      return el != null;
    });

    setAvailableVariantProducts(newAvailableVariantProducts);
  };

  const handleSelectVariant = (key, value) => {
    const newSelectedVariants = selectedVariants.map((item) => {
      if (item.key !== key) return item;

      if (item.key === key && item.is_selected && item.value.value_index === value.value_index) {
        return {
          key: item.key,
          uid: item.uid,
          value: null,
          is_selected: false
        }
      }

      return {
        key: item.key,
        uid: item.uid,
        value,
        is_selected: true
      };
    });

    setSelectedVariants(newSelectedVariants);
  };

  const queryGetProductDetail: any = (url_key) => {
    let final_query = QUERY_GET_PRODUCT_DETAIL_BY_SLUG.replaceAll("{{$param}}", "\"" + url_key + "\"");

    callGetApi(final_query).then((res: any) => {
      let products =
          !!res.data &&
          !!res.data.data &&
          !!res.data.data.products && res.data.data.products;

      if (
          !!products &&
          !!products.items &&
          !!products.items.length &&
          !!products.items[0]
      ) {
        const newProduct = transformProductDetail(products.items[0]);

        addProductViewCount({
          variables: {
            id: newProduct.id,
          },
        });

        let initSelectedVariants =
            newProduct?.configurableOptions &&
            newProduct?.configurableOptions.map((item) => {
              return {
                key: item.attribute_code,
                value: null,
              };
            });
        setSelectedVariants(initSelectedVariants);

        setProduct(newProduct);
        setPrice((newProduct && newProduct.price) || 0);
        setOldPrice((newProduct && newProduct.oldPrice) || 0);
        const newProductImage = [newProduct.image];

        setProductImage(newProductImage);
      }

      if (res.data.errors) {
        let errors = res.data.errors;
        toAstError(errors);
      }

      setIsSubmitLoading(false);
      return;
    });
  };

  const [addProductViewCount]: any = useLazyQuery(ADD_PRODUCT_VIEW_COUNT_GQL, {
    fetchPolicy: 'network-only',
  });

  const getSelectedVariant = () => {
    if (!product || !product.configurableOptions) return null;

    const { variants } = product;
    if (!variants || !variants.length) return null;

    return variants.find((variant) => {
      if (!variant || !variant.attributes) return false;

      let checMatch = true;
      variant.attributes.map((attribute) => {
        const findVariant =
          selectedVariants &&
          selectedVariants.find((item) => item.key === attribute.code);

        if (findVariant?.value?.value_index !== attribute?.value_index)
          checMatch = false;

        return null;
      });

      return checMatch;
    });
  };

  const detectPriceChange = () => {
    const selectedVariant = getSelectedVariant();
    if (!selectedVariant) return;

    const price =
      (!!selectedVariant.product &&
        !!selectedVariant.product.price_range &&
        !!selectedVariant.product.price_range &&
        !!selectedVariant.product.price_range.minimum_price &&
        !!selectedVariant.product.price_range.minimum_price.final_price &&
        selectedVariant.product.price_range.minimum_price.final_price.value) ||
      0;

    const oldPrice =
      (!!selectedVariant.product &&
        !!selectedVariant.product.price_range &&
        !!selectedVariant.product.price_range &&
        !!selectedVariant.product.price_range.minimum_price &&
        !!selectedVariant.product.price_range.minimum_price.regular_price &&
        selectedVariant.product.price_range.minimum_price.regular_price
          .value) ||
      0;

    if (!!price) setPrice(price);
    if (!!oldPrice) setOldPrice(oldPrice);
  };

  const initRelevantItems = () => {
    setAvailableVariantProducts(initAvailableVariantProducts);
  };

  // @ts-ignore
  const history = new useHistory();

  const init = () => {
    queryGetProductDetail(modalData);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    detectPriceChange();
    upAvailableProducts();
  }, [selectedVariants]);

  useEffect(() => {
    initRelevantItems();
  }, [product]);

  const handleAddToCart = ({
    sku,
    quantity,
    isCongifurableProduct,
    parentSku = '',
  }) => {
    if (!isCongifurableProduct) {
      addSimpleProductToCart({
        variables: { sku, quantity, cartId },
      });
    } else {
      addConfigurableProductToCart({
        variables: { sku, quantity, cartId, parentSku },
      });
    }
  };

  const [addSimpleProductToCart, { loading: isAddSimpleProductLoading }]: any =
    useMutation(ADD_SIMPLE_PRODUCT_TO_CART_GQL, {
      onCompleted: ({ addSimpleProductsToCart }) => {
        toastAlert({ type: 'success', message: 'Added to cart successful' });
        const cartInfo =
          !!addSimpleProductsToCart && addSimpleProductsToCart.cart;
        !!cartInfo && saveCartInfoAction(cartInfo);
          history.push(ROUTINGS.ROUTING_CART);
      },
      onError: ({ message }) => {
        toastAlert({ type: 'error', message });
        getCartInfo({ variables: { cartId } });
      },
    });

  const [
    addConfigurableProductToCart,
    { loading: isAddConfigurationProductLoading },
  ]: any = useMutation(ADD_CONFIGRURABLE_PRODUCT_TO_CART_GQL, {
    onCompleted: ({ addConfigurableProductsToCart }) => {
      toastAlert({ type: 'success', message: 'Added to cart successful' });
      const cartInfo =
        !!addConfigurableProductsToCart && addConfigurableProductsToCart.cart;
      !!cartInfo && saveCartInfoAction(cartInfo);
      history.push(ROUTINGS.ROUTING_CART);
    },
    onError: ({ message }) => {
      toastAlert({ type: 'error', message });
      getCartInfo({ variables: { cartId } });
    },
  });

  const [getCartInfo, { loading, data: cartDataInfo }]: any = useLazyQuery(
    GET_CART_INFO_GQL,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      onCompleted: ({ cart }) => {
        saveCartInfoAction(cart);
      },
      onError: ({ message }: any) => {
        if (!!cartDataInfo) {
          const { cart } = cartDataInfo;
          if (!!saveCartInfoAction(cart)) {
            saveCartInfoAction(cart);
          }
        }
      },
    }
  );

  const handleClickAddToCart = (e) => {
    e.preventDefault();

    if (!!isAddSimpleProductLoading || !!isAddConfigurationProductLoading)
      return;

    // SIMPLE PRODUCT
    if (!product.configurableOptions) {
      handleAddToCart({
        isCongifurableProduct: false,
        sku: product.sku,
        quantity: qty,
      });

      return;
    }

    const missingSelectedVariants =
      selectedVariants && selectedVariants.find((item) => !item.value);

    if (!!missingSelectedVariants) {
      return toastAlert({
        type: 'error',
        message: `Need to select ${missingSelectedVariants.key} variants`,
      });
    }

    const selectedVariant = getSelectedVariant();
    if (!selectedVariant)
      return toastAlert({
        type: 'error',
        message: 'This option(s) is disabled, please try another.',
      });

    handleAddToCart({
      isCongifurableProduct: true,
      parentSku: product.sku,
      sku: selectedVariant.product.sku,
      quantity: qty,
    });
  };

  if (!!isSubmitLoading || !product) {
    return (
      <div
        className='modal fade productQuickView show'
        style={{ paddingRight: '16px', display: 'block' }}
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <button
              type='button'
              onClick={closeModal}
              className='close'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>
                <i className='fas fa-times'></i>
              </span>
            </button>
            <div
              className='row align-items-center'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 300,
              }}
            >
              <span className='spinner-grow spinner-grow-lg' />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className='modal fade productQuickView show'
      style={{ paddingRight: '16px', display: 'block' }}
    >
      {/* <ToastContainer /> */}
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <button
            type='button'
            onClick={closeModal}
            className='close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>
              <i className='fas fa-times'></i>
            </span>
          </button>
          <div className='row align-items-center'>
            <div className='col-lg-6 col-md-6'>
              <div className='productQuickView-image'>
                <img src={productImage && productImage[0].url} alt={productImage && productImage[0].label} />
              </div>
            </div>

            <div className='col-lg-6 col-md-6'>
              <div className='product-content'>
                <h3>
                  <NavLink to='#'>{product.name}</NavLink>
                </h3>

                <div className='price'>
                  {!!oldPrice && (
                    <span
                      style={{
                        textDecoration: 'line-through',
                        opacity: 0.4,
                        marginRight: 10,
                      }}
                      className='new-price'
                    >
                      ${oldPrice}
                    </span>
                  )}
                  <span className='new-price'>${price}</span>
                </div>

                <div className='product-review'>
                  <div className='rating'>
                    <RatingValue value={product.ratingSummary / 20} />
                  </div>

                  <div className='rating-count'>
                    {product.reviewCount} reviews
                  </div>
                </div>

                <ul className='product-info'>
                  <li>
                    <span>Availability:</span> <b>{product.stockStatus}</b>
                  </li>
                </ul>

                {!!product.configurableOptions &&
                  product.configurableOptions.map((variant) => {
                    if (!variant) return null;

                    if ('color' === variant.attribute_code) {
                      return (
                        <div className='product-color-switch'>
                          <h4>{variant?.label}:</h4>
                          <ul>
                            {!!variant.values &&
                              variant.values.map((variantValues) => {
                                const isSelected = selectedVariants.find(
                                    (item) =>
                                        item?.value?.uid == variantValues?.uid
                                );

                                let className = '';
                                if (isSelected) {
                                  className = 'active';
                                }

                                if (availableVariantProducts) {
                                  selectedVariants.map((child) => {
                                    if (child.key === variant.attribute_code) {
                                      if (!child.is_selected) {
                                        let isAvailable = false;
                                        availableVariantProducts.map((availableVariantProduct) => {
                                          if (availableVariantProduct.includes(variantValues.uid)) {
                                            isAvailable = true;
                                            return true;
                                          }
                                        });

                                        if (!isAvailable) {
                                          className = 'disabled';
                                        }
                                      } else {
                                        if (availableVariantProducts.length === 0) {
                                          selectedVariants.map((selectedVariant) => {
                                            if (selectedVariant?.value?.uid === variantValues?.uid) {
                                              className = 'disabled';
                                            }
                                          });
                                        }
                                      }
                                    }
                                  });
                                }

                                return (
                                  <li
                                    className={className}
                                    onMouseDown={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleSelectVariant(
                                          variant.attribute_code,
                                          variantValues
                                      );
                                    }}
                                  >
                                    <NavLink to='#' title={variantValues.label} onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }}>
                                      <span
                                        style={{
                                          position: 'absolute',
                                          width: 'calc(100% - 4px)',
                                          height: 'calc(100% - 4px)',
                                          top: 2,
                                          left: 2,
                                          zIndex: 100,
                                          borderRadius: '50%',
                                          background: variantValues.label,
                                        }}
                                      />
                                    </NavLink>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      );
                    }

                    return (
                      <div className='product-size-wrapper'>
                        <h4>{variant?.label}:</h4>

                        <ul>
                          {!!variant?.values &&
                            variant?.values.map((variantValues) => {
                              const isSelected = selectedVariants.find(
                                  (item) =>
                                      item?.value?.uid == variantValues?.uid
                              );

                              let className = '';
                              if (isSelected) {
                                className = 'active';
                              }

                              if (availableVariantProducts) {
                                selectedVariants.map((child) => {
                                  if (child.key === variant.attribute_code) {
                                    if (!child.is_selected) {
                                      let isAvailable = false;
                                      availableVariantProducts.map((availableVariantProduct) => {
                                        if (availableVariantProduct.includes(variantValues.uid)) {
                                          isAvailable = true;
                                          return true;
                                        }
                                      });

                                      if (!isAvailable) {
                                        className = 'disabled';
                                      }
                                    } else {
                                      if (availableVariantProducts.length === 0) {
                                        selectedVariants.map((selectedVariant) => {
                                          if (selectedVariant?.value?.uid === variantValues?.uid) {
                                            className = 'disabled';
                                          }
                                        });
                                      }
                                    }
                                  }
                                });
                              }

                              return (
                                <li
                                  className={className}
                                  onMouseDown={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleSelectVariant(
                                        variant.attribute_code,
                                        variantValues
                                    );
                                  }}
                                >
                                  <NavLink
                                    style={{
                                      width: 'auto',
                                      paddingLeft: 12,
                                      paddingRight: 12,
                                    }}
                                    to='#'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }}
                                  >
                                    {variantValues.label}
                                  </NavLink>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    );
                  })}

                <div className='product-add-to-cart' style={{ marginTop: 20 }}>
                  <div className='input-counter'>
                    <span className='minus-btn' onClick={DecreaseItem}>
                      <i className='fas fa-minus'></i>
                    </span>
                    <input
                      value={qty}
                      type={'number'}
                      min='1'
                      className={'hide-control'}
                      readOnly={true}
                      onKeyDown={(e) => {
                        if (
                          !(
                            (e.keyCode >= 48 && e.keyCode <= 57) ||
                            [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) >= 0
                          )
                        )
                          e.preventDefault();
                      }}
                      onChange={(e) => setQty(e.target.value)}
                    />
                    <span className='plus-btn' onClick={IncrementItem}>
                      <i className='fas fa-plus'></i>
                    </span>
                  </div>
                  <button
                    type='submit'
                    style={{ width: 166 }}
                    className='btn btn-primary'
                    onClick={handleClickAddToCart}
                  >
                    {!!isAddSimpleProductLoading ||
                    !!isAddConfigurationProductLoading ? (
                      <span className='spinner-grow spinner-grow-sm' />
                    ) : (
                      <>
                        <i className='fas fa-cart-plus'></i> Add to Cart
                      </>
                    )}
                  </button>
                </div>

                <NavLink
                  to={`${ROUTINGS.ROUTING_PRODUCT_DETAIL_PATH}/${product.slug}`}
                  className='view-full-info'
                >
                  View full info
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickView);
