import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { GET_COMPARE_LIST } from '../../graphql/customer.gql';
import { useLazyQuery } from '@apollo/client';
import { toastAlert } from '../../utils/toastify';

import Facility from '../../components/Common/Facility';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { mapStateToProps, mapDispatchToProps } from './state';
import Content from '../../components/compare/Content';
import Meta from "../../components/meta";

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: any) => {
  const {
    compareState: { compareListUUID, compareData },
    saveCompareList,
  } = props;

  const init = () => {
    setTimeout(() => {
      !!compareListUUID &&
        !!compareListUUID.length &&
        getCompareList({
          variables: {
            compareListUUID,
          },
        });
    }, 1000);
  };

  const [getCompareList]: any = useLazyQuery(GET_COMPARE_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: ({ compareList }) => {
      saveCompareList({ compareData: compareList });
    },
    onError: ({ message }) => toastAlert({ type: 'error', message }),
  });

  useEffect(() => {
    init();
  }, []);

  const [meta, setMeta] = useState({
    title: "Compare page",
    description: "",
    keyword: ""
  });

  return (
    <>
      <Meta
          title={meta.title}
          description={meta.description}
          keywords={meta.keyword}
      />
      <Breadcrumb title='Compare' />

      <Content
        compare_products={(compareData && compareData.items) || []}
        compareListUUID={compareListUUID}
        saveCompareList={saveCompareList}
      />

      <Facility />
    </>
  );
});
