import React, { Component, useEffect, useState } from "react";
import {QUERY_GET_PRODUCTS_CATEGORY_STYLE_TWO_AREA_GQL} from "../../graphql/layout.gql";
import { useLazyQuery } from "@apollo/client";
import { toastAlert } from "../../utils/toastify";
import { renderHtmlContent } from "../../utils/html";
import callGetApi, {toAstError} from "../../api";

const ProductsCategoryStyleTwo = () => {
  const [productsCategoryBlock, setProductsCategoryBlock] = useState(null);

  const init = () => {
    queryGetProductsCategoryBlock();
  };

    const queryGetProductsCategoryBlock = () => {
        callGetApi(QUERY_GET_PRODUCTS_CATEGORY_STYLE_TWO_AREA_GQL).then((res: any) => {
            let cmsBlocks =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.cmsBlocks && res.data.data.cmsBlocks;

            if (cmsBlocks) {
                setProductsCategoryBlock(cmsBlocks.items);
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
            }
        });
    };

  useEffect(() => {
    init();
  }, []);
  return (
    <>
      {!!productsCategoryBlock &&
        !!productsCategoryBlock.length &&
        productsCategoryBlock.map((item) => {
          return renderHtmlContent({ content: item.content });
        })}
    </>
  );
};

export default ProductsCategoryStyleTwo;
