import { gql, useMutation } from '@apollo/client';
export const GET_SLIDER_GQL = gql`
  query getSlider {
    getSlider(slider_name: "Homepage Slider") {
      id
      slider_name
      display_from
      display_to
      items {
        id
        banner_type
        html
      }
    }
  }
`;

export const QUERY_GET_FACILITY_AREA_GQL = `
  query getFacilityArea {
    cmsBlocks(identifiers: "facility-area") {
      items {
        identifier
        title
        content
      }
    }
  }
`;
export const GET_SHIPPING_AREA_GQL = gql`
  query getShippingArea {
    cmsBlocks(identifiers: "shipping-block") {
      items {
        identifier
        title
        content
      }
    }
  }
`;

export const QUERY_GET_CUSTOM_PAYMENT_AREA_GQL = `
  query getCustomPaymentArea {
    cmsBlocks(identifiers: "custom-payment-area") {
      items {
        identifier
        title
        content
      }
    }
  }
`;

export const QUERY_GET_SHIPPING_TAB_AREA_GQL = `
  query getShippingTabArea {
    cmsBlocks(identifiers: "shipping-tab") {
      items {
        identifier
        title
        content
      }
    }
  }
`;

export const QUERY_GET_WHY_BUY_TAB_AREA_GQL = `
  query getWhyBuuyTabArea {
    cmsBlocks(identifiers: "why-buy-tab") {
      items {
        identifier
        title
        content
      }
    }
  }
`;

export const QUERY_GET_FOOTER_AREA_GQL = `
  query getFooterArea {
    cmsBlocks(identifiers: "footer-area") {
      items {
        identifier
        title
        content        
      }
    }
  }
`;

export const QUERY_GET_COPY_RIGHT_AREA_GQL = `
  query getCopyRightArea {
    cmsBlocks(identifiers: "copy-right-area") {
      items {
        identifier
        title
        content
      }
    }
  }
`;

export const QUERY_GET_NEWSLETTER_CONTENT_AREA_GQL = `
  query getNewsLetterContentArea {
    cmsBlocks(identifiers: "newsletter-content-area") {
      items {
        identifier
        title
        content
      }
    }
  }
`;

export const GET_INSTAGRAM_SQL = gql`
  query getGalleriesByCategoryId {
    getGalleriesByCategoryId(
      categoryIdentifier: "homepage-gallery"
      limit: 10
      curPage: 1
    ) {
      category_id
      category_name
      count_galleries
      last_page_num
      items {
        id
        name
        alt
        image_url
      }
    }
  }
`;
export const GET_ABOUT_AREA_GQL = gql`
  query getAboutArea {
    cmsBlocks(identifiers: "about-area") {
      items {
        identifier
        title
        content
      }
    }
  }
`;

export const GET_CONTACT_INFO_AREA_GQL = gql`
  query getContactInfoArea {
    cmsBlocks(identifiers: "contact-info-area") {
      items {
        identifier
        title
        content
      }
    }
  }
`;
export const GET_CUSTOMER_SERVICE_CONTENT_AREA_GQL = gql`
  query getCutomerServiceContent {
    cmsBlocks(identifiers: "customer-service-content") {
      items {
        identifier
        title
        content
      }
    }
  }
`;

export const QUERY_GET_PRODUCT_CATEGORIES_AREA_GQL = `
  query getProductCategories {
    cmsBlocks(identifiers: "product-categories-area") {
      items {
        identifier
        title
        content
      }
    }
  }
`;

export const GET_SIZE_GUIDE_AREA_GQL = gql`
  query getSizeGuide {
    cmsBlocks(identifiers: "sizing-guide-area") {
      items {
        identifier
        title
        content
      }
    }
  }
`;
export const GET_FAQ_AREA_GQL = gql`
  query getFaq {
    cmsBlocks(identifiers: "faq-area") {
      items {
        identifier
        title
        content
      }
    }
  }
`;

export const QUERY_GET_PRODUCTS_CATEGORY_STYLE_TWO_AREA_GQL = `
  query getProductsCategoryStyleTwo {
    cmsBlocks(identifiers: "ProductsCategoryStyleTwo-Area") {
      items {
        identifier
        title
        content
      }
    }
  }
`;

export const GET_TESTIMONIALS_SLIDER_GQL = gql`
  query getTestimonialsSlider {
    getSlider(slider_name: "testimonials-slides") {
      id
      slider_name
      display_from
      display_to
      items {
        id
        banner_type
        html
      }
    }
  }
`;
export const GET_COLLECTION_LISTS_GQL = gql`
  query categories {
    categories {
      total_count
      items {
        id
        level
        name
        image
        product_count
        breadcrumbs {
          category_name
          category_url_key
        }
        url_key
        children_count
        children {
          id
          level
          name
          image
          product_count
          url_key
          children_count
          children {
            id
            level
            name
            image
            product_count
            url_key
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
    }
  }
`;

export const QUERY_GET_CMS_PAGE_GQL = `
query cmsPage{
  cmsPage(identifier: {{$identifier}}) {    
    identifier
    title  
    meta_title
    meta_description
    meta_keywords    
  }
}
`;

//Home- Porduct
export const QUERY_GET_POPULAR_PRODUCT_GQL = `
  query popularProducts {
    popularProducts(pageSize: 10, currentPage: 1, sort: { name: DESC }) {
      total_count
      page_info {
        page_size
        current_page
        total_pages
      }
      items {
        name
        sku
        categories {
          id
          name
        }
        url_key
        rating_summary
        review_count
        __typename
        stock_status
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
          maximum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
        small_image {
          url
          label
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
            }
            product_id
          }
          variants {
            product {
              id
              name
              sku
              stock_status
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
              image {
                url
                label
              }
              small_image {
                url
                label
              }
              media_gallery {
                url
                label
              }
            }
            attributes {
              uid
              label
              code
              value_index
            }
          }
        }
      }
    }
  }
`;

export const QUERY_GET_TRENDING_PRODUCT_GQL = `
  query products{
    products(
      filter: { category_id: { in: [{{$trendingCategoryId}}] } }
      pageSize: 10
      currentPage: 1
      sort: { name: DESC }
    ) {
      total_count
      page_info {
        page_size
        current_page
      }
      items {
        name
        sku
        categories {
          id
          name
        }
        url_key
        rating_summary
        review_count
        __typename
        stock_status
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
          maximum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
        small_image {
          url
          label
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
            }
            product_id
          }
          variants {
            product {
              id
              name
              sku
              stock_status
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
              image {
                url
                label
              }
              small_image {
                url
                label
              }
              media_gallery {
                url
                label
              }
            }
            attributes {
              uid
              label
              code
              value_index
            }
          }
        }
      }
    }
  }
`;

export const QUERY_GET_BESTSELLERS_PRODUCT_GQL = `
  query bestSellerProducts {
    bestSellerProducts(pageSize: 10, currentPage: 1, sort: { name: DESC }) {
      total_count
      page_info {
        page_size
        current_page
        total_pages
      }
      items {
        name
        sku
        categories {
          id
          name
        }
        url_key
        rating_summary
        review_count
        __typename
        stock_status
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
          maximum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
        small_image {
          url
          label
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
            }
            product_id
          }
          variants {
            product {
              id
              name
              sku
              stock_status
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
              image {
                url
                label
              }
              small_image {
                url
                label
              }
              media_gallery {
                url
                label
              }
            }
            attributes {
              uid
              label
              code
              value_index
            }
          }
        }
      }
    }
  }
`;

export const SUBSCRIBE_EMAIL_GQL = gql`
  mutation SubscribeEmailToNewsletter($email: String!) {
    subscribeEmailToNewsletter(email: $email) {
      status
    }
  }
`;

export const GET_LIST_BRANDINGS_GQL = gql`
  query getListBrandings {
    getListBrandings(pageSize: 10, currentPage: 1) {
      total_count
      current_page
      page_size
      last_page_num
      items {
        id
        name
        small_image
        image
      }
    }
  }
`;

export const GET_CONTACT_FORM_GQL = gql`
  mutation contactUsFormSubmit(
    $name: String!
    $email: String!
    $phoneNumber: String!
    $message: String!
  ) {
    contactUsFormSubmit(
      input: {
        name: $name
        email: $email
        phone_number: $phoneNumber
        message: $message
      }
    ) {
      success_message
    }
  }
`;
