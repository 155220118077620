import { gql, useMutation } from '@apollo/client';

export const CREATE_EMPTY_CART_GQL = gql`
  mutation {
    createEmptyCart
  }
`;

export const ADD_SIMPLE_PRODUCT_TO_CART_GQL = gql`
  mutation AddSimpleProductsToCart(
    $sku: String!
    $quantity: Float!
    $cartId: String!
  ) {
    addSimpleProductsToCart(
      input: {
        cart_id: $cartId
        cart_items: [{ data: { quantity: $quantity, sku: $sku } }]
      }
    ) {
      cart {
        items {
          uid
          id
          product {
            thumbnail {
              url
              label
            }
            name
            url_key
            sku
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                final_price {
                  value
                  currency
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                final_price {
                  value
                  currency
                }
              }
            }
            ... on ConfigurableProduct {
              variants {
                product {
                  name
                  url_key
                  sku
                  thumbnail {
                    url
                    label
                  }
                  price_range {
                    minimum_price {
                      regular_price {
                        value
                        currency
                      }
                      final_price {
                        value
                        currency
                      }
                    }
                  }
                }
                attributes {
                  uid
                  label
                  code
                  value_index
                }
              }
            }
          }
          quantity
        }
        prices {
          subtotal_including_tax {
            value
            currency
          }
          grand_total {
            value
            currency
          }
        }
      }
    }
  }
`;

export const ADD_CONFIGRURABLE_PRODUCT_TO_CART_GQL = gql`
  mutation AddConfigurableProductsToCart(
    $sku: String!
    $quantity: Float!
    $cartId: String!
    $parentSku: String!
  ) {
    addConfigurableProductsToCart(
      input: {
        cart_id: $cartId
        cart_items: [
          { parent_sku: $parentSku, data: { quantity: $quantity, sku: $sku } }
        ]
      }
    ) {
      cart {
        items {
          uid
          id
          product {
            thumbnail {
              url
              label
            }
            name
            url_key
            sku
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                final_price {
                  value
                  currency
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                final_price {
                  value
                  currency
                }
              }
            }
            ... on ConfigurableProduct {
              variants {
                product {
                  name
                  url_key
                  sku
                  thumbnail {
                    url
                    label
                  }
                  price_range {
                    minimum_price {
                      regular_price {
                        value
                        currency
                      }
                      final_price {
                        value
                        currency
                      }
                    }
                  }
                }
                attributes {
                  uid
                  label
                  code
                  value_index
                }
              }
            }
          }
          quantity
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_value_uid
              option_label
              value_label
            }
          }
        }
        prices {
          subtotal_including_tax {
            value
            currency
          }
          grand_total {
            value
            currency
          }
        }
      }
    }
  }
`;

export const GET_CART_INFO_GQL = gql`
  query Cart($cartId: String!) {
    cart(cart_id: $cartId) {
      email
      billing_address {
        city
        country {
          code
          label
        }
        firstname
        lastname
        postcode
        region {
          code
          label
        }
        street
        telephone
      }
      shipping_addresses {
        firstname
        lastname
        street
        city
        company
        postcode
        region {
          code
          label
        }
        country {
          code
          label
        }
        telephone
        pickup_location_code
        available_shipping_methods {
          amount {
            currency
            value
          }
          available
          carrier_code
          carrier_title
          error_message
          method_code
          method_title
          price_excl_tax {
            value
            currency
          }
          price_incl_tax {
            value
            currency
          }
        }
        selected_shipping_method {
          amount {
            value
            currency
          }
          carrier_code
          carrier_title
          method_code
          method_title
        }
      }
      items {
        uid
        id
        product {
          stock_status
          thumbnail {
            url
            label
          }
          name
          url_key
          sku
          price_range {
            minimum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
            }
            maximum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
            }
          }
          ... on ConfigurableProduct {
            variants {
              product {
                name
                url_key
                sku
                thumbnail {
                  url
                  label
                }
                price_range {
                  minimum_price {
                    regular_price {
                      value
                      currency
                    }
                    final_price {
                      value
                      currency
                    }
                  }
                }
              }
              attributes {
                uid
                label
                code
                value_index
              }
            }
          }
        }
        quantity
        ... on ConfigurableCartItem {
          configurable_options {
            configurable_product_option_value_uid
            option_label
            value_label
          }
        }
      }
      available_payment_methods {
        code
        title
      }
      selected_payment_method {
        code
        title
      }
      applied_coupons {
        code
      }
      prices {
        subtotal_including_tax {
          value
          currency
        }
        subtotal_excluding_tax {
          value
          currency
        }
        grand_total {
          value
          currency
        }
        applied_taxes {
          amount {
            value
            currency
          }
          label
        }
        discount {
          amount {
            value
            currency
          }
        }
      }
    }
  }
`;

export const UPDATE_CART_PRODUCT_GQL = gql`
  mutation UpdateCartItems(
    $cartId: String!
    $cartItemId: ID!
    $quantity: Float!
  ) {
    updateCartItems(
      input: {
        cart_id: $cartId
        cart_items: [{ cart_item_uid: $cartItemId, quantity: $quantity }]
      }
    ) {
      cart {
        items {
          uid
          id
          product {
            thumbnail {
              url
              label
            }
            name
            url_key
            sku
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                final_price {
                  value
                  currency
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                final_price {
                  value
                  currency
                }
              }
            }
            ... on ConfigurableProduct {
              variants {
                product {
                  name
                  url_key
                  sku
                  thumbnail {
                    url
                    label
                  }
                  price_range {
                    minimum_price {
                      regular_price {
                        value
                        currency
                      }
                      final_price {
                        value
                        currency
                      }
                    }
                  }
                }
                attributes {
                  uid
                  label
                  code
                  value_index
                }
              }
            }
          }
          quantity
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_value_uid
              option_label
              value_label
            }
          }
        }
        prices {
          subtotal_including_tax {
            value
            currency
          }
          grand_total {
            value
            currency
          }
        }
      }
    }
  }
`;

export const REMOVE_FROM_CART = gql`
  mutation RemoveItemFromCart($cartId: String!, $cartItemId: Int!) {
    removeItemFromCart(input: { cart_id: $cartId, cart_item_id: $cartItemId }) {
      cart {
        items {
          uid
          id
          product {
            thumbnail {
              url
              label
            }
            name
            url_key
            sku
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                final_price {
                  value
                  currency
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                final_price {
                  value
                  currency
                }
              }
            }
            ... on ConfigurableProduct {
              variants {
                product {
                  name
                  url_key
                  sku
                  thumbnail {
                    url
                    label
                  }
                  price_range {
                    minimum_price {
                      regular_price {
                        value
                        currency
                      }
                      final_price {
                        value
                        currency
                      }
                    }
                  }
                }
                attributes {
                  uid
                  label
                  code
                  value_index
                }
              }
            }
          }
          quantity
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_value_uid
              option_label
              value_label
            }
          }
        }
        prices {
          subtotal_including_tax {
            value
            currency
          }
          grand_total {
            value
            currency
          }
        }
      }
    }
  }
`;

export const SET_SHIPPING_ADDRESS_GQL = gql`
  mutation SetShippingAddressesOnCart(
    $countryCode: String!
    $firstname: String!
    $lastname: String!
    $company: String!
    $street: [String]!
    $city: String!
    $region: String!
    $postcode: String!
    $phonenumber: String!
    $customerNotes: String!
    $cartId: String!
    $isSaveAddress: Boolean!
  ) {
    setShippingAddressesOnCart(
      input: {
        cart_id: $cartId
        shipping_addresses: [
          {
            customer_notes: $customerNotes
            address: {
              firstname: $firstname
              lastname: $lastname
              company: $company
              street: $street
              city: $city
              region: $region
              postcode: $postcode
              country_code: $countryCode
              telephone: $phonenumber
              save_in_address_book: $isSaveAddress
            }
          }
        ]
      }
    ) {
      cart {
        shipping_addresses {
          firstname
          lastname
          company
          street
          city
          region {
            code
            label
          }
          postcode
          telephone
          country {
            code
            label
          }
          available_shipping_methods {
            carrier_code
            carrier_title
            method_code
            method_title
            amount {
              value
              currency
            }
          }
        }
      }
    }
  }
`;

export const SET_SHIPPING_ADDRESS_BY_ID_GQL = gql`
  mutation SetShippingAddressesOnCart(
    $cartId: String!
    $customerAddressId: Int!
    $customerNotes: String!
  ) {
    setShippingAddressesOnCart(
      input: {
        cart_id: $cartId
        shipping_addresses: [
          {
            customer_address_id: $customerAddressId
            customer_notes: $customerNotes
          }
        ]
      }
    ) {
      cart {
        shipping_addresses {
          firstname
          lastname
          company
          street
          city
          region {
            code
            label
          }
          postcode
          telephone
          country {
            code
            label
          }
          available_shipping_methods {
            carrier_code
            carrier_title
            method_code
            method_title
            amount {
              value
              currency
            }
          }
        }
      }
    }
  }
`;

export const SET_BILLING_ADDRESS_GQL = gql`
  mutation SetBillingAddressesOnCart(
    $countryCode: String!
    $firstname: String!
    $lastname: String!
    $company: String!
    $street: [String]!
    $city: String!
    $region: String!
    $postcode: String!
    $phonenumber: String!
    $cartId: String!
  ) {
    setBillingAddressOnCart(
      input: {
        cart_id: $cartId
        billing_address: {
          address: {
            firstname: $firstname
            lastname: $lastname
            company: $company
            street: $street
            city: $city
            region: $region
            postcode: $postcode
            country_code: $countryCode
            telephone: $phonenumber
            save_in_address_book: false
          }
        }
      }
    ) {
      cart {
        billing_address {
          firstname
          lastname
          company
          street
          city
          region {
            code
            label
          }
          postcode
          telephone
          country {
            code
            label
          }
        }
      }
    }
  }
`;

export const SET_BILLING_ADDRESS_BY_ID_GQL = gql`
  mutation SetBillingAddressesOnCart(
    $cartId: String!
    $customerAddressId: Int!
  ) {
    setBillingAddressOnCart(
      input: {
        cart_id: $cartId
        billing_address: { 
          customer_address_id: $customerAddressId
        }
      }
    ) {
      cart {
        billing_address {
          firstname
          lastname
          company
          street
          city
          region {
            code
            label
          }
          postcode
          telephone
          country {
            code
            label
          }
        }
      }
    }
  }
`;

export const SET_EMAIL_ON_CART_GQL = gql`
  mutation SetGuestEmailOnCart($cartId: String!, $email: String!) {
    setGuestEmailOnCart(input: { cart_id: $cartId, email: $email }) {
      cart {
        email
      }
    }
  }
`;

export const SET_SHIPPING_METHOD_GQL = gql`
  mutation setShippingMethodsOnCart(
    $cartId: String!
    $carrierCode: String!
    $methodCode: String!
  ) {
    setShippingMethodsOnCart(
      input: {
        cart_id: $cartId
        shipping_methods: [
          { carrier_code: $carrierCode, method_code: $methodCode }
        ]
      }
    ) {
      cart {
        shipping_addresses {
          selected_shipping_method {
            carrier_code
            method_code
            carrier_title
            method_title
          }
        }
        prices {
          grand_total {
            value
            currency
          }
        }
      }
    }
  }
`;

export const SET_STRIPE_PAYMENT_METHOD_GQL = gql`
  mutation SetPaymentMethodOnCart($cartId: String!, $ccStripejsToken: String!) {
    setPaymentMethodOnCart(
      input: {
        cart_id: $cartId
        payment_method: {
          code: "stripe_payments"
          stripe_payments: {
            cc_stripejs_token: $ccStripejsToken
            cc_save: true
            location: "checkout"
          }
        }
      }
    ) {
      cart {
        selected_payment_method {
          code
        }
      }
    }
  }
`;

export const SET_PAYMENT_METHOD_GQL = gql`
  mutation SetPaymentMethodOnCart(
    $cartId: String!
    $paymentMethodCode: String!
  ) {
    setPaymentMethodOnCart(
      input: { cart_id: $cartId, payment_method: { code: $paymentMethodCode } }
    ) {
      cart {
        selected_payment_method {
          code
        }
      }
    }
  }
`;

export const PLACE_ORDER_GQL = gql`
  mutation PlaceOrder($cartId: String!) {
    placeOrder(input: { cart_id: $cartId }) {
      order {
        order_number
      }
    }
  }
`;

export const GET_CUSTOMER_ADDRESS_GQL = gql`
  query GetCustomerAddress {
    customer {
      firstname
      lastname
      suffix
      email
      addresses {
        id
        firstname
        lastname
        street
        city
        region {
          region_code
          region
        }
        postcode
        country_code
        telephone
        default_shipping
        default_billing
      }
    }
  }
`;
export const CREATE_PAYPAL_TOKEN_GQL = gql`
  mutation CreatePaypalExpressToken(
    $cartId: String!
    $returnUrl: String!
    $cancelUrl: String!
  ) {
    createPaypalExpressToken(
      input: {
        cart_id: $cartId
        code: "paypal_express"
        urls: { return_url: $returnUrl, cancel_url: $cancelUrl }
        is_external_frontend: true
      }
    ) {
      token
      paypal_urls {
        start
        edit
      }
    }
  }
`;

export const SET_PAYPAL_PAYMENT_METHOD_GQL = gql`
  mutation SetPaymentMethodOnCart(
    $cartId: String!
    $payerId: String!
    $token: String!
  ) {
    setPaymentMethodOnCart(
      input: {
        cart_id: $cartId
        payment_method: {
          code: "paypal_express"
          paypal_express: { payer_id: $payerId, token: $token }
        }
      }
    ) {
      cart {
        selected_payment_method {
          code
          title
        }
      }
    }
  }
`;

export const APPLY_COUPON_CODE = gql`
  mutation ApplyCouponToCart($cartId: String!, $couponCode: String!) {
    applyCouponToCart(input: { cart_id: $cartId, coupon_code: $couponCode }) {
      cart {
        applied_coupons {
          code
        }
      }
    }
  }
`;

export const REMOVE_COUPON_CODE = gql`
  mutation RemoveCouponFromCart($cartId: String!) {
    removeCouponFromCart(input: { cart_id: $cartId }) {
      cart {
        applied_coupons {
          code
        }
      }
    }
  }
`;

export const GET_STRIPE_CONFIG = gql`
  query storeConfig {
    storeConfig {
      stripe_mode
      stripe_live_pk
      stripe_test_pk
    }
  }
`;

export const MERGE_CART_GQL = gql`
  mutation MergeCarts($guestCartId: String!, $customerCartId: String!) {
    mergeCarts(
      source_cart_id: $guestCartId
      destination_cart_id: $customerCartId
    ) {
      id
    }
  }
`;
