export const PAGE_SIZE_DEFAULT = '5';

export const PAGE_SIZE_LIST = ['5', '10', '15', '20', '50'];

export const SORT_DEFAULT = 'position-ASC';

export const SORT_FIELD_DEFAULT = [
  {
    label: 'Position',
    value: 'position',
  },
  {
    label: 'Product Name',
    value: 'name',
  },
  {
    label: 'Price',
    value: 'price',
  },
];
