import React, {Component, useState} from "react";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import CartContent from "../../../components/cart/CartContent";
import Facility from "../../../components/Common/Facility";
import Meta from "../../../components/meta";

export default () => {
    const [meta, setMeta] = useState({
        title: "Shopping Cart",
        description: "",
        keyword: ""
    });

  return (
    <>
        <Meta
            title={meta.title}
            description={meta.description}
            keywords={meta.keyword}
        />
      <Breadcrumb title="Cart" />

      <CartContent />
      <Facility />
    </>
  );
};
