import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { toastAlert } from '../../../utils/toastify';
import * as ROUTINGS from '../../../routings/path';
import Cart from '../../Modal/Cart';
import { replaceAllSpace, checkEmptryString } from '../../../utils/validation';
import { QUERY_GET_MEGA_MENU_GQL } from '../../../graphql/megamenu.gql';
import callGetApi, { toAstError } from '../../../api';

import logo from '../../../images/logo.png';

import { mapStateToProps, mapDispatchToProps } from './state';

import './style.css';

const SearchForm = withRouter(({ onSearch, history }: any) => {
  const [keyword, setKeyword] = useState('');
  const [searchForm, setSearchForm] = useState(false);

  const handleChangeKeyword = (e) => setKeyword(e.target.value);
  const handleToggleSearchForm = () => setSearchForm(!searchForm);

  const handleSubmitForm = (e) => {
    e.preventDefault();

    onSearch && onSearch();

    if (checkEmptryString(keyword)) {
      toastAlert({
        type: 'error',
        message: 'Search keyword is invalid, please try again',
      });
      return;
    }

    if (replaceAllSpace(keyword).length < 3) {
      toastAlert({
        type: 'error',
        message: 'Search keyword need to have from 3 characters',
      });
      return;
    }

    setSearchForm(false);
    history.push(`${ROUTINGS.ROUTING_SEARCH_PATH}/${keyword}`);
  };

  return (
    <div className='option-item'>
      <i
        onClick={handleToggleSearchForm}
        className='search-btn fas fa-search'
        style={{ display: searchForm ? 'none' : 'block' }}
      ></i>

      <i
        onClick={handleToggleSearchForm}
        className={`close-btn fas fa-times ${searchForm ? 'active' : ''}`}
      ></i>

      <div
        className='search-overlay search-popup'
        style={{ display: searchForm ? 'block' : 'none' }}
      >
        <div className='search-box'>
          <form className='search-form' onSubmit={handleSubmitForm}>
            <input
              value={keyword}
              onChange={handleChangeKeyword}
              className='search-input'
              placeholder='Search'
              name='search'
              type='text'
            />
            <button className='search-button' type='submit'>
              <i className='fas fa-search'></i>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
});

interface IProps {
  products?: Array<any>;
  userState?: any;
  checkoutState?: any;
  clearCartAction: Function;
  signOutAction: Function;
  clearCompareData?: any;
  clearWishlistAction?: any;
  clearUerInfoAction: Function;
}

const mapMenuItemNameToRouter = {
  '/': ROUTINGS.ROUTING_HOME,
  blogs: ROUTINGS.ROUTING_HOME,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    products,
    userState,
    checkoutState,
    signOutAction,
    clearUerInfoAction,
    clearCompareData,
    clearCartAction,
    clearWishlistAction,
  }: IProps) => {
    const { info: userInfo } = userState;
    const { totalItemsInCart } = checkoutState;

    const [display, setDisplay] = useState(false);
    const [collapsed, setCollapsed] = useState(true);

    const handleCart = () => {
      if (0 === totalItemsInCart) {
        toastAlert({
          type: 'error',
          message: 'There are no item in cart. Please continues shopping',
        });
        return;
      }
      setDisplay(!display);
    };

    const toggleNavbar = () => {
      setCollapsed(!collapsed);
    };

    useEffect(() => {
      let elementId = document.getElementById('navbar');

      document.addEventListener('scroll', () => {
        if (window.scrollY > 170) {
          elementId.classList.add('is-sticky');
        } else {
          elementId.classList.remove('is-sticky');
        }
      });
      window.scrollTo(0, 0);
    }, []);

    const classOne = collapsed
      ? 'collapse navbar-collapse'
      : 'collapse navbar-collapse show';
    const classTwo = collapsed
      ? 'navbar-toggler navbar-toggler-right collapsed'
      : 'navbar-toggler navbar-toggler-right';

    const init = () => {
      queryGetMegamenu();
    };

    const [menu, setMenu] = useState(null);

    const formatMenu = (menu) => {
      if (menu.index === 'homepage') return '/';
      if (menu.index === 'blog') return ROUTINGS.ROUTING_BLOG_PATH;
      if (menu.index.indexOf('category-node-') !== -1)
        return `${ROUTINGS.ROUTING_PRODUCT_CATEGORY_PATH}/${menu.url_key}`;
    };

    const queryGetMegamenu = () => {
      callGetApi(QUERY_GET_MEGA_MENU_GQL).then((res: any) => {
        let megamenu =
          !!res.data &&
          !!res.data.data &&
          !!res.data.data.megamenu &&
          res.data.data.megamenu;

        if (megamenu) {
          setMenu(megamenu.data);
        }

        if (res.data.errors) {
          let errors = res.data.errors;
          toAstError(errors);
        }
      });
    };

    useEffect(() => {
      init();
    }, []);

    return (
      <>
        <div className='navbar-area'>
          <div id='navbar' className='comero-nav'>
            <div className='container'>
              <nav className='navbar navbar-expand-md navbar-light'>
                <NavLink to='/'>
                  <a className='navbar-brand'>
                    <img src={logo} alt='logo' />
                  </a>
                </NavLink>

                <button
                  onClick={toggleNavbar}
                  className={classTwo}
                  type='button'
                  data-toggle='collapse'
                  data-target='#navbarSupportedContent'
                  aria-controls='navbarSupportedContent'
                  aria-expanded='false'
                  aria-label='Toggle navigation'
                >
                  <span className='icon-bar top-bar'></span>
                  <span className='icon-bar middle-bar'></span>
                  <span className='icon-bar bottom-bar'></span>
                </button>

                <div className={classOne} id='navbarSupportedContent'>
                  {!!menu && !!menu.length && (
                    <ul className='navbar-nav'>
                      {menu.map((item) => {
                        return (
                          <>
                            <li
                              className='nav-item p-relative'
                              onClick={() => setCollapsed(true)}
                            >
                              <NavLink
                                to={formatMenu(item)}
                                className='nav-link active'
                              >
                                {item.label}{' '}
                                {!!item.children && !!item.children.length && (
                                  <i className='fas fa-chevron-down'></i>
                                )}
                              </NavLink>
                              {!!item.children && !!item.children.length && (
                                <ul className='dropdown-menu'>
                                  {item.children.map((sub) => {
                                    return (
                                      <>
                                        <li className='nav-item'>
                                          <NavLink to={formatMenu(sub)}>
                                            <a className='nav-link'>
                                              {sub.label}
                                            </a>
                                          </NavLink>
                                        </li>
                                      </>
                                    );
                                  })}
                                </ul>
                              )}
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  )}

                  <div className='others-option'>
                    <SearchForm onSearch={() => setCollapsed(true)} />

                    <div className='option-item'>
                      {!!userInfo ? (
                        <div className='dropdown' id='user-menu-dropdown'>
                          <a
                            style={{
                              maxWidth: 150,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                            className='dropdown-toggle'
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            href='#'
                            role='button'
                            id='dropdownMenuLink'
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                          >
                            {`Hi, ${userInfo.firstname} ${userInfo.lastname}`}
                          </a>
                          <div
                            className='dropdown-menu'
                            aria-labelledby='dropdownMenuButton'
                          >
                            <NavLink
                              onClick={() => setCollapsed(true)}
                              to={ROUTINGS.ROUTING_USER}
                              className='dropdown-item'
                              href='#'
                            >
                              Profile
                            </NavLink>
                            <div
                              onClick={() => {
                                setCollapsed(true);
                                clearCartAction();
                                clearCompareData();
                                clearUerInfoAction();
                                clearWishlistAction();
                                signOutAction();
                              }}
                              className='dropdown-item'
                            >
                              Sign out
                            </div>
                          </div>
                        </div>
                      ) : (
                        <NavLink
                          to={ROUTINGS.ROUTING_AUTH_SIGN_IN}
                          onClick={() => setCollapsed(true)}
                        >
                          Login
                        </NavLink>
                      )}
                    </div>

                    <div className='option-item'>
                      <NavLink to='#'>
                        <a
                          onClick={(e) => {
                            setCollapsed(true);
                            e.preventDefault();
                            handleCart();
                          }}
                        >
                          {`Cart `}
                          {!!totalItemsInCart && ` (${totalItemsInCart}) `}
                          <i className='fas fa-shopping-bag'></i>
                        </a>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>

        {display ? <Cart onClick={handleCart} /> : ''}
      </>
    );
  }
);
function data(data: any): (data: any) => void {
  throw new Error('Function not implemented.');
}
