import { gql, useMutation } from '@apollo/client';

export const QUERY_SEARCH_BY_KEYWORD_GQL = `
  query Products{
    products(
      search: {{$search}}, 
      filter: {{$filter}}
      pageSize: {{$pageSize}}, 
      currentPage: {{$currentPage}}
      sort: {{$sort}}
    ) {
      aggregations {
        count
        label
        attribute_code
        options {
          count
          label
          value
        }
      }
      sort_fields {
        default
        options {
          label
          value
        }
      }
      total_count
      items {
        id
        name
        url_key
        sku
        small_image {
          url
        }
        review_count
        rating_summary
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
            }
            product_id
          }
          variants {
            product {
              id
              name
              sku
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
            attributes {
              uid
              label
              code
              value_index
            }
          }
        }
      }
      page_info {
        page_size
        current_page
      }
    }
  }
`;

export const QUERY_GET_PRODUCT_DETAIL_BY_SLUG = `
  query product {
    products(filter: { url_key: { eq: {{$param}} } }) {
      items {
        id
        meta_title
        meta_keyword
        meta_description
        rating_summary
        review_count
        name
        sku
        categories {
          id
        }
        url_key
        reviews(pageSize: 20, currentPage: 1) {
          page_info {
            page_size
            current_page
          }
          items {
            summary
            text
            nickname
            created_at
            average_rating
          }
        }
        __typename
        stock_status
        description {
          html
        }
        short_description {
          html
        }
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
        image {
          url
          label
        }        
        media_gallery {
          url
          label
        }
        related_products {
          id
          name
          sku
          url_key
          rating_summary
          review_count
          price_range {
            minimum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
            }
            maximum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
            }
          }
          small_image {
            url
            label
          }
        }
        ... on ConfigurableProduct {
          configurable_options {
            uid
            id
            label
            position
            use_default
            attribute_code
            values {
              uid
              value_index
              label
            }
            product_id
          }
          variants {
            product {              
              id
              name
              sku
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
            attributes {
              uid
              label
              code
              value_index
            }
          }
        }
      }
      total_count
      page_info {
        page_size
      }
    }
  }
`;

export const GET_REVIEW_META_DATA = gql`
  query productReviewRatingsMetadata {
    productReviewRatingsMetadata {
      items {
        id
        name
        values {
          value_id
          value
        }
      }
    }
  }
`;

export const ADD_PRODUCT_REVIEW = gql`
  mutation createProductReview(
    $sku: String!
    $nickname: String!
    $summary: String!
    $text: String!
    $ratingsId: String!
    $ratingsValueId: String!
  ) {
    createProductReview(
      input: {
        sku: $sku
        nickname: $nickname
        summary: $summary
        text: $text
        ratings: [{ id: $ratingsId, value_id: $ratingsValueId }]
      }
    ) {
      review {
        nickname
        summary
        text
        average_rating
        ratings_breakdown {
          name
          value
        }
      }
    }
  }
`;

export const QUERY_GET_CATEGORY_BY_SLUG = `
  query categories {
    categories(filters: { url_key: { eq: {{$param}} } }) {
      total_count
      page_info {
        page_size
        current_page
      }
      items {
        id
        level
        name
        meta_title
        meta_description
        meta_keywords
        breadcrumbs {
          category_name
          category_url_key
        }
        url_key
        children_count
        children {
          id
          level
          name
          url_key
          children_count
          children {
            id
            level
            name
            url_key
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
    }
  }
`;

export const QUERY_GET_PRODUCTS_BY_CATEGORY_ID = `
  query products {
    products(
      filter: {{$filter}}
      pageSize: {{$pageSize}}
      currentPage: {{$currentPage}}
      sort: {{$sort}}
    ) {
      aggregations {
        count
        label
        attribute_code
        options {
          count
          label
          value
        }
      }
      sort_fields {
        default
        options {
          label
          value
        }
      }
      items {
        name
        sku
        id
        categories {
          id
          name
        }
        url_key
        rating_summary
        review_count
        __typename
        stock_status
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
          maximum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
        small_image {
          url
          label
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
            }
            product_id
          }
          variants {
            product {
              id
              name
              sku
              stock_status
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
              image {
                url
                label
              }
              small_image {
                url
                label
              }
              media_gallery {
                url
                label
              }
            }
            attributes {
              uid
              label
              code
              value_index
            }
          }
        }
      }
      total_count
      page_info {
        page_size
        current_page
      }
    }
  }
`;

export const GET_ADDITIONAL_INFO_GQL = gql`
  query getAdditionalInfo($sku: String!) {
    getAdditionalInfo(sku: $sku) {
      items {
        code
        label
        value
      }
    }
  }
`;


export const ADD_PRODUCT_VIEW_COUNT_GQL = gql`
  query addProductViewCount($id: Int!){
    addProductViewCount(id: $id){
        status
    }
  }
`
