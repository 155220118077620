import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { getUrlParameter } from '../../../utils/uri';

export default withRouter((props) => {
  const [id, setId] = useState('');
  useEffect(() => {
    const {
      location: { search },
    } = props;

    setId(getUrlParameter(search.replace('#', '?'), 'id'));
  }, []);
  return (
    <section className='checkout-area ptb-60'>
      <div className='container' style={{ textAlign: 'center', padding: 50 }}>
        <i
          style={{ fontSize: 50, marginBottom: 30 }}
          className='far fa-check-circle'
        ></i>
        <h2>Place Order Success</h2>
        <h6>Congratulation, your order is completed</h6>
        <h6 style={{ marginBottom: 30 }}>
          Thanks for your shopping experience
        </h6>
        <h5 style={{ marginBottom: 20 }}>ORDER ID: #{id}</h5>
        <NavLink to={'/'} className={'btn btn-light'}>
          Continue Shopping
        </NavLink>
      </div>
    </section>
  );
});
