import React, { Component, useEffect, useState } from "react";
import {
    QUERY_GET_PRODUCT_CATEGORIES_AREA_GQL
} from "../../graphql/layout.gql";
import { renderHtmlContent } from "../../utils/html";
import callGetApi, {toAstError} from "../../api";

export default () => {
  const [productCategory, setProductCategory] = useState(null);

  const init = () => {
    getQueryProductCategory();
  };

    const getQueryProductCategory = () => {
        callGetApi(QUERY_GET_PRODUCT_CATEGORIES_AREA_GQL).then((res: any) => {
            let cmsBlocks =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.cmsBlocks && res.data.data.cmsBlocks;

            if (cmsBlocks) {
                setProductCategory(cmsBlocks.items);
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
            }
        });
    };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {!!productCategory &&
        !!productCategory.length &&
        productCategory.map((item) => {
          return renderHtmlContent({ content: item.content });
        })}
    </>
  );
};
