export const calculateProductItemPrice = (productItem) => {
  if (!productItem) return 0;

  if (!productItem.configurable_options) {
    if (
      !productItem.product ||
      !productItem.product.price_range ||
      !productItem.product.price_range.minimum_price ||
      !productItem.product.price_range.minimum_price.final_price ||
      !productItem.product.price_range.minimum_price.final_price.value
    )
      return 0;

    return productItem.product.price_range.minimum_price.final_price.value;
  }

  const configurableOptions = productItem.configurable_options;
  const productVariants = productItem.product.variants;

  let price = 0;
  productVariants.map((variant) => {
    let isCheck = true;
    variant.attributes.map((attribute) => {
      const find = configurableOptions.find((config) => {
        return (
          config.configurable_product_option_value_uid === attribute.uid
        );
      });

      if (!find) isCheck = false;
    });

    if (!!isCheck) {
      price =
        !!variant.product &&
        !!variant.product.price_range &&
        !!variant.product.price_range.minimum_price &&
        !!variant.product.price_range.minimum_price.final_price &&
        variant.product.price_range.minimum_price.final_price.value;
    }
  });

  return price;
};


export const calculateProductItemPriceForWishList = (productItem) => {
  if (!productItem) return 0;

  if (!productItem.configurable_options) {
    let price = productItem?.product?.price_range?.minimum_price?.regular_price?.value;
    let finalPrice = productItem?.product?.price_range?.minimum_price?.final_price?.value;

    return {
      price: finalPrice || price,
      oldPrice: finalPrice !== price ? price : 0
    };
  }

  const configurableOptions = productItem.configurable_options;
  const productVariants = productItem.product.variants;

  let price = 0;
  let finalPrice = 0;
  productVariants.map((variant) => {
    let isCheck = true;
    variant.attributes.map((attribute) => {
      const find = configurableOptions.find((config) => {
        return (
          config.option_label.toLowerCase() === attribute.code.toLowerCase() &&
          config.value_label.toLowerCase() === attribute.label.toLowerCase()
        );
      });

      if (!find) isCheck = false;
    });

    if (!!isCheck) {
      price =
        !!variant.product &&
        !!variant.product.price_range &&
        !!variant.product.price_range.minimum_price &&
        !!variant.product.price_range.minimum_price.regular_price &&
        variant.product.price_range.minimum_price.regular_price.value;

      finalPrice =
        !!variant.product &&
        !!variant.product.price_range &&
        !!variant.product.price_range.minimum_price &&
        !!variant.product.price_range.minimum_price.final_price &&
        variant.product.price_range.minimum_price.final_price.value;
    }
  });

  return {
    price: finalPrice || price,
    oldPrice: finalPrice !== price ? price : 0
  };
};
