import React from 'react';
import { NavLink } from 'react-router-dom';
import * as ROUTINGS from '../../routings/path';
import { RatingValue } from '../../utils/rating';

import AddToCart from '../Shared/AddToCart';
import RemoveCompare from '../Shared/RemoveCompare';

const Content = (props) => {
  const { compare_products, compareListUUID, saveCompareList } = props;

  return (
    <section className='compare-area ptb-60'>
      <div className='container'>
        <div className='section-title'>
          <h2>
            <span className='dot'></span> Compare Products
          </h2>
        </div>

        <div className='products-compare-table'>
          <div className='row'>
            {!!compare_products && compare_products.length ? (
              compare_products.map((data, idx) => {
                const price =
                  data &&
                  data.product &&
                  data.product.price_range &&
                  data.product.price_range.minimum_price &&
                  data.product.price_range.minimum_price.regular_price &&
                  data.product.price_range.minimum_price.regular_price.value;

                const finalPrice =
                  (data &&
                    data.product &&
                    data.product.price_range &&
                    data.product.price_range.minimum_price &&
                    data.product.price_range.minimum_price.final_price &&
                    data.product.price_range.minimum_price.final_price.value) ||
                  price;

                return (
                  <div className='col-lg-3 col-md-6 col-sm-6' key={idx}>
                    <RemoveCompare
                      compareListUUID={compareListUUID}
                      saveCompareList={saveCompareList}
                      id={data.product.id}
                    />

                    <div className='single-product-box'>
                      <div className='product-image'>
                        <NavLink to={`${ROUTINGS.ROUTING_PRODUCT_DETAIL_PATH}/${data.product.url_key}`}>
                          <a>
                            <img src={data.product.small_image.url} alt={data.product.small_image.label} />
                            <img
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                              src={data.product.small_image.url}
                              alt={data.product.small_image.label}
                            />
                          </a>
                        </NavLink>
                      </div>

                      <div className='product-content'>
                        <h3>
                          <NavLink
                            to={`${ROUTINGS.ROUTING_PRODUCT_DETAIL_PATH}/${data.product.url_key}`}
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              lineHeight: '20px',
                              height: 40,
                              WebkitLineClamp: 2,
                            }}
                          >
                            <a>{data.product.name}</a>
                          </NavLink>
                        </h3>

                        <div className='product-price'>
                          {price !== finalPrice && (
                            <span
                              style={{
                                textDecoration: 'line-through', opacity: .4,
                                marginRight: 10,
                              }}
                              className='new-price'
                            >
                              ${price}
                            </span>
                          )}
                          <span className='new-price'>${finalPrice}</span>
                        </div>

                        <div className='rating' style={{height:21}}>
                          {
                            data.product.rating_summary ? (
                              <>
                                <RatingValue value={data.product.rating_summary / 20} />
                                <span>({data.product.review_count})</span>
                              </>
                            ) : null
                          }
                        </div>

                        <AddToCart {...data} />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>Empty</div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content;
