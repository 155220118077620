import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { calculateProductItemPrice } from '../../utils/cart';
import * as ROUTINGS from '../../routings/path';

export default (props: any) => {
  const [actionId, setActionId] = useState(0);
  const {
    cartInfo,
    isUpdateCartLoading,
    isDeleteCartLoading,
    onUpdate,
    onDelete,
  } = props;
  const { items } = cartInfo;

  let cartItems = items.length ? (
    items.map((data, idx) => {
      if (!data || !data.product) return null;

      const price = calculateProductItemPrice(data);

      return (
        <tr key={idx}>
          <td className='product-thumbnail'>
            <NavLink
              to={`${ROUTINGS.ROUTING_PRODUCT_DETAIL_PATH}/${data.product.url_key}`}
            >
              <img
                src={data.product.thumbnail && data.product.thumbnail.url}
                alt={data.product.thumbnail && data.product.thumbnail.label}
              />
            </NavLink>
          </td>

          <td className='product-name col-lg-5'>
            <NavLink
              style={{ whiteSpace: 'pre-wrap', paddingRight: 20 }}
              to={`${ROUTINGS.ROUTING_PRODUCT_DETAIL_PATH}/${data.product.url_key}`}
            >
              {data.product.name}
            </NavLink>
            {'OUT_OF_STOCK' === data.product.stock_status && (
              <div style={{ fontSize: 12, color: 'red', fontWeight: 500 }}>
                This product is out of stock.
              </div>
            )}

            <ul>
              {data.configurable_options &&
                data.configurable_options.map((config) => (
                  <li>
                    <span style={{ textTransform: 'capitalize' }}>
                      {config.option_label}
                    </span>
                    : <strong>{config.value_label}</strong>
                  </li>
                ))}
            </ul>
          </td>

          <td className='product-price'>
            <span className='unit-amount'>${price}</span>
          </td>

          <td
            className='product-quantity'
            style={{ pointerEvents: !!isUpdateCartLoading ? 'none' : 'all' }}
          >
            <div className='input-counter'>
              <span
                className='minus-btn'
                onClick={() => {
                  if (!!isUpdateCartLoading) return;
                  setActionId(data.id);
                  onUpdate({
                    cartItemUid: data.uid,
                    quantity: data.quantity - 1,
                  });
                }}
              >
                <i className='fas fa-minus'></i>
              </span>
              {isUpdateCartLoading && actionId === data.id ? (
                <div
                  style={{
                    width: '100%',
                    height: 45,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{ background: '#aaa', width: 20, height: 20 }}
                    className='spinner-grow spinner-grow-sm'
                  />
                </div>
              ) : (
                <input
                  type='number'
                  value={data.quantity}
                  min={1}
                  max={10}
                  readOnly={true}
                  onChange={(e) => e}
                />
              )}

              <span
                className='plus-btn'
                onClick={() => {
                  if (!!isUpdateCartLoading) return;
                  setActionId(data.id);
                  onUpdate({
                    cartItemUid: data.uid,
                    quantity: data.quantity + 1,
                  });
                }}
              >
                <i className='fas fa-plus'></i>
              </span>
            </div>
          </td>

          <td className='product-subtotal'>
            <span className='subtotal-amount'>${price * data.quantity}</span>
            <NavLink
              to='#'
              className='remove'
              onClick={() => {
                if (!!isDeleteCartLoading) return;

                setActionId(data.id);
                onDelete({ cartItemUid: data.id });
              }}
            >
              {!!isDeleteCartLoading && actionId === data.id ? (
                <div
                  style={{ background: '#aaa', width: 20, height: 20 }}
                  className='spinner-grow spinner-grow-sm'
                />
              ) : (
                <i className='far fa-trash-alt'></i>
              )}
            </NavLink>
          </td>
        </tr>
      );
    })
  ) : (
    <tr>
      <td className='product-thumbnail' colSpan={5}>
        <p>Empty.</p>
      </td>
    </tr>
  );

  return (
    <>
      <table className='table table-bordered'>
        <thead>
          <tr style={{ borderTop: 'none' }}>
            <th scope='col'>Product</th>
            <th scope='col'>Name</th>
            <th scope='col'>Unit Price</th>
            <th scope='col'>Quantity</th>
            <th scope='col'>Total</th>
          </tr>
        </thead>
        <tbody>{cartItems}</tbody>
      </table>
    </>
  );
};
