import * as ACTION_TYPE from './type';
import * as CHECKOUT_ACTION_TYPE from '../checkout/type';
import { REDUCER_GROUP } from '../reducer.group';

export const saveAuthInfoAction = (data) => ({
  type: ACTION_TYPE.SAVE_AUTH_INFO,
  payload: { data },
  group: REDUCER_GROUP.AUTH,
});

export const signOutAction = () => ({
  type: ACTION_TYPE.SIGN_OUT,
  payload: {},
  group: REDUCER_GROUP.AUTH,
});
